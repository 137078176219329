import React, {useState, useEffect, useCallback, useRef, useContext} from "react";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faClone,
  faEdit,
  faPlus,
  faSearch
} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../hooks/useMountedState";
import useApi from "../hooks/useApi";
import {eventTypeColor} from "./schedule/helpers";
import {getSnakeCase, toTitleCase} from "../utils/helpers";
import {iconLookup} from "../components/form/InputFieldType";
import {AuthContext} from "../contexts/auth.js";

// Components
import Element from "../components/Element.js";
import AccordionWrapped from "../components/AccordionWrapped";
import Help from "../components/Help.js";
import ModalStage from "./schedule/ModalStage";
import ModalDuplicateTemplate from "./schedule/ModalDuplicateTemplate";
import {InputCheck, InputText} from "../components/form/FormInputs.js";

// Style
import {flex, z} from "../style/components/mixins";
import {voice} from "../style/components/typography";
import {breakpoint} from "../style/components/breakpoints";
import {border, colors, pad, radius, transition} from "../style/components/variables";
import {
  Page,
  Title,
  Heading,
  Text,
  Button,
  Loader,
  Inline,
  Form,
  FormField,
  Select,
  Search,
  ButtonFull,
  Small,
  Abbr,
  Label,
  SearchWrapper,
  SearchIcon,
  Error
} from "../style/components/general.js";

const defaultValues = {
  type: "action",
  name: "",
  overwrite: false,
  label: ""
};

const defaultSchema = {
  type: yup.string().required("Please provide an event type"),
  name: yup.string().when("templatizing", {
    is: val => !!val,
    then: () => yup.string().nullable(),
    otherwise: () => yup.string().required("Please provide name")
  }),
  overwrite: yup.bool(),
  label: yup.string().nullable()
};

const EventTemplates = () => {
  const isMounted = useMountedState();

  const icons = iconLookup();

  const {roles} = useContext(AuthContext);

  const [query, setQuery] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [selected, setSelected] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [targetStage, setTargetStage] = useState(null);
  const [units, setUnits] = useState([]);
  const [unitsToAdd, setUnitsToAdd] = useState([]);
  // Modals
  const [showModalStage, setShowModalStage] = useState(null);
  const [showModalDuplicate, setShowModalDuplicate] = useState(false);

  const {api: apiEventTypes} = useApi("event-types");
  const {api: apiEventTemplates} = useApi("event-templates");
  const {api: apiUnits} = useApi("units");

  const hasSubmitted = useRef(false);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(defaultSchema))
  });

  const {
    register,
    setValue,
    watch,
    reset,
    handleSubmit,
    trigger,
    formState: {errors}
  } = form;
  const watchType = watch("type");
  const watchName = watch("name");
  const watchOverwrite = watch("overwrite");

  const loadEventTypes = useCallback(
    () =>
      apiEventTypes.callGet().then(({status, data}) => {
        if (status === 200) {
          const temp = {};
          data.map(type => {
            temp[type.name] = type;
          });
          setEventTypes(data.filter(({name}) => name !== "reminder"));
          // setTypesAvailable(temp);
        }
      }),
    [apiEventTypes]
  );

  const loadUnits = useCallback(
    () =>
      apiUnits.callGet().then(({status, data}) => {
        if (status === 200) setUnits(data);
      }),
    [apiUnits]
  );

  const loadTemplates = useCallback(
    () =>
      apiEventTemplates.callGet(null, {query: query}).then(({status, data}) => {
        if (status === 200) setTemplates(data);
      }),
    [apiEventTemplates, query]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      loadEventTypes();
      loadUnits();
    }
  }, [isMounted, loadEventTypes, loadUnits]);

  useEffect(() => {
    if (isMounted() && templates === null) loadTemplates();
  }, [isMounted, loadTemplates, templates]);

  // Search
  useEffect(() => {
    if (query || query === "") loadTemplates();
  }, [query, loadTemplates]);

  const selectType = type => {
    const {name, stages} = type;
    setEventType(type);
    setValue("type", name);

    const allIds = [];
    const byId = {};

    let prev = null;
    stages.map(({name: stageName, color, hasFields, help, builder, restrictTo}, index) => {
      const key = getSnakeCase(stageName);

      // Default Values
      const stage = {
        name: stageName,
        color,
        restrictTo: null,
        builder: null,
        last: index === eventType.stages.length - 1,
        next: null
      };

      if (hasFields) {
        stage.help = help;
        stage.builder = builder;
        stage.restrictTo = restrictTo;
      }

      allIds.push(key);
      byId[key] = stage;

      if (prev) byId[prev].next = key;

      prev = key;
    });

    setValue("stages", {allIds, byId});
  };

  const saveStage = ({name, restrictTo, help, builder}) => {
    const key = getSnakeCase(name);
    const target = `stages.byId.${key}`;
    const watchTarget = watch(target);
    setValue(target, {
      ...watchTarget,
      name,
      restrictTo,
      help,
      builder
    });

    setTargetStage(null);
    setShowModalStage(false);

    if (hasSubmitted.current) trigger();
  };

  useEffect(() => {
    if (errors?.stages) hasSubmitted.current = true;
  }, [errors]);

  const selectTemplate = template => {
    const {type, name, label, responses, stages} = template;

    reset({
      type: type.name,
      name,
      overwrite: name !== label,
      label,
      ...responses,
      stages
    });

    setSelected(template);
    setEventType(type);
    setIsEditing(true);
    setQuery(name);
    setIsCreating(false);

    window.scrollTo(0, 0);
  };

  const handleDuplicate = ({name}) => {
    const {type, name: _n, label, stages, ...rest} = watch();

    const postData = {
      type,
      name,
      label,
      stages,
      responses: rest
    };

    apiEventTemplates.callPost(postData).then(({status, data}) => {
      if (status === 201) {
        selectTemplate(data.template);
        setQuery(name);
        setShowModalDuplicate(false);
      }
    });
  };

  const saveTemplate = ({id, type, name, label, stages, overwrite: _o, ...rest}) => {
    const postData = {
      type,
      name,
      label,
      stages,
      responses: rest,
      units: unitsToAdd
    };

    const request = selected?.id
      ? apiEventTemplates.callPut(selected.id, postData)
      : apiEventTemplates.callPost(postData);

    request.then(({status}) => {
      if (status === 200 || status === 201) {
        loadTemplates();
        reset(defaultValues);
        setEventType(null);
        setIsEditing(false);
        setQuery("");
      }
    });
  };

  const renderStages = () => {
    if (eventType) {
      const content = eventType.stages?.map((stage, index) => {
        if (stage) {
          const {name, hasFields} = stage;

          const key = getSnakeCase(name);
          const watchById = watch("stages.byId");
          const watchRestrictTo = watch(`stages.byId.${key}.restrictTo`);
          const watchFields = watch(`stages.byId.${key}.builder`);
          const watchNext = watch(`stages.byId.${key}.next`);

          const roleLabel =
            watchRestrictTo?.length > 0
              ? watchRestrictTo
                  .filter(({id}) => roles.filter(({id: roleId}) => id === roleId)[0]?.label)
                  .map(({id}) => roles.filter(({id: roleId}) => id === roleId)[0].label)
                  .join(", ")
              : "Unset";

          return (
            <Stage key={name}>
              {index === 0 && (
                <>
                  <Status color={colors.blue}>
                    <Label bold>
                      <Abbr title="SCHEDULED">SCHEDULED</Abbr>
                    </Label>
                  </Status>
                  <Arrow>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Arrow>
                </>
              )}

              <AccordionWrapped
                labelNode={
                  <>
                    <AccordionLabel invertColors>
                      <Abbr title={name.toUpperCase()}>{name.toUpperCase()}</Abbr>
                    </AccordionLabel>
                    {hasFields && (
                      <Help showModal inverted>
                        Defined on type, edits not allowed on template or event.
                      </Help>
                    )}
                  </>
                }
                menu={
                  !hasFields ? (
                    <Button
                      type="button"
                      title="Edit Stage"
                      onClick={e => {
                        e.stopPropagation();
                        setTargetStage(stage);
                        setShowModalStage(true);
                      }}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  ) : null
                }
                invertColors>
                <StageContent>
                  <Small>
                    Available:&nbsp;
                    <Abbr title={roleLabel}>{roleLabel || "All"}</Abbr>
                  </Small>
                  {watchFields?.allIds?.length > 0 ? (
                    watchFields.allIds.map(id => {
                      const {type: fieldType, name: fieldName, label} = watchFields.byId[id];
                      return (
                        <Field key={fieldName}>
                          <FontAwesomeIcon icon={icons[fieldType]} />
                          &nbsp;
                          <Abbr title={label}>{label}</Abbr>
                        </Field>
                      );
                    })
                  ) : (
                    <Text inverted>No fields</Text>
                  )}
                  {watchNext && watchNext !== null && (
                    <Small>
                      Triggers:&nbsp;
                      <Abbr title={watchById[watchNext].name.toUpperCase()}>
                        {watchById[watchNext].name.toUpperCase()}
                      </Abbr>
                    </Small>
                  )}
                </StageContent>
              </AccordionWrapped>
              <ErrorWrapper>
                {errors?.stages?.byId && errors?.stages?.byId[key] && (
                  <Error>{errors?.stages?.byId[key].message}</Error>
                )}
              </ErrorWrapper>

              {/* Show Completed */}
              {eventType?.stages?.length && index === eventType.stages.length - 1 && (
                <>
                  <Arrow>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </Arrow>
                  <Status color={colors.green}>
                    <Label bold>
                      <Abbr title="COMPLETED">COMPLETED</Abbr>
                    </Label>
                  </Status>
                </>
              )}

              <Arrow hidden={index === (eventType?.stages?.length || 1) - 1}>
                <FontAwesomeIcon icon={faArrowDown} />
              </Arrow>
            </Stage>
          );
        }
        return null;
      });

      return (
        <FormField>
          <Label bold>STAGES</Label>
          <Stages>{content?.filter(stage => !!stage)}</Stages>
          {errors?.stages && <Error>One or more stages have errors. Please see above.</Error>}
        </FormField>
      );
    }

    return null;
  };
  return (
    <Page>
      <Menu fullWidth>
        <Title>Manage Templates</Title>

        <Button type="button" onClick={() => window.history.back()}>
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;&nbsp;Go Back
        </Button>
      </Menu>

      <Wrapper>
        <Col>
          <Inline fullWidth>
            <Heading>Event Templates</Heading>
          </Inline>
          <SearchWrapper>
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <Search
              type="text"
              placeholder="Search..."
              value={query}
              onChange={({target}) => setQuery(target.value)}
            />
          </SearchWrapper>
          <TemplateTable>
            {templates ? (
              <div>
                {templates.length > 0 ? (
                  templates.map(({id, name, ...rest}) => (
                    <EventTemplate
                      key={id}
                      type="button"
                      title={name}
                      onClick={() => selectTemplate({id, name, ...rest})}
                      disabled={watchName === name}>
                      {name}
                    </EventTemplate>
                  ))
                ) : (
                  <Text>No templates have been created.</Text>
                )}
              </div>
            ) : (
              <Loader />
            )}
          </TemplateTable>
        </Col>
        <Col>
          {!isEditing ? (
            <AddButton
              type="button"
              onClick={() => {
                reset(defaultValues);
                setQuery("");
                setSelected(null);
                setEventType(eventTypes[0]);
                setIsEditing(true);
                setIsCreating(true);
              }}>
              <FontAwesomeIcon icon={faPlus} />
            </AddButton>
          ) : (
            <AbsoluteInline>
              {!isCreating && (
                <Duplicate type="button" title="Clone" onClick={() => setShowModalDuplicate(true)}>
                  <FontAwesomeIcon icon={faClone} />
                </Duplicate>
              )}
              <Reset
                type="button"
                title="Cancel"
                onClick={() => {
                  reset(defaultValues);
                  setQuery("");
                  setSelected(null);
                  setEventType(eventTypes[0]);
                  setIsCreating(false);
                  setIsEditing(false);
                }}>
                Cancel
              </Reset>
            </AbsoluteInline>
          )}
          {isEditing ? (
            <FormProvider {...form}>
              <Form noValidate>
                <FormField inline>
                  <Select {...register("type", {required: true})} hidden>
                    {eventTypes?.map(({name, value}) => (
                      <option key={`${name}-hidden`} value={value}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  {eventTypes?.map(curr => {
                    const {id, name} = curr;
                    return (
                      <EventType
                        key={id}
                        type="button"
                        title={toTitleCase(name)}
                        onClick={() => selectType(curr)}
                        active={watchType === name}
                        color={eventTypeColor(curr)}
                        disabled={!isCreating && watchType !== name}>
                        {toTitleCase(name)}
                      </EventType>
                    );
                  })}
                </FormField>

                <FormField>
                  <InputText name="name" placeholder="Name" label="TEMPLATE NAME" required />
                </FormField>

                <FormField>
                  <InputCheck name="overwrite">
                    Use a different name when template is applied?
                  </InputCheck>
                  {watchOverwrite && (
                    <InputText name="label" placeholder="Label" label="TEMPLATE LABEL" />
                  )}
                </FormField>

                {/* Custom Fields defined from Type */}
                {eventType?.fields?.allIds?.map(id => {
                  const field = eventType.fields.byId[id];
                  return (
                    field && (
                      <FormField key={id}>
                        <Element attributes={field} />
                      </FormField>
                    )
                  );
                })}

                {renderStages()}

                <Button type="submit" title="Save Template" onClick={handleSubmit(saveTemplate)}>
                  {!isCreating ? "Update Template" : "Add Template"}
                </Button>
              </Form>
            </FormProvider>
          ) : (
            <CenterText>
              <Text>
                Please select a template to edit, or click the &nbsp;<Plus>+</Plus>&nbsp; button to
                add a new one
              </Text>
            </CenterText>
          )}
        </Col>
      </Wrapper>

      {showModalStage && (
        <ModalStage
          visible={showModalStage}
          setVisible={setShowModalStage}
          stage={targetStage}
          existing={targetStage && watch(`stages.byId.${getSnakeCase(targetStage.name)}`)}
          units={units}
          setUnits={setUnits}
          unitsToAdd={unitsToAdd}
          setUnitsToAdd={setUnitsToAdd}
          saveStage={saveStage}
        />
      )}

      {showModalDuplicate && (
        <ModalDuplicateTemplate
          visible={showModalDuplicate}
          setVisible={setShowModalDuplicate}
          saveTemplate={handleDuplicate}
          templateName={watchName}
        />
      )}
    </Page>
  );
};

// Style Overrides
const Menu = styled(Inline)`
  justify-content: space-between;
  margin-bottom: ${pad}px;
`;

const Col = styled.div`
  position: relative;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  margin-bottom: ${pad * 2}px;
`;

const TemplateTable = styled.div`
  position: relative;
  max-height: 75vh;
  overflow: auto;
  margin-top: ${pad}px;
`;

const Wrapper = styled.section`
  ${flex("row", "nowrap", "start", "start")};
  width: 100%;
  gap: ${pad}px;
  max-width: ${breakpoint.width[5]};

  ${Col}:first-child {
    width: 35%;
  }

  ${Col}:last-child {
    width: 65%;
  }
`;

const EventType = styled(Button)`
  color: ${({color}) => color};
  border: ${border} solid ${({color}) => color};
  background: transparent;
  margin-right: ${pad}px;

  ${({active, theme, color}) =>
    active &&
    css`
      color: ${theme.tertiary};
      background: ${color};
    `};
`;

const EventTemplate = styled(ButtonFull)`
  width: 100%;
  text-transform: uppercase;
  background: transparent;
  margin-bottom: ${pad}px;
  color: ${({theme}) => theme.secondary};
  border: ${border} solid ${({theme}) => theme.secondary};
  transition: ${transition};
  font-weight: bold;
  ${voice.normal};

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme}) => theme.secondary};
  }

  ${({active, theme}) =>
    active &&
    css`
      color: ${theme.tertiary};
      background: ${theme.secondary};
    `}
`;

const Stages = styled.div`
  ${flex("column", "nowrap", "start", "start")};
  width: 65%;
  padding: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  gap: ${pad}px;
`;

const Stage = styled.div`
  width: 100%;
`;

const Arrow = styled.div`
  font-size: 30px;
  text-align: center;

  ${({hidden}) =>
    hidden &&
    css`
      opacity: 0;
    `}
`;

const Status = styled.div`
  padding: ${pad}px;
  border-radius: ${radius};
  background: ${({color}) => color};
  overflow: hidden;

  ${Label} {
    display: block;
    width: 100%;
    color: ${({theme}) => theme.tertiary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Field = styled.div`
  padding: ${pad}px 0;
  color: ${({theme}) => theme.tertiary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const Reset = styled(Button)`
  z-index: ${z("above")};
`;

const Duplicate = styled(Button)`
  z-index: ${z("above")};
`;

const AbsoluteInline = styled(Inline)`
  position: absolute;
  top: ${pad}px;
  right: ${pad}px;
`;

const StageContent = styled.div`
  padding: 0 ${pad}px ${pad}px;
  display: flex;
  flex-direction: column;
`;

const ErrorWrapper = styled.div`
  margin-top: -${pad / 2}px;
  margin-bottom: ${pad}px;
`;

const CenterText = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    text-align: center;
  }
`;

const AddButton = styled(Button)`
  top: ${pad}px;
  right: ${pad}px;
  position: absolute;
  z-index: ${z("above")};
`;

const Plus = styled.strong`
  ${voice.medium}
`;

const AccordionLabel = styled(Label)`
  font-weight: bold;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.secondary};

  ${({invertColors, theme}) =>
    invertColors
      ? css`
          color: ${theme.tertiary};
        `
      : ""};
`;

export default EventTemplates;
