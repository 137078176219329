import React, {useState, useMemo, useCallback, useContext} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm, FormProvider, useFieldArray} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import {
  defaultUnacceptableParameterPrompt,
  getAncestors,
  getFields,
  replacePseudoFields
} from "../../utils/builder.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Components
import SearchSelect from "../../components/SearchSelect.js";
import Modal from "../../components/Modal.js";
import {
  InputCheck,
  InputError,
  InputRadioGroup,
  InputSelect,
  InputText,
  InputTextArea
} from "../../components/form/FormInputs.js";
import UnacceptableParameter from "./UnacceptableParameter.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {exists, generateUniqueKey} from "../../utils/helpers.js";
import {
  FormGroup,
  FormField,
  ButtonFull,
  Form,
  Heading,
  Label,
  Button,
  Inline,
  Text
} from "../../style/components/general.js";

const initialConditional = {
  check: null,
  compare: null
};

const defaultValues = {
  trigger: "Checksheet Completion",
  condition: {
    depends: null,
    conditionArray: [{...initialConditional}],
    operator: "or"
  },
  prompt: defaultUnacceptableParameterPrompt,
  subject: "",
  message: "",
  important: false,
  recipients: []
};

const ModalNotificationsUpdate = ({
  visible,
  setVisible,
  goBack,
  checksheetBuilder,
  edit,
  current,
  setEdit,
  notifications,
  handleSave: save
}) => {
  const {allIds, byId} = checksheetBuilder;

  const {roles} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);
  const {facility} = useContext(FacilityNavContext);

  const [recipientResults, setRecipientResults] = useState([]);
  const [results, setResults] = useState([]);

  const hasRoleRecipientsEnabled = useMemo(() => {
    if (roles?.length > 0 && settings?.roleRecipients === "true") return true;
    return false;
  }, [roles, settings]);

  const schema = yup.object().shape({
    trigger: yup.string(),
    condition: yup
      .object()
      .nullable()
      .when("trigger", {
        is: "Field Condition",
        then: () =>
          yup.object().shape({
            depends: yup.string().required("Dependent field is required."),
            conditionArray: yup.array().of(
              yup.object().shape({
                check: yup.string().required("Check operation required."),
                compare: yup.string().required("Compare value required.")
              })
            ),
            operator: yup.string().nullable()
          })
      })
      .when("trigger", {
        is: "Unacceptable Parameter",
        then: () =>
          yup.object().shape({
            conditionArray: yup.array().of(
              yup.object().shape({
                depends: yup.string().required("Dependent field is required."),
                aMin: yup
                  .number()
                  .transform((v, o) => (o === "" ? null : v))
                  .nullable()
                  .test({
                    test: (val, ctx) => {
                      if (
                        !ctx.parent.depends ||
                        !["number", "generated"].includes(byId[ctx.parent.depends]?.type)
                      )
                        return true;

                      if (!exists(val) && !exists(ctx.parent.aMax)) return false;

                      return true;
                    },
                    message: "Minimum or maximum must be provided."
                  })
                  .test({
                    test: (val, ctx) => {
                      if (
                        !ctx.parent.depends ||
                        !["number", "generated"].includes(byId[ctx.parent.depends]?.type)
                      )
                        return true;

                      if (exists(val) && exists(ctx.parent.aMax) && val >= ctx.parent.aMax)
                        return false;

                      return true;
                    },
                    message: "Minimum must be less than maximum."
                  }),
                aMax: yup
                  .number()
                  .transform((v, o) => (o === "" ? null : v))
                  .nullable(),
                strictMin: yup.bool(),
                strictMax: yup.bool(),
                alertCondition: yup
                  .mixed()
                  .nullable()
                  .test({
                    test: (val, ctx) => {
                      if (
                        !ctx.parent.depends ||
                        !["radio", "dropdown", "switch", "checkbox"].includes(
                          byId[ctx.parent.depends]?.type
                        )
                      )
                        return true;

                      if (
                        ["dropdown", "radio"].includes(byId[ctx.parent.depends].type) &&
                        !val?.length
                      )
                        return false;
                      if (!["dropdown", "radio"].includes(byId[ctx.parent.depends].type) && !val)
                        return false;

                      return true;
                    },
                    message: "Alert condition must be provided."
                  })
              })
            )
          })
      }),
    prompt: yup
      .mixed()
      .nullable()
      .when("trigger", {
        is: "Unacceptable Parameter",
        then: () => yup.string().nullable()
      }),
    name: yup
      .mixed()
      .nullable()
      .when("trigger", {
        is: "Unacceptable Parameter",
        then: () => yup.string().nullable().required("Name is required")
      }),
    subject: yup
      .string()
      .required("Please provide a subject for email.")
      .when("trigger", {
        is: "Unacceptable Parameter",
        then: () => yup.mixed().nullable()
      }),
    message: yup
      .string()
      .required("Please provide a message for email.")
      .when("trigger", {
        is: "Unacceptable Parameter",
        then: () => yup.mixed().nullable()
      }),
    important: yup.bool().when("trigger", {
      is: "Unacceptable Parameter",
      then: () => yup.mixed().nullable()
    }),
    recipients: yup
      .array()
      .test({
        message: `At least one ${hasRoleRecipientsEnabled ? "role" : "email"} is required.`,
        test: arr => arr && arr.length > 0
      })
      .when("trigger", {
        is: "Unacceptable Parameter",
        then: () => yup.mixed().nullable()
      })
  });

  const currentRecipientsAreRoles = useMemo(
    () => current?.type === "roles" || hasRoleRecipientsEnabled,
    [current, hasRoleRecipientsEnabled]
  );

  const {api: apiFacilityUser} = useApi("facility-users");

  const form = useForm({
    defaultValues: current || defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    control,
    formState: {errors}
  } = form;

  const {fields, append, remove} = useFieldArray({
    control,
    name: "condition.conditionArray"
  });
  const watchTrigger = watch("trigger");
  const watchDepends = watch("condition.depends");
  const watchConditionArray = watch("condition.conditionArray");
  const selected = watch("recipients");

  const handleSearchUser = query => {
    const filter = {
      Omit: selected,
      showHidden: true
    };

    if (query) filter.Search = query;

    const params = {
      facilityId: facility?.id,
      noTest: true,
      limit: query ? 5 : 20,
      filter: JSON.stringify(filter)
    };

    apiFacilityUser.callGet("", params).then(({status, data}) => {
      if (status === 200 && data) setRecipientResults(data);
    });
  };

  const handleSearchRole = query => {
    if (query)
      setRecipientResults(
        roles?.filter(
          role =>
            !selected.includes(role.label) &&
            role.label.toLowerCase().includes(query?.toLowerCase())
        ) || []
      );
    else setRecipientResults(roles?.filter(role => !selected.includes(role.label)) || []);
  };

  const triggerReset = () => {
    setEdit(null);
    reset(defaultValues);
    goBack();
  };

  const getCheckOptions = useCallback(() => {
    const depends = watchDepends ?? current?.condition?.depends;
    let check = [];
    if (depends) {
      const {sanitized: sanitizedDepends} = replacePseudoFields(watchDepends);
      if (sanitizedDepends && byId[sanitizedDepends]) {
        const {type} = byId[sanitizedDepends];
        check = [
          {label: "is", name: "==="},
          {label: "is not", name: "!=="}
        ];

        if (type) {
          if (type === "number" || type === "generated" || type === "range") {
            check = check.concat([
              {label: "is greater than", name: ">"},
              {label: "is less than", name: "<"},
              {label: "is greater than or equal to", name: ">="},
              {label: "is less than or equal to", name: "<="}
            ]);
          }

          if (type === "multiselect") {
            check = [{label: "has", name: "contains"}];
          }

          if (type === "text") {
            check.push({label: "contains", name: "contains"});
          }
        }
      } else if (["rainfall", "cumulative"].includes(depends)) {
        check = check.concat([
          {label: "is", name: "==="},
          {label: "is not", name: "!=="},
          {label: "is greater than", name: ">"},
          {label: "is less than", name: "<"},
          {label: "is greater than or equal to", name: ">="},
          {label: "is less than or equal to", name: "<="}
        ]);
      }
    }
    return check;
  }, [byId, current, watchDepends]);

  const getAllowOther = useCallback(() => {
    const depends = watchDepends ?? current?.condition?.depends;
    if (depends) {
      if (depends === "rainfall" || depends === "cumulative") return true;
      const {sanitized: sanitizedDepends} = replacePseudoFields(watchDepends);
      if (sanitizedDepends && byId[sanitizedDepends]) {
        const {type} = byId[sanitizedDepends];

        const noOther = [
          "checkbox",
          "confirm",
          "radio",
          "dropdown",
          "switch",
          "multiselect",
          "upload"
        ];

        if (!type || noOther.includes(type)) return false;
        return true;
      }
    }
    return false;
  }, [byId, current, watchDepends]);

  const getCompareOptions = useCallback(() => {
    const depends = watchDepends ?? current?.condition?.depends;
    let compare = [];
    if (depends) {
      const {sanitized: sanitizedDepends} = replacePseudoFields(watchDepends);
      if (sanitizedDepends && byId[sanitizedDepends]) {
        const {type, options, on, off} = byId[sanitizedDepends];

        if (type === "checkbox" || type === "confirm") {
          compare = [
            {label: "True", name: "true"},
            {label: "False", name: "false"}
          ];
        }

        if (type === "radio" || type === "dropdown") {
          compare = options.map(select => ({
            label: select.value || select.option || select,
            name: select.value || select.option || select
          }));
        }

        if (type === "switch") {
          compare = [
            {label: on, name: on},
            {label: off, name: off}
          ];
        }

        if (type === "number") {
          compare = [
            {label: "0", name: "0"},
            {label: "1", name: "1"}
          ];
        }

        if (type === "multiselect") {
          compare = [
            {label: "all checked", name: "checked"},
            {label: "all unchecked", name: "unchecked"},
            {label: "one checked", name: "one"},
            {label: "at least one checked", name: "more"},
            ...options.map(select => ({
              label: `"${select.value || select.option || select}" selected`,
              name: select.value || select.option || select
            }))
          ];
        }

        if (type === "upload") compare = [{label: "uploaded", name: "uploaded"}];
      }
    }
    return compare;
  }, [byId, current, watchDepends]);

  const checkOptions = useMemo(getCheckOptions, [getCheckOptions]);
  const compareOptions = useMemo(getCompareOptions, [getCompareOptions]);
  const allowOther = useMemo(getAllowOther, [getAllowOther]);

  const search = query => {
    const tempFields = getFields(allIds, byId);
    const allOptions = tempFields.map(({name, ancestry: label}) => ({name, label}));
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allOptions
        .filter(option => option.label.toLowerCase().includes(lower))
        .filter(
          option =>
            watchTrigger !== "Unacceptable Parameter" ||
            (["number", "generated", "dropdown", "switch", "checkbox", "radio"].includes(
              byId[option.name]?.type
            ) &&
              !watchConditionArray?.some(({depends}) => depends === option.name))
        );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setResults(tempResults);
    } else {
      setResults(
        allOptions.filter(
          option =>
            watchTrigger !== "Unacceptable Parameter" ||
            (["number", "generated", "dropdown", "switch", "checkbox", "radio"].includes(
              byId[option.name]?.type
            ) &&
              !watchConditionArray?.some(({depends}) => depends === option.name))
        )
      );
    }
  };

  const handleSave = values => {
    const {name, prompt, trigger, condition, subject, message, important, recipients} = values;

    const updated = {...notifications};
    if (!updated.allIds) updated.allIds = [];
    if (!updated.byId) updated.byId = {};

    // Create
    let notification = {trigger};

    if (trigger === "Unacceptable Parameter")
      notification = {
        ...notification,
        name,
        prompt
      };
    else
      notification = {
        ...notification,
        template: {subject, message, important},
        type: hasRoleRecipientsEnabled ? "roles" : "users",
        recipients: recipients
      };

    if (condition)
      notification = {
        ...notification,
        ...condition
      };

    const notificationKey = edit || generateUniqueKey(subject || name);
    updated.byId[notificationKey] = {...notification};
    if (!edit) updated.allIds.push(notificationKey);

    save(updated);

    triggerReset();
  };

  const getConfigMismatchWarning = () => {
    if (
      currentRecipientsAreRoles === undefined ||
      currentRecipientsAreRoles === hasRoleRecipientsEnabled ||
      selected?.length > 0
    )
      return "";
    if (currentRecipientsAreRoles)
      return (
        <StyledSpan>
          <Warning>Warning: </Warning>
          Role recipients is disabled. Please reassign notification group to a list of users.
        </StyledSpan>
      );
    return (
      <StyledSpan>
        <Warning>Warning: </Warning>
        Role recipients is enabled. Please reassign notification group to a list of roles.
      </StyledSpan>
    );
  };

  return (
    <Modal visible={visible} setVisible={setVisible} goBack={triggerReset} hasBackButton>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSave)}>
          <Heading>Add Checksheet Notification</Heading>
          <FormGroup inline>
            <FormField>
              <InputRadioGroup
                name="trigger"
                label="Triggered by..."
                options={["Checksheet Completion", "Field Condition", "Unacceptable Parameter"]}
              />
            </FormField>
          </FormGroup>
          {watchTrigger === "Field Condition" && (
            <Condition>
              <Label bold>Condition</Label>
              <Inline fullWidth>
                <ColLeft>
                  <InputText name="condition.depends" hidden required />
                  {!watchDepends || watchDepends === "date" || watchDepends === "weekday" ? (
                    <SearchSelect
                      results={results}
                      setResults={setResults}
                      search={search}
                      add={item => setValue("condition.depends", item.name)}
                      showAll
                    />
                  ) : (
                    <Depends type="button">
                      {getAncestors(watchDepends, byId)}
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={() => setValue("condition.depends", null)}
                      />
                    </Depends>
                  )}
                  {fields.length > 1 && checkOptions && compareOptions && (
                    <InputRadioGroup
                      options={[{option: "or"}, {option: "and"}]}
                      label="Logical Operator"
                      name="condition.operator"
                      testId="condition.operator"
                      defaultValue="or"
                    />
                  )}
                </ColLeft>
                <ColRight>
                  {fields.map((field, i) => (
                    <Inline key={field.id}>
                      <FormField inline>
                        <InputSelect
                          name={`condition.conditionArray[${i}].check`}
                          placeholder="Condition..."
                          options={checkOptions}
                          minWidth={120}
                          disabled={!checkOptions || watchDepends === ""}
                          required
                        />
                      </FormField>
                      <FormField inline>
                        <InputSelect
                          name={`condition.conditionArray[${i}].compare`}
                          placeholder="Compare to..."
                          options={compareOptions}
                          other={allowOther}
                          minWidth={120}
                          disabled={
                            !compareOptions || watch(`condition.conditionArray[${i}].check`) === ""
                          }
                          required
                        />
                      </FormField>
                      {fields.length > 1 && watchDepends && (
                        <Button
                          data-testid="condition.remove"
                          type="button"
                          onClick={() => remove(i)}>
                          <FontAwesomeIcon icon={faMinus} />
                        </Button>
                      )}
                      {fields.length < 5 && watchDepends && (
                        <Button
                          data-testid="condition.add"
                          type="button"
                          onClick={() => append(initialConditional)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      )}
                    </Inline>
                  ))}
                </ColRight>
              </Inline>
            </Condition>
          )}

          {watchTrigger === "Unacceptable Parameter" && (
            <>
              <FormField>
                <InputText name="name" label="Name" />
              </FormField>
              <FormField>
                <InputText name="prompt" label="Prompt for Explanation" />
              </FormField>
              <Unacceptable>
                <Label bold>UNACCEPTABLE PARAMETERS</Label>
                <Inline fullWidth>
                  <Col>
                    {fields.map((field, index) => (
                      <ParamInputWrapper key={field.id}>
                        <UnacceptableParameter
                          field={field}
                          index={index}
                          results={results}
                          remove={fields?.length > 1 ? () => remove(index) : null}
                          setResults={setResults}
                          search={search}
                          byId={byId}
                        />
                      </ParamInputWrapper>
                    ))}
                  </Col>
                </Inline>

                <FooterWrapper>
                  {fields?.length < 5 && (
                    <Button
                      data-testid="condition.add"
                      type="button"
                      onClick={() => append(initialConditional)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                </FooterWrapper>
              </Unacceptable>
            </>
          )}

          {watchTrigger === "Unacceptable Parameter" ? (
            <FormGroup>
              <Text>
                <strong>Note: </strong>Will be sent to list of users defined in facility
                notifications.
              </Text>
            </FormGroup>
          ) : (
            <>
              <FormGroup>
                <Label bold>Send Email...</Label>
                <Email>
                  <FormField>
                    <InputText name="subject" label="Subject" />
                  </FormField>
                  <FormField>
                    <InputTextArea name="message" label="Message" />
                  </FormField>
                  <FormField>
                    <InputCheck name="important">Send with Importance?</InputCheck>
                  </FormField>
                </Email>
              </FormGroup>
              <FormGroup>
                <FormField>
                  <SearchWrapper>
                    <SearchSelect
                      label="To Recipients..."
                      placeholder={`Find ${hasRoleRecipientsEnabled ? "roles" : "users"}...`}
                      search={hasRoleRecipientsEnabled ? handleSearchRole : handleSearchUser}
                      results={recipientResults}
                      setResults={setRecipientResults}
                      showAll
                      add={r =>
                        setValue("recipients", [
                          ...selected,
                          hasRoleRecipientsEnabled ? r.label : r.email
                        ])
                      }
                    />
                  </SearchWrapper>
                  {errors.recipients && <InputError errors={errors} name="recipients" />}
                  {getConfigMismatchWarning()}
                  <Selections>
                    {selected?.map(recipient => (
                      <Selection key={recipient} type="button">
                        {recipient}&nbsp;
                        <FontAwesomeIcon
                          icon={faClose}
                          onClick={() =>
                            setValue(
                              "recipients",
                              selected.filter(selection => selection !== recipient)
                            )
                          }
                        />
                      </Selection>
                    ))}
                  </Selections>
                </FormField>
              </FormGroup>
            </>
          )}
          <Submit type="submit" data-testid="saveNotification.save">
            Save
          </Submit>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalNotificationsUpdate.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  checksheetBuilder: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  edit: PropTypes.string,
  current: PropTypes.objectOf(PropTypes.any),
  setEdit: PropTypes.func.isRequired,
  notifications: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired
};

ModalNotificationsUpdate.defaultProps = {
  visible: false,
  goBack: null,
  edit: null,
  current: null
};

// Style Overrides
const Condition = styled(FormGroup)`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;

  ${Label} {
    font-weight: bold;
    width: 100%;
  }

  ${FormField} {
    width: auto;
    margin: 0;

    div {
      margin: 0;
    }
  }
`;

const Unacceptable = styled(FormGroup)`
  border: none;
`;

const Col = styled.div`
  width: 100%;
  align-self: start;
`;

const ParamInputWrapper = styled(Inline)`
  width: 100%;
  margin-top: ${pad}px;
`;

const ColLeft = styled(Col)`
  width: 40%;
`;

const ColRight = styled(Col)`
  width: 60%;
`;

const Depends = styled(ButtonFull)`
  ${flex("row", "nowrap", "space-between", "center")};
  position: relative;
  text-align: left;
  padding: ${pad / 4}px ${pad / 2}px;

  svg {
    padding-left: ${pad}px;
  }
`;

const Email = styled.div`
  width: 100%;
  padding: 0 ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};

  ${FormField} {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Submit = styled(ButtonFull)`
  margin-bottom: ${pad}px;
`;

const Selections = styled.div`
  position: relative;
  ${flex("row", "wrap", "start", "start")};
`;

const Selection = styled.button`
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad / 2}px;
  width: fit-content;
  margin: ${pad}px ${pad / 2}px 0 0;
  ${voice.quiet};
`;

const SearchWrapper = styled.div`
  max-width: 200px;
`;

const StyledSpan = styled.p`
  margin-top: ${pad / 2}px;
  ${voice.quiet};
`;

const Warning = styled.strong`
  color: ${colors.yellow};
`;

const FooterWrapper = styled.div`
  ${flex("row", "nowrap", "end", "center")}
  gap: ${pad}px;
  margin-top: ${pad}px;
`;

export default ModalNotificationsUpdate;
