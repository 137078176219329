import styled, {css, createGlobalStyle} from "styled-components";
import {animateBackground, fadeIn, rotate} from "./animations.js";
import {voice} from "./typography.js";
import {bp, breakpoint} from "./breakpoints.js";
import {flex, z} from "./mixins.js";
import {cross, triangle} from "./shapes.js";
import {
  border,
  pad,
  radius,
  transition,
  colors,
  thickShadow,
  navHeight,
  navWidth
} from "./variables.js";

// Custom Scrollbar
export const scrollbar = css`
  &::-webkit-scrollbar {
    width: ${pad}px;
    height: ${pad}px;
  }

  &::-webkit-scrollbar-track {
    padding: ${pad}px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.primary};
    border: ${border} solid ${({theme}) => theme.tertiary};
    border-radius: ${radius};
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${({theme}) => theme.tertiary};
  }

  /* Printable */
  @page {
    margin: 2rem;
    
    /* A4 */
    size: 210mm 297mm;
  }

  @media print {
    html,
    body {
      height: initial;
      overflow: initial;
    }
  }
`;

// Components
export const Page = styled.section`
  position: relative;
  height: 100%;
  margin: 0 auto;
  margin-top: ${navHeight}px;
  padding: ${pad * 2}px ${pad}px 0;
  max-width: 2000px;

  ${bp(1)} {
    padding: ${pad * 4}px ${pad * 2}px 0;
  }

  ${({hasMenu}) =>
    hasMenu &&
    css`
      margin-bottom: ${navHeight}px;

      ${`${bp(3)} and (min-height: 708px)`} {
        margin-left: ${navWidth}px;
        margin-bottom: auto;
      }
    `}
`;

export const Section = styled.div`
  padding: ${pad}px 0;
`;

export const Title = styled.h1`
  color: ${({theme}) => theme.secondary};
  font-weight: bold;
  ${voice.strong};

  ${bp(5)} {
    ${voice.loud};
  }
`;

export const TitleCenter = styled(Title)`
  text-align: center;
  align-self: center;
`;

export const Heading = styled.h2`
  color: ${({theme}) => theme.secondary};
  font-weight: bold;
  ${voice.medium};

  ${bp(3)} {
    ${voice.strong};

    ${({smaller}) =>
      smaller &&
      css`
        ${voice.medium}
      `}
  }

  ${({inverted, theme}) =>
    inverted &&
    css`
      color: ${theme.tertiary};
    `}

  ${({center}) =>
    center &&
    css`
      text-align: center;
    `}

  ${({smaller}) =>
    smaller &&
    css`
      ${voice.normal}
    `}
`;

export const HeadingCenter = styled(Heading)`
  color: ${({theme}) => theme.secondary};
  text-align: center;
  align-self: center;
  font-weight: 700;
  margin-bottom: ${pad * 2}px;
`;

export const HeadingMedium = styled.h3`
  ${voice.medium};
  font-weight: bold;
  color: ${({theme}) => theme.secondary};

  ${({center}) =>
    center &&
    css`
      text-align: center;
    `}

  ${({inline}) =>
    inline &&
    css`
      display: flex;
      align-items: center;
      gap: ${pad}px;
    `}
`;

export const HeadingSmall = styled.h4`
  font-weight: bold;
  color: ${({theme}) => theme.secondary};

  ${({center}) =>
    center &&
    css`
      text-align: center;
    `}

  ${({inline}) =>
    inline &&
    css`
      display: flex;
      align-items: center;
      gap: ${pad}px;
    `}
`;

export const Text = styled.p`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  ${voice.normal};

  ${({quiet}) =>
    quiet &&
    css`
      ${voice.quiet}
    `};

  ${({inverted, theme}) =>
    inverted &&
    css`
      color: ${theme.tertiary};
    `};

  ${({bold}) =>
    bold &&
    css`
      font-weight: bold;
    `};

  ${({center}) =>
    center &&
    css`
      text-align: center;
      margin: 0 auto;
    `};

  ${({inline}) =>
    inline &&
    css`
      display: inline-block;
    `}
`;

export const TextCenter = styled(Text)`
  width: fit-content;
  text-align: center;
  margin: 0 auto;
`;

export const Span = styled.span`
  color: ${({color, theme}) => color || theme.secondary};

  ${({error, theme}) =>
    error &&
    css`
      color: ${theme.error};
    `};
`;

export const SubText = styled.span`
  font-size: 10px;
  font-weight: inherit;

  ${({error, theme}) =>
    error &&
    css`
      color: ${theme.error};
    `}
`;

export const Small = styled.span`
  ${voice.quiet};
  font-weight: normal;
  color: ${({color, theme}) => color || theme.primary};

  ${({inverted, theme}) =>
    inverted &&
    css`
      color: ${theme.tertiary};
    `}

  ${({secondary, theme}) =>
    secondary &&
    css`
      color: ${theme.secondary};
    `}

  ${({center}) =>
    center &&
    css`
      text-align: center;
    `}
`;

export const List = styled.ul`
  list-style: none;
  display: inline-flex;
  align-items: center;

  ${({column}) =>
    column &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    `};

  ${({row}) =>
    row &&
    css`
      flex-direction: row;
    `};
`;

export const ListItem = styled.li`
  ${voice.normal};
  padding: 0 ${pad}px 0 0;
  text-decoration: none;
  color: ${({theme}) => theme.secondary};

  ${({inline}) =>
    inline &&
    css`
      display: inline-flex;
      flex-direction: row;
      align-items: center;
    `}
`;

export const Bullet = styled(ListItem)`
  list-style: circle;
  margin-left: ${pad * 2}px;
`;

export const Anchor = styled.a`
  ${voice.normal};
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  max-width: max-content;
  background-color: ${({theme}) => theme.primary};
  color: ${({theme}) => theme.tertiary};
`;

export const AnchorInline = styled.a`
  background: none;
  color: ${({theme}) => theme.secondary};
  text-decoration: underline;
  display: inline;
`;

export const Button = styled.button`
  position: relative;
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  width: max-content;
  max-width: 100%;
  height: fit-content;
  background-color: ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.tertiary};
  line-height: 1.5em;
  ${voice.small};

  ${({quiet}) => quiet && voice.quiet}

  ${bp(2)} {
    ${({quiet}) => !quiet && voice.normal}
  }

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.tertiary};
  }

  ${({disabled}) =>
    disabled &&
    css`
      &:hover {
        cursor: not-allowed;
        pointer-events: none;
      }
    `};

  ${({hide}) =>
    hide &&
    css`
      display: none;
    `};

  ${({loading}) =>
    loading &&
    css`
      position: relative;
      opacity: 100%;
      overflow: hidden;
      animation: ${animateBackground} 0.15s linear 0s 1 forwards;
      animation-delay: 0.3s;
      pointer-events: none;
    `}

  ${({plain, theme}) =>
    plain &&
    css`
      background: none;
      color: ${theme.secondary};
    `}

  ${({inText, theme}) =>
    inText &&
    css`
      background: none;
      color: ${theme.secondary};
      text-decoration: underline;
      padding: 0;
      display: inline-block;

      &:hover {
        color: ${theme.primary};
      }
    `}
`;

export const ButtonFull = styled.button`
  position: relative;
  width: 100%;
  padding: ${pad}px;
  background-color: ${({theme}) => theme.secondary};
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  font-weight: bold;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }

  ${({disabled}) =>
    disabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
    `}

  ${({loading}) =>
    loading &&
    css`
      margin-bottom: ${pad}px;
      position: relative;
      display: inline-block;
      overflow: hidden;
      animation: ${animateBackground} 0.15s linear 0s 1 forwards;
      animation-delay: 0.3s;
      pointer-events: none;
    `}
`;

export const ButtonWrapper = styled.button`
  width: initial;
  cursor: pointer;
  padding: ${pad}px;
`;

// Form Styles
export const Form = styled.form`
  position: relative;
  width: 100%;
  margin: 0 auto;
  ${flex("column", "nowrap", "center", "start")};
`;

export const FormGroup = styled.div`
  ${flex("column", "wrap", "center", "start")};
  position: relative;
  width: 100%;
  margin-bottom: ${pad * 2}px;

  ${({inline}) =>
    inline &&
    css`
      ${flex("row", "nowrap", "start", "center")};
      margin-bottom: 0;
      gap: ${pad}px;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormGroupHeading = styled(Heading)`
  ${voice.medium};
`;

export const FormField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${pad * 2}px;

  ${({standard}) =>
    standard &&
    css`
      margin-bottom: ${pad / 2}px;
    `}

  ${({hidden}) =>
    hidden &&
    css`
      display: none;
    `}

  ${({inline}) =>
    inline &&
    css`
      flex-direction: row;
      align-self: start;
      width: fit-content;
    `}
`;

export const FormFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${pad}px;

  ${({inline}) =>
    inline &&
    css`
      flex-direction: row;
    `}

  ${({selfInline}) =>
    selfInline &&
    css`
      width: inherit;
    `}

  ${({standard}) =>
    standard &&
    css`
      padding: ${pad / 2}px 0;
    `}

  ${({hidden}) =>
    hidden &&
    css`
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
      margin-top: 0;
    `}
`;

export const Label = styled.label`
  ${voice.normal};
  cursor: inherit;
  line-height: 1.5;
  color: ${({theme}) => theme.secondary};

  ${({wrap}) =>
    wrap
      ? css`
          white-space: unset;
        `
      : css`
          white-space: nowrap;
        `};

  ${({inverted, theme}) =>
    inverted &&
    css`
      color: ${theme.tertiary};
    `};

  ${({center}) =>
    center &&
    css`
      display: block;
      text-align: center;
    `};

  ${({bold}) =>
    bold &&
    css`
      font-weight: bold;
    `};

  ${({color}) =>
    color &&
    css`
      color: ${color};
    `};

  ${({inline}) =>
    inline &&
    css`
      ${flex("row", "nowrap", "start", "center")};
      gap: ${pad}px;
    `};
`;

export const Input = styled.input`
  padding: ${pad - 2}px ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  min-width: 100px;
  height: 100%;
  max-height: 35px;
  background: transparent;
  color: ${({theme}) => theme.secondary};

  &[type="text"],
  &[type="textarea"],
  &[type="email"],
  &[type="password"] {
    width: 100%;
    max-width: ${breakpoint.width[5]};
  }

  &[type="date"] {
    height: 35px;
    width: 135px;

    ${({placeholder, isMobile, hasValue}) =>
      placeholder &&
      isMobile &&
      css`
        &:before {
          content: attr(placeholder);
          color: ${colors.heroBlack};
          position: absolute;
          pointer-events: none;

          ${hasValue &&
          css`
            display: none;
          `}
        }
      `}
  }

  &[type="number"] {
    ${({maxWidth}) =>
      maxWidth &&
      css`
        max-width: 100%;
      `}
  }

  &[type="file"] {
    border: ${border} dashed ${({theme}) => theme.secondary};
    max-height: 200px;

    &::file-selector-button {
      border: 0;
      border-radius: ${radius};
      background: ${({theme}) => theme.secondary};
      color: ${({theme}) => theme.tertiary};

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &:disabled {
    background: ${colors.grayLight};
    background: ${({theme}) =>
      theme.name === "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.2)"};
  }

  ${({maxWidth}) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({hidden}) =>
    hidden &&
    css`
      opacity: 0;
      width: 0;
      height: 0;
    `}
`;

export const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  min-height: 100px;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  background: transparent;
  color: ${({theme}) => theme.secondary};

  ${({disabled}) =>
    disabled &&
    css`
      background: ${colors.grayLight};
    `}
`;

export const Block = styled.textarea`
  margin: ${pad}px 0;
`;

export const Invalid = styled.span`
  color: ${({theme}) => theme.error};
  ${voice.quiet};
`;

// Shapes/Icons
export const Line = styled.span`
  width: 10px;
  height: 2px;
  background-color: ${({theme}) => theme.secondary};
  margin: ${pad}px;

  ${({width}) =>
    width &&
    css`
      width: ${width}px;
    `}

  ${({color, primary}) =>
    color &&
    css`
      background-color: ${primary};
    `}
`;

export const TriangleDown = styled.span`
  width: fit-content;
  margin-left: ${pad / 2}px;
  display: flex;
  align-items: center;
  ${({theme}) => triangle(pad / 2, theme.tertiary, "down")}
`;

export const TriangleUp = styled.span`
  width: fit-content;
  margin-left: ${pad / 2}px;
  display: flex;
  align-items: center;
  ${({theme}) => triangle(pad / 2, theme.tertiary, "up")}
`;

export const TriangleRight = styled.span`
  width: fit-content;
  margin-left: ${pad / 2}px;
  display: flex;
  align-items: center;
  ${({theme}) => triangle(pad / 2, theme.tertiary, "right")}
`;

export const TriangleLeft = styled.span`
  width: fit-content;
  margin-left: ${pad / 2}px;
  display: flex;
  align-items: center;
  ${({theme}) => triangle(pad / 2, theme.tertiary, "left")}
`;

// Tables
export const TableWrapper = styled.section`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  max-width: 100%;
  margin-bottom: ${pad}px;
`;

export const TableHeaderWrap = styled.nav`
  width: 100%;
  padding: ${pad}px;

  ${flex("row", "wrap", "space-between", "center")}

  @media (min-width: ${breakpoint.width[2]}) {
    flex-wrap: nowrap;
  }
`;

export const TableMenu = styled.div`
  width: fit-content;
  ${flex("row", "nowrap")};
`;

export const TitleCell = styled.th`
  padding: ${pad}px;
  text-align: left;
  color: ${({theme}) => theme.tertiary};
`;

export const TableEditMenu = styled.div`
  width: "100%";
  display: flex;
  align-items: center;
`;

export const TableEditMenuButton = styled(Button)`
  max-height: 100%;
  padding: 0 10px;
  margin-right: 10px;
  background: ${({theme}) => theme.primary};
  color: ${({theme}) => theme.tertiary};
`;

export const TableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pad}px;
`;

// Loader
export const NotLoaded = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 75px;
  padding: ${pad}px;
  margin-right: ${pad}px;
  width: 100%;
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: ${pad}px;
  margin-bottom: ${pad}px;
  animation: ${fadeIn} 200ms linear;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  opacity: 0;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: ${pad}px;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    border-top: ${radius} solid ${({theme}) => theme.tertiary || colors.heroBlack};
    border-right: ${radius} solid ${({theme}) => theme.tertiary || colors.heroBlack};
    border-bottom: ${radius} solid ${({theme}) => theme.tertiary || colors.heroBlack};
    border-left: ${radius} solid ${({theme}) => theme.primary || colors.heroGreen};
    border-radius: 50%;
    animation: ${rotate} 1s infinite linear;
  }
`;

export const EditMenuLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 200ms linear;
  opacity: 0;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;

  &:after {
    opacity: 0.5;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 1.2px;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    border-top: ${radius} solid ${({theme}) => theme.secondary};
    border-right: ${radius} solid ${({theme}) => theme.secondary};
    border-bottom: ${radius} solid ${({theme}) => theme.secondary};
    border-left: ${radius} solid ${({theme}) => theme.tertiary};
    border-radius: 50%;
    animation: ${rotate} 1s infinite linear;
  }
`;

export const SettingsRowWrapper = styled.div`
  width: 100%;
`;

export const ButtonLoader = styled(Loader)`
  animation: ${fadeIn} 200ms linear;
  opacity: 0;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  &:after {
    top: calc(50% - 22px);
  }
`;

export const CalloutContent = styled.div`
  position: sticky;
  height: min-content;
  max-height: ${({taskType}) => (taskType === "event" ? "300px" : "75vh")};
  right: 0;
  bottom: 0;
  border-radius: ${radius};
  background: ${({theme}) => theme.tertiary};
  padding: ${pad * 1.5}px;
  box-shadow: ${thickShadow};
  max-width: 400px;
  pointer-events: auto;
  overflow-y: auto;

  ${scrollbar}

  ${({mobile, taskType}) =>
    mobile
      ? css`
          width: 90vw;
        `
      : css`
          width: ${taskType === "event" ? "300px" : "70vw"};
        `}

  @media (min-width: ${breakpoint.width[3]}) {
    width: ${({taskType}) => (taskType === "event" ? "300px" : "70vw")};
  }
`;

export const ModalButton = styled.button`
  background-color: transparent;
  max-width: max-content;
  margin-left: ${pad * 2}px;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

export const CloseButton = styled(ModalButton)`
  position: relative;
  width: fit-content;
  padding: ${pad}px;
  ${({theme}) => cross("100%", theme.secondary)};
  z-index: ${z("top")};
`;

// Checkbox
export const CheckWrapper = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  background: ${({theme}) => theme.tertiary};

  &:hover {
    cursor: pointer;
  }

  ${({inverted, theme}) =>
    inverted &&
    css`
      background-color: ${theme.tertiary};
    `};

  ${({disabled}) =>
    disabled &&
    css`
      background-color: ${colors.grayLight};
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

export const CheckMark = styled.span`
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: inherit;
  transition: all ease 0.1s;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
    width: 37%;
    height: 85%;
    border: ${border} solid ${({theme}) => theme.secondary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all ease 0.1s;
    opacity: 0;
  }
`;

export const CheckInput = styled.input`
  display: none;

  &:checked + span:after {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Success = styled.p`
  ${voice.medium};
  color: ${({theme}) => theme.success};

  ${bp(3)} {
    ${voice.strong}
  }
`;

export const Error = styled.p`
  ${voice.quiet};
  color: ${({theme}) => theme.error};
`;

export const Warning = styled.p`
  ${voice.quiet};
  color: ${({theme}) => theme.warning};

  span {
    color: ${({theme}) => theme.secondary};
  }
`;

export const Inline = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: ${pad}px;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const Select = styled.select`
  width: 100%;
  height: 34px;
  min-width: 50px;
  max-width: 120px;
  padding: ${pad - 3}px ${pad / 2}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  background: transparent;
  background-color: ${({theme}) => theme.tertiary};
  color: ${({theme}) => theme.secondary};

  ${bp(2)} {
    height: 34px;
    ${({fullWidth}) =>
      fullWidth &&
      css`
        max-width: 100%;
      `}
  }

  ${bp(3)} {
    max-width: 200px;
  }

  ${({minWidth}) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `};

  ${({hidden}) =>
    hidden
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
        `};
`;

export const CenterWrapper = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  div {
    height: min-content;
  }
`;

export const RelativeWrapper = styled.div`
  position: relative;
  height: inherit;
`;

export const StickyWrapper = styled.div`
  -webkit-position: sticky;
  position: sticky;
  margin-top: ${pad}px;
  margin-bottom: ${pad}px;
`;

export const Arrow = styled.div`
  position: relative;
  width: ${({scale}) => (scale ? scale * 22 : 22)}px;
  height: ${({scale}) => (scale ? scale * 22 : 22)}px;
  border-radius: 50%;
  background: transparent;
  transition: ${transition};

  &:hover {
    background: ${({theme}) => theme.grayLight};
    border-radius: 50%;
  }

  ${({rotate: r}) =>
    r &&
    css`
      transform: rotate(${r});
    `}

  &:before {
    content: "";
    position: absolute;
    top: ${({scale}) => (scale ? scale * 7 : 7)}px;
    left: 0;
    right: 0;
    width: ${({scale}) => (scale ? scale * 10 : 10)}px;
    height: ${({scale}) => (scale ? scale * 2 : 2)}px;
    margin: 0 auto;
    background: ${({theme}) => theme.secondary};
    transform: rotate(-45deg);
  }

  &:after {
    content: "";
    position: absolute;
    top: ${({scale}) => (scale ? scale * 13 : 13)}px;
    left: 0;
    right: 0;
    width: ${({scale}) => (scale ? scale * 10 : 10)}px;
    height: ${({scale}) => (scale ? scale * 2 : 2)}px;
    margin: 0 auto;
    background: ${({theme}) => theme.secondary};
    transform: rotate(45deg);
  }
`;

// Masonry
export const Masonry = styled.div`
  display: flex;
  gap: ${pad}px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: ${pad}px;
`;

export const MasonryColumn = styled.div`
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: ${pad}px;

  @media (min-width: ${breakpoint.width[2]}) {
    flex: 1 1 calc(50% - ${pad / 2}px);
    max-width: calc(50% - ${pad / 2}px);
  }

  @media (min-width: ${breakpoint.width[3]}) {
    flex: 1 1 calc(33.3333% - 7px);
    max-width: calc(33.3333% - 7px);
  }
`;

export const MasonryItem = styled.div`
  page-break-inside: avoid;
  break-inside: avoid;
  width: 100%;
`;

export const Hidden = styled.div`
  display: none !important;
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Search = styled(Input)`
  &[type="text"] {
    max-width: 140px;
    max-height: 34px;
    padding-left: ${pad * 3}px;
    background: transparent;
    color: ${({theme}) => theme.secondary};

    &:placeholder-shown {
      text-overflow: ellipsis;
    }

    ${bp(2)} {
      max-width: 155px;
      max-height: 35px;
    }

    ${({fullWidth}) =>
      fullWidth &&
      css`
        width: 100%;
      `}
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  top: ${pad}px;
  left: ${pad}px;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

export const SearchToggle = styled(Button)`
  margin-left: ${pad / 2}px;
`;

export const Abbr = styled.abbr`
  text-decoration: none;
`;

export const Pill = styled.span`
  ${voice.quiet};
  width: min-content;
  height: min-content;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: ${pad * 3}px;
  padding: ${pad / 3}px ${pad / 1.5}px ${pad / 4}px;
  color: ${({theme}) => theme.tertiary};
  background: ${({color, theme}) => color || theme.primary};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${bp(3)} {
    ${voice.small};

    ${({quiet}) =>
      quiet &&
      css`
        ${voice.quiet};
      `};
  }

  ${Abbr} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
