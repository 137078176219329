import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {yupResolver} from "@hookform/resolvers/yup";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";

// Contexts
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Utils
import {formatDate} from "../../utils/helpers.js";
import usePrevious from "../../hooks/usePrevious.js";

// Components
import Modal from "../../components/Modal.js";
import Help from "../../components/Help.js";
import SearchSelect from "../../components/SearchSelect.js";
import InputNotification from "../../components/form/InputNotification.js";
import {
  InputSelect,
  InputError,
  InputCheck,
  InputRadioGroup
} from "../../components/form/FormInputs.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {
  Button,
  Form,
  FormField,
  FormFieldWrapper,
  Heading,
  Inline,
  Label,
  Loader,
  NotLoaded,
  Small
} from "../../style/components/general.js";

export const extractRecipients = list => Object.values(list).map(({id}) => id);

const NOTIFICATION_TYPES = [
  {
    label: "State Change",
    value: "State"
  },
  {
    label: "State Reminder",
    value: "StateRemind"
  },
  {
    label: "Task Completion",
    value: "Completed"
  },
  {
    label: "Outstanding Tasks",
    value: "Remind"
  },
  {
    label: "Overdue Tasks",
    value: "Overdue"
  }
];

const NOTIFICATION_DESC = {
  State: "Email notification sent when a facility state changes.",
  StateRemind: "Recurring email notification sent when a facility state has been applied.",
  Completed:
    "Email notification sent when a task is completed with Unacceptable Parameter violations or includes specified Tagged Fields.",
  OutOfRange:
    "Email notification sent when a task is submitted with values outside acceptable ranges.",
  Remind: "Recurring email notification to indicate tasks due during a given interval.",
  Overdue:
    "Recurring email notification sent at a given interval to indicate checksheets that are overdue and require resolution."
};

const FREQUENCY_OPTIONS = [
  {label: "Daily", name: "daily"},
  {label: "Weekly", name: "weekly"},
  {label: "Monthly", name: "monthly"},
  {label: "Annually", name: "annual"}
];

const hasFrequency = type => {
  if (type === "Remind" || type === "Overdue" || type === "StateRemind") return true;
  return false;
};

const defaultValues = {
  notification: {
    type: null,
    includeTags: false,
    tags: [],
    frequency: null,
    occurrences: [],
    recipients: []
  }
};

const schema = yup.object().shape({
  notification: yup.object().shape({
    type: yup.string().nullable().required("Please provide notification type."),
    state: yup
      .string()
      .nullable()
      .when("type", {
        is: type => type === "State" || type === "StateRemind",
        then: () => yup.string().required("Please provide a target state.")
      }),
    includeTags: yup.boolean(),
    tags: yup
      .array()
      .nullable()
      .when("includeTags", {
        is: true,
        then: () =>
          yup
            .array()
            .of(yup.object())
            .test({
              test: arr => Array.isArray(arr) && arr.length > 0,
              message: "Please select tags."
            })
      }),
    frequency: yup
      .mixed()
      .nullable()
      .when("type", {
        is: type => hasFrequency(type),
        then: () => yup.string().nullable().required("Please provide frequency.")
      }),
    occurrences: yup
      .mixed()
      .nullable()
      .when("type", {
        is: type => hasFrequency(type),
        then: () =>
          yup
            .array()
            .of(
              yup.object().shape({
                dayOfYear: yup
                  .mixed()
                  .nullable()
                  .when("frequency", {
                    is: freq => freq === "annually",
                    then: () => yup.date().required("Please provide date.")
                  }),
                dayOfMonth: yup
                  .mixed()
                  .nullable()
                  .when("frequency", {
                    is: freq => freq === "monthly",
                    then: () => yup.date().required("Please provide date.")
                  }),
                dayOfWeek: yup
                  .mixed()
                  .nullable()
                  .when("frequency", {
                    is: freq => freq === "weekly",
                    then: () => yup.string().required("Please provide weekday.")
                  }),
                time: yup
                  .mixed()
                  .nullable()
                  .when("frequency", {
                    is: freq => !!freq,
                    then: () => yup.string().required("Please provide time.")
                  })
              })
            )
            .test({
              test: (arr, ctx) => {
                if (!Array.isArray(arr) || arr.length === 0) return false;
                for (let i = 0; i < arr.length; i++) {
                  const item = arr[i];
                  if (ctx.parent.type === "anually" && !item.date) return false;
                  if (ctx.parent.type === "monthly" && !item.day) return false;
                  if (ctx.parent.type === "weekly" && !item.weekday) return false;
                  if (!item.time) return false;
                }
                return true;
              },
              message: "Please provide recurrence info."
            })
      }),
    recipients: yup
      .array()
      .of(yup.object())
      .test({
        test: arr => Array.isArray(arr) && arr.length > 0,
        message: "Please select recipients"
      })
  })
});

const formatTime = (hour, min) => {
  let formattedHour = hour;
  let formattedMin = min;
  if (formattedMin < 10) formattedMin = `0${formattedMin}`;
  if (formattedHour < 10) formattedHour = `0${formattedHour}`;
  return `${formattedHour}:${formattedMin}`;
};

const WEEKDAYS_IDX = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const ModalNotification = ({visible, setVisible, handleSave, setManage, target, freq, type}) => {
  const isMounted = useMountedState();

  const {roles} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);
  const {facility} = useContext(FacilityNavContext);

  const {api: apiFieldTags} = useApi("field-tags");
  const {api: apiFacilityStates} = useApi("facility-states");
  const {api: apiFacilityUser} = useApi("facility-users");

  const [fieldTags, setFieldTags] = useState();
  const [states, setStates] = useState(undefined);
  const [users, setUsers] = useState([]);

  const initialized = useRef(false);

  const form = useForm({defaultValues, resolver: yupResolver(schema)});
  const {
    watch,
    setValue,
    handleSubmit,
    formState: {errors}
  } = form;
  const watchType = watch("notification.type");
  const watchIncludeTags = watch("notification.includeTags");
  const watchTags = watch("notification.tags");
  const watchFrequency = watch("notification.frequency");
  const watchRecipients = watch("notification.recipients");

  const prevFrequency = usePrevious(watchFrequency);

  const hasRoleRecipientsEnabled = useMemo(() => {
    if (roles?.length > 0 && settings?.roleRecipients === "true") return true;
    return false;
  }, [roles, settings]);

  const listIsRole = useMemo(() => {
    const {recipients} = target || {};
    const [first] = recipients ? Object.values(recipients) : [];
    return first?.isRole;
  }, [target]);

  const handleSearchUser = query => {
    const filter = {
      Omit: watchRecipients?.map(user => user.id),
      showHidden: true
    };

    if (query) filter.Search = query;

    const params = {
      facilityId: facility?.id,
      limit: query ? 5 : 20,
      filter: JSON.stringify(filter),
      noTest: true
    };

    apiFacilityUser.callGet("", params).then(({status, data}) => {
      if (status === 200 && data) setUsers(data?.map(user => ({...user, id: user.publicId})) || []);
    });
  };

  const handleSearchRole = query => {
    if (query)
      setUsers(
        roles?.filter(
          role =>
            !watchRecipients.map(({id}) => id).includes(role.id) &&
            role.label.toLowerCase().includes(query?.toLowerCase())
        ) || []
      );
    else
      setUsers(roles?.filter(role => !watchRecipients.map(({id}) => id).includes(role.id)) || []);
  };

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      if (!fieldTags)
        apiFieldTags.callGet().then(({status, data}) => {
          if (status === 200 && data)
            setFieldTags(data.map(({id, name}) => ({id, name, label: name.toUpperCase()})));
        });

      if (states === undefined)
        apiFacilityStates.callGet().then(({status, data}) => {
          if (status === 200 && data?.length > 0)
            setStates(data.map(({id, name}) => ({id, name, label: name.toUpperCase()})));
          else setStates(null);
        });
    }
  }, [isMounted, apiFieldTags, fieldTags, apiFacilityStates, states]);

  useEffect(() => {
    if (isMounted() && fieldTags && states !== undefined && !initialized?.current) {
      if (target && freq) {
        const {times, recipients} = target;
        const freqNormalized = freq.toLowerCase();
        let occurrenceMapper = null;

        if (freqNormalized === "daily") {
          occurrenceMapper = time => ({time: formatTime(time.hour, time.min)});
        } else if (freqNormalized === "weekly") {
          occurrenceMapper = time => {
            const dayOfWeek = WEEKDAYS_IDX[time.dayOfWeek];

            return {
              time: formatTime(time.hour, time.min),
              dayOfWeek: [dayOfWeek]
            };
          };
        } else if (freqNormalized === "monthly") {
          occurrenceMapper = time => ({
            time: formatTime(time.hour, time.min),
            dayOfMonth: time.dayOfMonth
          });
        } else if (freqNormalized === "annual") {
          occurrenceMapper = time => {
            let now = dayjs();
            now = now.month(time.month - 1);
            now = now.date(time.dayOfMonth);
            return {
              dayOfYear: formatDate(now),
              time: formatTime(time.hour, time.min)
            };
          };
        }

        let initialType = type;
        let initialState = null;
        if (type?.includes("StateRemind")) {
          initialType = "StateRemind";
          initialState = type.replace("StateRemind", "");
        } else if (type?.includes("State")) {
          initialType = "State";
          initialState = type.replace("State", "");
        }

        const notification = {
          type: initialType,
          frequency: freqNormalized !== "none" ? freqNormalized : null,
          occurrences: times ? times.map(occurrenceMapper) : null,
          recipients: listIsRole === hasRoleRecipientsEnabled ? Object.values(recipients) : []
        };

        if (initialState) notification.state = initialState.toLowerCase();

        if (target?.tags) {
          notification.includeTags = target.tags.length > 0;
          notification.tags = fieldTags.filter(tag => target.tags.includes(tag.id));
        }

        setValue("notification", notification);
      }

      initialized.current = true;
    }
  }, [
    isMounted,
    states,
    fieldTags,
    target,
    initialized,
    freq,
    type,
    listIsRole,
    hasRoleRecipientsEnabled,
    setValue
  ]);

  // Reinitialize Occurrences
  useEffect(() => {
    if (isMounted() && initialized?.current && prevFrequency && prevFrequency !== watchFrequency)
      setValue("notification.occurrences", []);
  }, [isMounted, initialized, prevFrequency, watchFrequency, setValue]);

  const getConfigMismatchWarning = () => {
    if (
      listIsRole === undefined ||
      listIsRole === hasRoleRecipientsEnabled ||
      watchRecipients?.length > 0
    )
      return "";

    return (
      <WarningWrapper>
        <Warning>Warning:</Warning>
        Role recipients is {listIsRole ? "disabled" : "enabled"}. Please reassign notification group
        to a list of roles.
      </WarningWrapper>
    );
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>Configure Notification</Heading>
      <FormProvider {...form}>
        {initialized?.current && states !== undefined ? (
          <Form onSubmit={handleSubmit(handleSave)} noValidate>
            <InputSelect
              name="notification.type"
              label="Type"
              placeholder="Select type..."
              options={
                states
                  ? NOTIFICATION_TYPES
                  : NOTIFICATION_TYPES.filter(({value}) => !value.includes("State"))
              }
              required
            />

            {watchType && NOTIFICATION_DESC[watchType] && (
              <Description>{NOTIFICATION_DESC[watchType]}</Description>
            )}

            {(watchType === "State" || watchType === "StateRemind") && (
              <FormField>
                <InputRadioGroup
                  name="notification.state"
                  label="Target State"
                  options={states}
                  required
                />
              </FormField>
            )}

            {watchType === "Completed" && (
              <>
                <InputCheck name="notification.includeTags">
                  Do you want to include tagged fields&nbsp;
                  <Help>
                    All fields with specified tags will be shown at the top of this notification.
                  </Help>
                </InputCheck>
                {watchIncludeTags && (
                  <>
                    <SearchWrapper>
                      <SearchSelect
                        placeholder="Find tags.."
                        results={fieldTags}
                        setResults={setFieldTags}
                        search={query => {
                          if (!query) setFieldTags(undefined);
                          else
                            setFieldTags(prev =>
                              prev.filter(({name}) =>
                                name.toLowerCase().includes(query.toLowerCase())
                              )
                            );
                        }}
                        add={selected => {
                          if (!watchTags) setValue("notification.tags", [{...selected}]);
                          else if (!watchTags.map(({id}) => id).includes(selected.id))
                            setValue("notification.tags", [...watchTags, {...selected}]);
                        }}
                        showAll
                      />
                      <InputError name="notification.tags" errors={errors} />
                    </SearchWrapper>
                    <Inline>
                      {watchTags?.map(({id, name}) => (
                        <Button
                          key={`tag-${id}`}
                          onClick={() =>
                            setValue(
                              "notification.tags",
                              watchTags.filter(curr => {
                                if (curr.id !== id) return true;
                                return false;
                              })
                            )
                          }
                          quiet>
                          {name.toUpperCase()}&nbsp;
                          <FontAwesomeIcon icon={faClose} />
                        </Button>
                      ))}
                    </Inline>
                  </>
                )}
              </>
            )}

            {hasFrequency(watchType) && (
              <>
                <InputSelect
                  name="notification.frequency"
                  label="SEND"
                  placeholder="Select frequency..."
                  options={FREQUENCY_OPTIONS}
                  required
                />
                {watchFrequency && (
                  <FormFieldWrapper>
                    <Label bold>{watchFrequency === "daily" ? "AT" : "ON"} *</Label>
                    <InputNotification type={watchFrequency} name="notification" />
                    <InputError atBottom={false} errors={errors} name="notification.occurrences" />
                  </FormFieldWrapper>
                )}
              </>
            )}

            {watchType && (
              <>
                <SearchWrapper>
                  <SearchSelect
                    label="RECIPIENTS *"
                    placeholder={`Send to ${hasRoleRecipientsEnabled ? "roles" : "users"}...`}
                    search={hasRoleRecipientsEnabled ? handleSearchRole : handleSearchUser}
                    results={users}
                    setResults={setUsers}
                    add={selected => {
                      if (!watchRecipients)
                        setValue("notification.recipients", [
                          {selected, isRole: hasRoleRecipientsEnabled}
                        ]);
                      else if (!watchRecipients.map(({id}) => id).includes(selected.id))
                        setValue("notification.recipients", [
                          ...watchRecipients,
                          {...selected, isRole: hasRoleRecipientsEnabled}
                        ]);
                    }}
                    showAll
                  />
                </SearchWrapper>
                <Inline>
                  {watchRecipients?.map(({label, id, email, isRole}) => (
                    <Button
                      quiet
                      key={`${isRole ? "role-" : "email-"}${id}`}
                      onClick={() =>
                        setValue(
                          "notification.recipients",
                          watchRecipients.filter(curr => {
                            if (curr.id !== id) return true;
                            return false;
                          })
                        )
                      }>
                      {isRole ? label : email}&nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </Inline>
                <InputError
                  atBottom={false}
                  errors={watchRecipients && watchRecipients.length ? {} : errors}
                  name="notification.recipients"
                />
                {getConfigMismatchWarning()}
              </>
            )}

            <ButtonWrapper>
              <Button type="submit">Save</Button>
              <Button type="button" onClick={() => setManage(false)}>
                Cancel
              </Button>
            </ButtonWrapper>
          </Form>
        ) : (
          <NotLoaded>
            <Loader />
          </NotLoaded>
        )}
      </FormProvider>
    </Modal>
  );
};

ModalNotification.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  setManage: PropTypes.func.isRequired,
  target: PropTypes.objectOf(PropTypes.any),
  freq: PropTypes.string,
  type: PropTypes.string
};

ModalNotification.defaultProps = {
  target: null,
  freq: null,
  type: null
};

// Style Overrides
const Description = styled(Small)`
  margin-top: ${pad}px;
`;

const SearchWrapper = styled.div`
  position: relative;
  margin: ${pad}px 0;
`;

const WarningWrapper = styled.p`
  margin-top: ${pad / 2}px;
  ${voice.quiet};
`;

const Warning = styled.strong`
  color: ${({theme}) => theme.warning};
`;

const ButtonWrapper = styled(Inline)`
  margin-top: ${pad * 3}px;
`;

export default ModalNotification;
