import React, {useEffect, useLayoutEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputText from "./InputText.js";
import InputError from "./InputError.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Button, FormFieldWrapper, Label} from "../../style/components/general.js";

const InputTextGroup = ({
  testId = "input-text-group",
  name,
  label,
  keyPlaceholder = "Key",
  valuePlaceholder = "Value",
  required = false,
  canSetKey = false,
  limit = 10,
  triggerAppend
}) => {
  const hasChanged = useRef(false);

  const {
    control,
    formState: {errors, isSubmitted},
    trigger,
    setValue,
    watch
  } = useFormContext();

  const fields = watch(name);

  const {append, remove, insert} = useFieldArray({control, name});

  useEffect(() => {
    if (triggerAppend) append(triggerAppend);
  }, [triggerAppend, append]);

  useLayoutEffect(() => {
    if (fields && fields.length > 0) {
      if (hasChanged.current && isSubmitted) trigger(name);
      else if (isSubmitted) hasChanged.current = true;
    } else {
      hasChanged.current = false;
      setValue(name, canSetKey ? [{key: "", value: ""}] : [{option: ""}]);
    }
  }, [append, fields, name, trigger, setValue, canSetKey, isSubmitted]);

  return (
    <StyledFormFieldWrapper data-testid={testId} standard>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      {fields &&
        fields.length > 0 &&
        fields.map((option, index) => (
          <StyledInlineFormField key={option.id || index}>
            {canSetKey && (
              <InputText
                testId={`${testId}-key`}
                name={`${name}.${index}.key`}
                placeholder={keyPlaceholder}
                defaultValue={option.key || null}
                required={required}
              />
            )}

            <InputText
              testId={`${testId}-value`}
              name={`${name}.${index}.${canSetKey ? "value" : "option"}`}
              placeholder={valuePlaceholder}
              defaultValue={option.option || option.value || null}
              required={required}
            />

            <StyledEdit
              disable={index === 0 && fields.length === 1}
              type="button"
              data-testid={`${testId}.removeInput`}
              onClick={() => remove(index)}>
              <FontAwesomeIcon icon={faMinus} />
            </StyledEdit>
            <StyledEdit
              disable={fields.length === limit}
              type="button"
              data-testid={`${testId}.addInput`}
              onClick={() => {
                insert(index + 1, canSetKey ? {key: "", value: ""} : {option: ""});
              }}>
              <FontAwesomeIcon icon={faPlus} />
            </StyledEdit>
          </StyledInlineFormField>
        ))}
      <InputError errors={errors} name={name} />
    </StyledFormFieldWrapper>
  );
};

InputTextGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  keyPlaceholder: PropTypes.string,
  valuePlaceholder: PropTypes.string,
  required: PropTypes.bool,
  canSetKey: PropTypes.bool,
  triggerAppend: PropTypes.objectOf(PropTypes.any),
  limit: PropTypes.number
};

// Style Overrides
const StyledInlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin: 0;
  align-items: space-between;

  div {
    margin: 0;

    &:nth-child(2) {
      margin-left: ${pad}px;
    }
  }
`;

const StyledEdit = styled(Button)`
  padding: 0 ${pad}px;
  margin-left: ${pad}px;
  height: 35px;

  ${props =>
    props.disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  gap: ${pad * 2}px;
`;

export default InputTextGroup;
