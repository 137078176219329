import React, {useState, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import InputTextGroupDraggable from "../../components/form/InputTextGroupDraggable.js";
import {InputText, InputCheck} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  FormField,
  Button,
  Form,
  FormGroup,
  ButtonLoader,
  HeadingCenter,
  Label
} from "../../style/components/general.js";

const schema = yup.object().shape({
  name: yup.string().required("Please provide name"),
  details: yup.object().shape({
    hasJobs: yup.bool(),
    jobs: yup.array().when("hasJobs", {
      is: val => !!val,
      then: () =>
        yup.array().of(
          yup.object().shape({
            option: yup.string().required("Job # required when checked")
          })
        )
    }),
    hasNotes: yup.bool(),
    notes: yup.array().when("hasNotes", {
      is: val => !!val,
      then: () =>
        yup.array().of(
          yup.object().shape({
            key: yup.string().required("Key required when checked"),
            value: yup.string().required("Value required when checked")
          })
        )
    }),
    hasContacts: yup.bool(),
    contacts: yup.array().when("hasContacts", {
      is: val => !!val,
      then: () =>
        yup.array().test({
          message: "At least one email is required.",
          test: arr => arr.length > 0
        })
    })
  })
});

const ModalEditFacility = ({visible, setVisible, facility, setFacility}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const [loading, setLoading] = useState(false);

  const {api} = useApi("facilities");

  const form = useForm({
    defaultValues: {
      ...facility,
      details: {
        ...facility.details,
        hasJobs: facility.details?.jobs?.length > 0,
        jobs:
          facility.details?.jobs?.length > 0
            ? facility.details.jobs?.map(job => ({option: job}))
            : [{option: ""}],
        hasNotes: facility.details?.notes?.length > 0,
        notes: facility.details?.notes?.length > 0 ? facility.details.notes : [{key: "", value: ""}]
      }
    },
    resolver: yupResolver(schema)
  });
  const {setValue, handleSubmit, watch} = form;

  const hasJobs = watch("details.hasJobs");
  const hasNotes = watch("details.hasNotes");

  useEffect(() => {
    const {details} = facility;
    if (!hasJobs && !details.jobs) setValue("details.jobs", [{option: ""}]);
    if (!hasNotes && !details.notes) setValue("details.notes", [{key: "", value: ""}]);
  }, [facility, hasJobs, hasNotes, setValue]);

  const editFacility = values => {
    setLoading(true);

    const {name, details} = values;

    const sanitizedName = name.replace(/\s+/g, " ").trim();

    const editFacilityData = {
      name: sanitizedName,
      details: {
        jobs:
          details.hasJobs && details?.jobs?.length > 0 ? details.jobs.map(job => job.option) : [],
        notes: details.hasNotes && details?.notes?.length > 0 ? details.notes : []
      }
    };

    api
      .callPut(facility.id, editFacilityData)
      .then(({status, data}) => {
        if (status === 200 && data) {
          const updated = data.facility;
          setFacility(prev => ({
            ...prev,
            name: updated.name,
            slug: updated.slug,
            details: updated.details,
            addresses: updated.addresses
          }));

          window.history.pushState({}, "Facility", updated.slug);

          // when changing facility name, need to update corresponding paths in facility dashboard tabs
          const matchedPathName = pathname.match(/^\/.+\/.+\/(.+)\/?/);
          const tabPathName = matchedPathName ? `/${matchedPathName[1]}` : "";
          navigate(`/facilities/${updated.slug}${tabPathName}`);
        }
      })
      .finally(() => {
        setLoading(false);
        setVisible(false);
      });
  };

  return (
    facility && (
      <Modal visible={visible} setVisible={setVisible}>
        <ModalTitle>Edit Facility Information</ModalTitle>

        <FormProvider {...form}>
          <Form noValidate>
            <FormGroup>
              <FormField>
                <InputText name="name" placeholder="Name" label="Name" required />
              </FormField>

              <FormField>
                <Label bold>TYPE:</Label> {facility?.type}
              </FormField>
            </FormGroup>

            <FormGroup>
              <InputCheck name="details.hasJobs">Include Job number(s)</InputCheck>
              {hasJobs && (
                <FormField>
                  <InputTextGroupDraggable
                    name="details.jobs"
                    valuePlaceholder="Job #"
                    limit={15}
                  />
                </FormField>
              )}

              <InputCheck name="details.hasNotes">Include Note(s)</InputCheck>
              {hasNotes && (
                <FormField>
                  <InputTextGroupDraggable name="details.notes" keyPlaceholder="Label" canSetKey />
                </FormField>
              )}
            </FormGroup>

            <FormField>
              <Submit type="button" onClick={handleSubmit(editFacility)} loading={loading ? 1 : 0}>
                Save {loading && <ButtonLoader />}
              </Submit>
            </FormField>
          </Form>
        </FormProvider>
      </Modal>
    )
  );
};

ModalEditFacility.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  setFacility: PropTypes.func.isRequired,
  setRefreshFacility: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Submit = styled(Button)`
  margin-bottom: ${pad}px;
`;

export default ModalEditFacility;
