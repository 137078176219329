import React, {useContext, useEffect, useState} from "react";
import {
  Map as GoogleMap,
  AdvancedMarker as Marker,
  InfoWindow,
  useMap
} from "@vis.gl/react-google-maps";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../contexts/auth.js";

// Hooks
import useApi from "../hooks/useApi.js";

// Utils
import {getMapFromCoords, openLink} from "../utils/helpers.js";
import MapProvider from "../utils/google/maps.js";

// Styles
import {voice} from "../style/components/typography.js";
import {border, colors, radius, pad, heroTheme} from "../style/components/variables.js";
import {Inline, Label, Text} from "../style/components/general.js";

const maps = new MapProvider();

const HYBRID = "hybrid";
const ROADMAP = "roadmap";
const MAPTYPES = [HYBRID, ROADMAP];

const DEFAULT_CENTER = {lat: 35.0494378, lng: -120.5876814}; // office

const Map = ({
  locations,
  editLocation,
  activeMarker,
  setActiveMarker,
  querying = false,
  refreshMarkers = true,
  setRefreshMarkers
}) => {
  const {atLeast} = useContext(AuthContext);

  const map = useMap();

  const [markers, setMarkers] = useState([]);

  const {api} = useApi("files");

  useEffect(() => {
    const lookup = {};
    const bounds = maps.getBounds();
    const tempMarkers = [];
    if (refreshMarkers && map) {
      locations.map(({id, lat, lon, draggable, ...rest}) => {
        // Add marker offset for markers on same coordinates
        if (lookup[lat] && lookup[lat] === lon) lon += 0.00002;

        const formatted = maps.getLatLng(lat, lon);

        bounds.extend(formatted);

        lookup[lat] = lon;

        tempMarkers.push({
          id,
          coords: formatted,
          draggable: atLeast("creator") && draggable,
          ...rest
        });
      });

      setMarkers(tempMarkers);

      if (locations?.length > 0) map.fitBounds(bounds);
      if (setRefreshMarkers) setRefreshMarkers(false);
    }
  }, [refreshMarkers, map, atLeast, locations, setRefreshMarkers]);

  const handleActiveMarker = marker => {
    if (marker === activeMarker) return;
    setActiveMarker(marker);
  };

  return (
    atLeast("general") &&
    locations && (
      <MapWrapper>
        {locations.length > 0 ? (
          <GoogleMap
            mapId="72dcf0e07eec3903"
            mapTypeId={HYBRID}
            defaultCenter={DEFAULT_CENTER}
            defaultZoom={18} // average supported
            options={{
              mapTypeControlOptions: {
                mapTypeIds: MAPTYPES
              }
            }}>
            {markers.map(({id, label, coords, details, draggable, marker, primary}) => (
              <Marker
                key={id}
                position={coords}
                onClick={() => handleActiveMarker(id)}
                draggable={draggable && !!editLocation}
                onDragEnd={editLocation ? e => editLocation(e.latLng, id) : null}>
                <MarkerIcon
                  icon={marker?.icon || "map-marker-alt"}
                  color={marker?.color ? `#${marker.color}` : heroTheme.primary}
                  strokecolor={marker?.border || heroTheme.secondary}
                  strokeweight={marker?.weight || 30}
                />
                {activeMarker === id && (
                  <InfoWindow position={coords} onCloseClick={() => setActiveMarker(null)}>
                    <MarkerContent>
                      <DirLink href={getMapFromCoords(coords.lat(), coords.lng())}>
                        <MarkerLabel>
                          {label || (primary ? "Primary Location" : "Location")}
                        </MarkerLabel>
                        &nbsp;
                        <FontAwesomeIcon icon={faCompass} />
                      </DirLink>
                      {details?.map(
                        ({key, value, fileId}) =>
                          key && (
                            <MarkerText key={key}>
                              {key}:&nbsp;
                              {fileId ? (
                                <FileLink
                                  type="button"
                                  onClick={() =>
                                    api
                                      .callGet(fileId)
                                      .then(
                                        ({status, data}) => status === 200 && openLink(data.link)
                                      )
                                  }>
                                  Open File
                                </FileLink>
                              ) : (
                                value
                              )}
                            </MarkerText>
                          )
                      )}
                    </MarkerContent>
                  </InfoWindow>
                )}
              </Marker>
            ))}
          </GoogleMap>
        ) : (
          <Text center>
            {querying
              ? "No locations found"
              : "Map is not available, no locations have been provided."}
          </Text>
        )}
      </MapWrapper>
    )
  );
};

Map.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.any).isRequired,
  editLocation: PropTypes.func,
  activeMarker: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setActiveMarker: PropTypes.func.isRequired,
  querying: PropTypes.bool,
  refreshMarkers: PropTypes.bool,
  setRefreshMarkers: PropTypes.func
};

// Style
const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: ${border} solid ${colors.heroBlack};
  border-radius: ${radius};
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const MarkerIcon = styled(FontAwesomeIcon)`
  fill: ${({color}) => color};
  height: 25px;

  path {
    stroke: ${({strokecolor}) => strokecolor};
    stroke-width: ${({strokeweight}) => strokeweight};
  }
`;

const MarkerContent = styled.div`
  padding: 0 ${pad / 2}px;
`;

const DirLink = styled.a`
  svg {
    transition: all ease 0.5s;
  }

  &:hover svg {
    fill: ${({theme}) => theme.primary};
  }
`;

const MarkerLabel = styled(Label)`
  font-weight: bold;
  color: ${colors.heroBlack};
`;

const MarkerText = styled(Inline)`
  ${voice.quiet};
  color: ${colors.heroBlack};
`;

const FileLink = styled.button`
  ${voice.quiet};
  color: ${({theme}) => theme.primary};
  text-decoration: underline;
`;

export default Map;
