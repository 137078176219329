import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {pad} from "../style/components/variables.js";
import {Select} from "../style/components/general.js";

const Dropdown = ({
  testId = "Dropdown",
  options,
  placeholder = "",
  showPlaceholder = false,
  selection = "",
  setSelection,
  required = true,
  disabled = false,
  fullWidth = false,
  other = false,
  spaced = false
}) => {
  const sanitizeValue = option => {
    if (option && option.value) return option.value;
    if (option && option.option) return option.option;
    if (option && option.name) return option.name;
    return option || "";
  };

  const sanitizeLabel = option => {
    if (option && option.label) return option.label;
    if (option && option.option) return option.option;
    if (option && option.name) return option.name;
    if (option && option.value) return option.value;
    return option || "";
  };

  return (
    <Wrapper style={{marginTop: spaced ? `${pad}px` : 0}}>
      <Select
        data-testid={testId}
        value={selection}
        onChange={e => setSelection(e.target.value)}
        required={required}
        disabled={disabled}
        fullWidth={fullWidth}>
        {placeholder && (
          <option
            value=""
            hidden={!showPlaceholder}
            data-testid={testId ? `${testId}.option` : "option"}>
            {placeholder}
          </option>
        )}
        {options &&
          options.map(option => (
            <option
              key={sanitizeValue(option)}
              value={sanitizeValue(option).toString().toLowerCase()}
              data-testid={testId ? `${testId}.option` : "option"}>
              {sanitizeLabel(option)}
            </option>
          ))}
        {other && (
          <option value="other" data-testid={testId ? `${testId}.option` : "option"}>
            Other
          </option>
        )}
      </Select>
    </Wrapper>
  );
};

Dropdown.propTypes = {
  testId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  placeholder: PropTypes.string,
  showPlaceholder: PropTypes.bool,
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelection: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  other: PropTypes.bool,
  spaced: PropTypes.bool
};

// Style Overrides
const Wrapper = styled.div`
  align-self: start;
  width: 100%;
`;

export default Dropdown;
