import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router-dom";

// Contexts
import {AuthContext} from "../contexts/auth.js";
import {SettingsContext} from "../contexts/settings.js";

// Hooks
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {scrollTo} from "./checksheet-builder/helpers.js";

// Components
import EnterpriseSettings from "./account/EnterpriseSettings.js";
import UserDetails from "./account/UserDetails.js";
import Security from "./account/Security.js";
import Integrations from "./account/Integrations.js";
import {InfoCell, InfoItem, InfoLabel} from "./account/components.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {pad, border, radius} from "../style/components/variables.js";
import {breakpoint} from "../style/components/breakpoints.js";
import {Page, Heading, Button} from "../style/components/general.js";

const Account = () => {
  const isMounted = useMountedState();

  const {atLeast, currentUser} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  const {hash} = useLocation();

  const [editEnterprise, setEditEnterprise] = useState(false);
  const [editIntegrations, setEditIntegrations] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editSecurity, setEditSecurity] = useState(false);

  useEffect(() => {
    if (isMounted() && hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) scrollTo(element, 0);
        // removes hash from url
        window.history.replaceState(null, null, " ");

        if (hash === "#userDetails") setEditUser(true);
        if (hash === "#enterpriseSettings") setEditEnterprise(true);
        if (hash === "#securitySettings") setEditSecurity(true);
      }, 300);
    }
  }, [hash, isMounted]);

  return (
    currentUser && (
      <Page>
        <Settings>
          {atLeast("super") && (
            <>
              <TitleColumn>
                <Heading>Enterprise Settings</Heading>
              </TitleColumn>
              <InfoColumn>
                <Edit
                  type="button"
                  onClick={() => setEditEnterprise(prev => !prev)}
                  cancel={editEnterprise}>
                  {editEnterprise ? "Cancel" : <FontAwesomeIcon icon={faEdit} />}
                </Edit>
                <EnterpriseSettings edit={editEnterprise} setEdit={setEditEnterprise} />
              </InfoColumn>
              <TitleColumn>
                <Heading>Integrations</Heading>
              </TitleColumn>
              <InfoColumn>
                {settings?.microsoft === "enabled" && (
                  <Edit
                    type="button"
                    onClick={() => setEditIntegrations(prev => !prev)}
                    cancel={editIntegrations}>
                    {editIntegrations ? "Cancel" : <FontAwesomeIcon icon={faEdit} />}
                  </Edit>
                )}
                <Integrations edit={editIntegrations} setEdit={setEditIntegrations} />
              </InfoColumn>
            </>
          )}

          <TitleColumn>
            <Heading>User Details</Heading>
          </TitleColumn>
          <InfoColumn>
            <Edit type="button" onClick={() => setEditUser(prev => !prev)} cancel={editUser}>
              {editUser ? "Cancel" : <FontAwesomeIcon icon={faEdit} />}
            </Edit>
            <UserDetails edit={editUser} setEdit={setEditUser} />
          </InfoColumn>

          <TitleColumn>
            <Heading>Security</Heading>
          </TitleColumn>
          <InfoColumn>
            <Edit
              type="button"
              onClick={() => setEditSecurity(prev => !prev)}
              cancel={editSecurity}>
              {editSecurity ? "Cancel" : <FontAwesomeIcon icon={faEdit} />}
            </Edit>
            <Security edit={editSecurity} setEdit={setEditSecurity} />
          </InfoColumn>
        </Settings>
      </Page>
    )
  );
};

const AccountRow = ({label, children}) => (
  <InfoItem>
    <InfoLabel>{label}</InfoLabel>
    <InfoCell>{children}</InfoCell>
  </InfoItem>
);

AccountRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

// Style Overrides
const Settings = styled.div`
  margin: ${pad}px;
  ${flex("row", "wrap")}
`;

const TitleColumn = styled.div`
  width: 100%;
  margin-bottom: ${pad}px;

  @media (min-width: ${breakpoint.width[2]}) {
    width: 25%;
  }
`;

const InfoColumn = styled.div`
  position: relative;
  width: 100%;
  min-width: 300px;
  margin-bottom: ${pad * 2}px;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};

  @media (min-width: ${breakpoint.width[2]}) {
    width: 75%;
  }
`;

const Edit = styled(Button)`
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: ${pad * 2}px;
  background: ${({cancel, theme}) => (cancel ? theme.secondary : 0)};
  z-index: ${z("above")};

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

export default Account;
