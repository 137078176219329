import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Hooks
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {exists} from "../utils/helpers.js";

// Style
import {border, pad, radius} from "../style/components/variables.js";
import {voice} from "../style/components/typography.js";
import {flex} from "../style/components/mixins.js";

const ScaleBar = ({state = 0, setState, min = 0, max = 3, length = 3, icon}) => {
  const isMounted = useMountedState();
  const step = useMemo(() => (max - min) / length, [length, max, min]);

  const [dragging, setDragging] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(Math.min(Math.round((state - min) / step), max));

  const sliderMarks = useMemo(() => {
    const marks = [];
    let i = 0;
    for (let val = min; val < max; val += step) {
      const iCapture = i;
      marks.push(
        <ScaleBarMark
          id={`sliderMark-${i}`}
          className="sliderMarks"
          key={`sliderMark-${i}`}
          mark={i}
          length={length}
          onClick={() => {
            if (!dragging) {
              setState(val);
              setCurrentIdx(iCapture);
            }
          }}
        />
      );
      i++;
    }
    return marks;
  }, [min, max, step, length, dragging, setState]);

  useEffect(() => {
    const resetDrag = () => {
      setDragging(false);
    };

    if (isMounted()) {
      window.addEventListener("mouseup", resetDrag);
    }

    return () => {
      if (!isMounted()) window.removeEventListener("mouseup", resetDrag);
    };
  }, [isMounted]);

  return (
    <ScaleBarWrapper
      draggable={false}
      onMouseDown={() => {
        setDragging(true);
      }}
      onMouseLeave={() => setDragging(false)}
      onMouseMove={e => {
        if (dragging) {
          const marks = document.getElementsByClassName("sliderMarks");
          if (marks) {
            // get distances from each mark to mouse
            const distances = Array.prototype.slice.call(marks).map(mark => {
              const rect = mark?.getBoundingClientRect();
              if (rect) return Math.abs(e.clientX - rect.left);
              // null values (due to client rect not being found) are skipped when determining min distance
              return null;
            });

            let minDist = null;
            let minIdx = null;
            // iterate over distances, skipping null values (client rect not found)
            distances.map((distance, i) => {
              if (exists(distance) && (!exists(minIdx) || distance < min)) {
                minDist = distance;
                minIdx = i;
              }
            });

            let currentDist = null;
            const currentMark = document.getElementById(`sliderMark-${currentIdx}`);
            const rect = currentMark?.getBoundingClientRect();
            // get distance of current mark from mouse
            if (rect) currentDist = Math.abs(e.clientX - rect.left);
            if (exists(minIdx) && (!exists(currentDist) || minDist < currentDist)) {
              // only change state when the closest mark is closer than the currently selected
              const newState = minIdx * step + min;
              setState(newState);
              setCurrentIdx(minIdx);
            }
          }
        }
      }}>
      {icon && (
        <SmallerIcon>
          <FontAwesomeIcon icon={icon} />
        </SmallerIcon>
      )}
      <TrackWrapper>
        <ScaleBarOutline draggable={false}>
          {sliderMarks}
          <ScaleBarIcon state={currentIdx} length={length} />
        </ScaleBarOutline>
      </TrackWrapper>
      {icon && (
        <LargerIcon>
          <FontAwesomeIcon icon={icon} />
        </LargerIcon>
      )}
    </ScaleBarWrapper>
  );
};

ScaleBar.propTypes = {
  state: PropTypes.number,
  setState: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  length: PropTypes.number,
  icon: PropTypes.objectOf(PropTypes.any)
};

// Style Overrides
const ScaleBarWrapper = styled.div`
  display: flex;
  padding: ${pad * 0.85}px ${pad}px;
  border: ${border} solid ${props => props.theme.tertiary};
  background-color: ${props => props.theme.secondary};
  border-radius: ${radius};
`;

const ScaleBarOutline = styled.div`
  position: relative;
  width: 150px;
  height: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${pad}px;
  border-radius: 5px;
  background: ${props => props.theme.tertiary};
  transition: all ease 0.1s;

  &:hover {
    cursor: pointer;
  }
`;

const ScaleBarMark = styled.span`
  position: absolute;
  left: calc(-2px + 100% * (${props => props.mark}) / (${props => props.length - 1}));
  width: 4px;
  height: 7px;

  &:after {
    content: "";
    position: absolute;
    background: ${props => props.theme.tertiary};
    border-radius: 1px;
    width: 2px;
    left: 1px;
    top: 0;
    height: 7px;
  }
`;

const ScaleBarIcon = styled.span`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: -5px;
  left: calc(-6.5px + 100% * (${props => props.state}) / (${props => props.length - 1}));
  width: 9px;
  height: 9px;
  background: ${props => props.theme.primary};
  border-radius: 50%;
  margin: 2px;
  transition: all ease 0.1s;

  &:hover {
    position: absolute;
    transform: scale(1.2);
    transform-origin: center center;
  }
`;

const SmallerIcon = styled.div`
  svg {
    ${voice.quiet}
    fill: ${props => props.theme.tertiary};
  }
`;

const LargerIcon = styled.div`
  svg {
    fill: ${props => props.theme.tertiary};
  }
`;

const TrackWrapper = styled.div`
  width: 175px;
  ${flex("row", "nowrap", "center", "center")}
  padding-left: ${pad * 0.8}px;
`;

export default ScaleBar;
