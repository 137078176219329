import {heroTheme} from "../../style/components/variables";

export const FACILITY_MARKER = {
  id: "facility",
  icon: "map-marker-alt",
  color: heroTheme.primary.split("#")[1]
};

export const DEFAULT_MARKER = {
  id: "default",
  label: "Uncategorized",
  icon: "map-marker-alt",
  color: "d95140"
};

/**
 * @param {array} address google formatted string
 * @returns {object}
 */
export const formatAddressComponents = components => {
  let line1 = "";
  let city = "";
  let state = "";
  let zipCode = "";

  components.map(part => {
    part.types.map(type => {
      if (type === "street_number") line1 = part.long_name;
      if (type === "route") line1 += ` ${part.long_name}`;
      if (type === "locality") city = part.long_name;
      if (type === "administrative_area_level_1") state = part.short_name;
      if (type === "postal_code") zipCode = part.long_name;
    });
  });

  return {
    line1,
    city,
    state,
    zipCode
  };
};

/**
 * @param {object} address local
 * @returns {string} formatted address string
 */
export const formatAddressForGeocode = address =>
  `${address.line1}, ${address.city}, ${address.state} ${address.zipCode}`;

class MapProvider {
  constructor() {
    this.exists = () => window.google;
    this.provider = () => window.google && window.google.maps;
  }

  /**
   * Google Map Bounds
   * @returns {func}
   */
  getBounds() {
    if (this.provider()) return new window.google.maps.LatLngBounds();
    return null;
  }

  /**
   * Google Map LatLng
   * @param {string} lat
   * @param {string} lon
   * @returns {func}
   */
  getLatLng(lat, lon) {
    if (this.provider()) return new window.google.maps.LatLng(lat, lon);
    return null;
  }

  /**
   * Google Map Point
   * @param {number} x
   * @param {number} y
   * @returns {func}
   */
  getPoint(x, y) {
    if (this.provider()) return new window.google.maps.Point(x, y);
    return null;
  }

  /**
   * Google Geocode API
   * @param {object} params
   * @param {func} callback
   */
  async geocode(params, callback) {
    if (this.provider()) {
      const geocoder = new window.google.maps.Geocoder();
      return geocoder.geocode(params, callback);
    }
    return null;
  }
}

export default MapProvider;
