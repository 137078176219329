import React, {useState, useCallback, useEffect, useContext, useRef} from "react";
import {useReactToPrint} from "react-to-print";
import {FormProvider, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faPrint} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import Chart from "../../components/Chart.js";
import Modal from "../../components/Modal.js";
import InputSelect from "../../components/form/InputSelect.js";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {
  FormGroup,
  Label,
  FormFieldWrapper,
  Input,
  Inline,
  Button,
  Form,
  HeadingCenter,
  StickyWrapper,
  ModalButton
} from "../../style/components/general.js";

const ModalCompareAnalytic = ({visible, setVisible}) => {
  const isMounted = useMountedState();

  const {facility} = useContext(FacilityNavContext);
  const {roleCanAccessResource} = useContext(AuthContext);

  const [analytics, setAnalytics] = useState([]);
  const [results, setResults] = useState(null);
  const [selected, setSelected] = useState([null, null]);

  const [chartA, setChartA] = useState(null);
  const [chartB, setChartB] = useState(null);

  // Filters
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const {api: apiAnalytics} = useApi("facility-analytics", {
    suppress: {success: true, error: true}
  });

  const dropdownOptions = [
    "None",
    "Last 7 Days",
    "Last 30 Days",
    "Last 90 Days",
    "Last Year",
    "Custom Range"
  ];

  const schema = yup.object().shape({
    snapshot: yup.string()
  });

  const form = useForm({
    defaultValues: {snapshot: "none"},
    resolver: yupResolver(schema)
  });
  const {watch} = form;
  const snapshot = watch("snapshot");

  // Initial Load
  useEffect(() => {
    if (isMounted() && facility?.name)
      apiAnalytics.callGet(null, {facilityName: facility.name}).then(({status, data}) => {
        if (status === 200)
          setAnalytics(
            data?.analytics?.map(({label, facilityType, ...rest}) => ({
              label: `${label.toUpperCase()} (${facilityType})`,
              ...rest
            })) || []
          );
      });
  }, [isMounted, facility, apiAnalytics]);

  const getCharts = useCallback(
    (newStart = null, newEnd = null) => {
      const params = {
        facilityName: facility.name,
        sourcesA: selected[0]?.data?.sources,
        sourcesB: selected[1]?.data?.sources,
        idA: selected[0]?.id,
        idB: selected[1]?.id
      };
      if (snapshot) params.snapshot = snapshot.toLowerCase();
      if (newStart) params.start = newStart;
      if (newEnd) params.end = newEnd && dayjs(newEnd).add(1, "day").format("YYYY-MM-DD");
      if (selected[0] || selected[1])
        apiAnalytics.callGet(null, params).then(({status, data}) => {
          if (status === 200 && data) {
            const {chartA: a, chartB: b} = data;
            setChartA(a);
            setChartB(b);
            setStart(newStart);
            setEnd(newEnd);
          }
        });
    },
    [selected, snapshot, facility, apiAnalytics]
  );

  useEffect(() => getCharts(), [getCharts]);

  const search = query => {
    if (query) {
      const filtered =
        analytics.filter(
          analytic =>
            analytic &&
            analytic.type === "plot" &&
            analytic.label?.toLowerCase()?.includes(query.toLowerCase()) &&
            !selected?.some(res => res && res.id === analytic.id)
        ) || [];
      setResults(filtered);
    } else {
      const filtered =
        analytics?.filter(
          analytic =>
            analytic &&
            analytic.type === "plot" &&
            !selected?.some(res => res && res.id === analytic.id)
        ) || [];
      setResults(filtered);
    }
  };

  // Handle Print
  const printChartARef = useRef();
  const printChartBRef = useRef();
  const printRef = useRef();

  const configurePrint = () => {
    const printBody = document.createElement("div");

    const cA = printChartARef.current.cloneNode(true);
    const cB = printChartBRef.current.cloneNode(true);
    cA.firstChild.style.width = "fit-content";
    cA.firstChild.style.height = "fit-content";
    cA.firstChild.style.marginTop = "30px";
    printBody.appendChild(cA);
    cB.firstChild.style.width = "fit-content";
    cB.firstChild.style.height = "fit-content";
    cB.firstChild.style.marginTop = "30px";
    printBody.appendChild(cB);
    printRef.current = printBody;
  };

  const handlePrint = useReactToPrint({
    contentRef: printRef
  });

  const renderModalControls = () =>
    selected?.length > 0 && (
      <Controls>
        {roleCanAccessResource("analytic", "export") && chartA && chartB && (
          <ModalButton
            type="button"
            title="Print"
            onClick={() => {
              configurePrint();
              handlePrint();
            }}>
            <FontAwesomeIcon icon={faPrint} />
          </ModalButton>
        )}
      </Controls>
    );

  return (
    <Modal
      visible={visible}
      setVisible={state => setVisible(state)}
      maxWidth={breakpoint.width[5]}
      renderModalControls={renderModalControls()}>
      <ModalTitle>Compare Charts</ModalTitle>
      {(!chartA || !chartB) && (
        <StyledFormGroup>
          <SearchSelect
            label="Select Charts to Compare"
            placeholder="Find chart..."
            results={results}
            setResults={setResults}
            search={search}
            add={s => {
              setSelected(prev => {
                if (!prev) return [s, null];
                if (!prev[0]) return [s, prev[1]];
                return [prev[0], s];
              });
            }}
            showAll
          />
        </StyledFormGroup>
      )}
      <OuterContent>
        <Menu>
          <StickyDiv>
            <FormProvider {...form}>
              <Form>
                <FormGroup>
                  <InputSelect
                    testId="editSnapshotDropdown"
                    name="snapshot"
                    label="Snapshot"
                    options={dropdownOptions.map(option => ({
                      label: option,
                      value: option.toLowerCase()
                    }))}
                    fullWidth
                  />
                </FormGroup>
                {snapshot === "custom range" && (
                  <>
                    <DateSelectWrapper>
                      <Label bold>FROM</Label>
                      <Input
                        type="date"
                        onChange={e => getCharts(e.target.value, end)}
                        placeholder="Select Date ..."
                        hasValue={start}
                      />
                    </DateSelectWrapper>
                    <DateSelectWrapper>
                      <Label bold>TO</Label>
                      <Input
                        type="date"
                        onChange={e => getCharts(start, e.target.value)}
                        placeholder="Select Date ..."
                        hasValue={start}
                      />
                    </DateSelectWrapper>
                  </>
                )}
              </Form>
            </FormProvider>
          </StickyDiv>
        </Menu>
        <Content>
          <Left>
            {chartA ? (
              <SelectedChart key={`chart-${chartA.id}`}>
                <IconButton
                  onClick={() => {
                    setSelected(prev => [null, prev[1]]);
                    setChartA(null);
                  }}>
                  <FontAwesomeIcon icon={faClose} />
                </IconButton>
                <Chart
                  view="builder"
                  label={chartA?.label}
                  chart={chartA || {}}
                  allSources={chartA?.sources}
                  chartType={chartA?.chartType}
                />
                <PrintableChart>
                  <div ref={printChartARef}>
                    <Chart
                      printScale={{width: 300}}
                      view="preview-print"
                      label={chartA?.label}
                      chart={chartA || {}}
                      allSources={chartA?.sources}
                      chartType={chartA?.chartType}
                    />
                  </div>
                </PrintableChart>
              </SelectedChart>
            ) : (
              <Empty>No Chart Selected</Empty>
            )}
          </Left>
          <Right>
            {chartB ? (
              <SelectedChart key={`chart-${chartB.id}`}>
                <IconButton
                  onClick={() => {
                    setSelected(prev => [prev[0], null]);
                    setChartB(null);
                  }}>
                  <FontAwesomeIcon icon={faClose} />
                </IconButton>
                <Chart
                  view="builder"
                  label={chartB?.label}
                  chart={chartB || {}}
                  allSources={chartB?.sources}
                  chartType={chartB?.chartType}
                />
                <PrintableChart>
                  <div ref={printChartBRef}>
                    <Chart
                      printScale={{width: 300}}
                      view="preview-print"
                      label={chartB?.label}
                      chart={chartB || {}}
                      allSources={chartB?.sources}
                      chartType={chartB?.chartType}
                    />
                  </div>
                </PrintableChart>
              </SelectedChart>
            ) : (
              <Empty>No Chart Selected</Empty>
            )}
          </Right>
        </Content>
      </OuterContent>
    </Modal>
  );
};

ModalCompareAnalytic.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

// Style Overrides
const Controls = styled.div`
  display: flex;
  margin-right: ${pad}px;
`;

const OuterContent = styled(Inline)`
  width: 100%;
  display: block;

  ${bp(2)} {
    display: flex;
  }
`;

const Content = styled.section`
  position: relative;
  width: 100%;
  gap: ${pad}px;
  display: flex;
  flex-direction: column;

  ${bp(2)} {
    width: 79%;
  }

  ${bp(3)} {
    flex-direction: row;
  }
`;

const Left = styled.div`
  padding: ${pad}px;
  width: 100%;

  ${bp(3)} {
    width: 49%;
  }

  /* Mobile Landscape */
  @media (max-width: ${breakpoint.width[3]}) and (orientation: landscape) {
    display: none;
  }
`;

const Right = styled.div`
  padding: ${pad}px;
  width: 100%;

  ${bp(3)} {
    width: 49%;
  }
`;

const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${({theme}) => theme.secondary};
`;

const Menu = styled.div`
  width: 100%;
  align-self: stretch;

  ${bp(2)} {
    width: 20%;
  }

  min-width: 200px;
  padding-right: ${pad * 1.5}px;
`;

const DateSelectWrapper = styled(FormFieldWrapper)`
  max-width: 200px;
  margin-bottom: ${pad}px;
`;

const PrintableChart = styled.div`
  display: none;
`;

const SelectedChart = styled.div`
  position: relative;
  width: 100%;
`;

const IconButton = styled(Button)`
  font-size: 19px;
  background-color: transparent;
  width: min-content;
  padding: 0;
  position: absolute;
  top: 3px;
  right: 6px;

  z-index: ${z("above")};

  svg {
    fill: ${colors.heroBlack};
  }
`;

const Empty = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  width: 100%;
  height: 410px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const StickyDiv = styled(StickyWrapper)`
  width: 100%;
  padding: ${pad}px;

  ${bp(2)} {
    top: ${pad * 5}px;
    z-index: ${z("above")};
  }

  /* Mobile Landscape */
  @media (max-width: ${breakpoint.width[3]}) and (orientation: landscape) {
    padding: 0;
    margin: 0;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-left: ${pad}px;
`;

export default ModalCompareAnalytic;
