import React, {useEffect, useState, useContext} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {toTitleCase} from "../../utils/helpers.js";
import {COMPONENT, GROUP} from "../../utils/builder.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import Modal from "../../components/Modal.js";
import Location from "../../components/Location.js";
import SearchSelect from "../../components/SearchSelect.js";
import Help from "../../components/Help.js";
import ElementHelp from "../general/ElementHelp.js";
import {
  InputCheck,
  InputFileGroup,
  InputText,
  InputTextArea,
  InputSelect,
  InputTextGroup
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  HeadingCenter,
  Button,
  FormGroup,
  FormField,
  Form,
  Text,
  SearchWrapper,
  Pill
} from "../../style/components/general.js";

const defaultValues = {
  label: "",
  internalIds: [{}],
  hasGlobalPrompt: false,
  globalPrompt: null,
  base: false,
  displayTable: false,
  hasHelp: false,
  help: [{}],
  hasAddress: false,
  address: null,
  markerId: null
};

const ModalEditElement = ({
  visible,
  setVisible,
  targetElement,
  setTargetElement,
  persistEdit,
  builder,
  markerIconMap
}) => {
  const markers = Object.keys(markerIconMap).map(id => ({id, ...markerIconMap[id]}));

  const {currentUser} = useContext(AuthContext);

  const [triggerAppend, setTriggerAppend] = useState(null);
  const [results, setResults] = useState(markers);

  const {api: uploadFile} = useApi("files");

  const schema = yup.object().shape({
    label: yup
      .string()
      .required("Please provide an Element Name")
      .test({
        test: val => !val.match(/.*[.,'"].*/),
        message: "Element name may not contain periods, commas or quotation marks."
      }),
    hasGlobalPrompt: yup.boolean(),
    globalPrompt: yup.string().nullable(),
    base: yup.bool(),
    displayTable: yup.bool(),
    hasHelp: yup.bool(),
    help: yup
      .mixed()
      .nullable()
      .when("hasHelp", {
        is: val => !!val,
        then: () =>
          yup
            .array()
            .of(
              yup.object().shape({
                key: yup.string().nullable().required("Please provide a key."),
                value: yup.string().nullable().required("Please provide a value.")
              })
            )
            .test({
              test: val => Array.isArray(val) && val.length > 0,
              message: "Please provide help item."
            })
      }),
    hasAddress: yup.bool(),
    address: yup
      .mixed()
      .nullable()
      .when("hasAddress", {
        is: val => !!val,
        then: () =>
          yup.lazy(({lat, lon, line1}) => {
            if ((!lat || lat === "") && (!lon || lon === "") && line1 !== "" && line1 !== undefined)
              return yup.object().shape({
                line1: yup.string().required("Line 1 is required."),
                line2: yup.string().nullable(),
                city: yup.string().required("City is required."),
                state: yup.string().required("State is required."),
                zipCode: yup.string().required("Zip Code is required."),
                lat: yup.number().nullable(),
                lon: yup.number().nullable()
              });

            if (
              (!line1 || line1 === "") &&
              lat !== "" &&
              lat !== undefined &&
              lon !== "" &&
              lon !== undefined
            )
              return yup.object().shape({
                line1: yup.string().nullable(),
                line2: yup.string().nullable(),
                city: yup.string().nullable(),
                state: yup.string().nullable(),
                zipCode: yup.string().nullable(),
                lat: yup
                  .number()
                  .typeError("Provide valid latitude.")
                  .required("Latitude is required."),
                lon: yup
                  .number()
                  .typeError("Provide valid longitude.")
                  .required("Longitude is required.")
              });

            return yup.object().shape({
              line1: yup.string().required("Line 1 is required."),
              line2: yup.string().nullable(),
              city: yup.string().required("City is required."),
              state: yup.string().required("State is required."),
              zipCode: yup.string().required("Zip Code is required."),
              lat: yup
                .number()
                .typeError("Provide valid latitude.")
                .required("Latitude is required."),
              lon: yup
                .number()
                .typeError("Provide valid longitude.")
                .required("Longitude is required.")
            });
          })
      }),
    markerId: yup.string().nullable()
  });

  const form = useForm({
    defaultValues: {...defaultValues, ...targetElement},
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit, reset, setValue} = form;
  const field = watch();
  const hasAddress = watch("hasAddress");
  const help = watch("help");
  const markerId = watch("markerId");

  const addFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    uploadFile
      .callPost(formData, {
        params: {
          userId: currentUser.publicId
        }
      })
      .then(({status, data: body}) => {
        if (status === 201) {
          let wasSet = false;
          if (help?.length > 0)
            for (let i = 0; i < help.length; i++) {
              const {key, value} = help[i] || {};
              if (!key && !value) {
                setValue(`help.${i}`, {
                  key: body.data.label.split(".")[0],
                  value: body.data.label,
                  fileId: body.data.id,
                  export: body.data.export || false
                });
                wasSet = true;
                break;
              }
            }

          if (!wasSet)
            setTriggerAppend({
              key: body.data.label.split(".")[0],
              value: body.data.label,
              fileId: body.data.id,
              export: body.data.export || false
            });
        }
      });
  };

  useEffect(() => {
    if (!visible) {
      reset({...defaultValues});
      setTargetElement(null);
    }
  }, [targetElement, visible, reset, setTargetElement]);

  const search = query => {
    if (query) {
      const filtered =
        Object.keys(markerIconMap)
          ?.filter(id => markerIconMap[id].label.toLowerCase()?.includes(query?.toLowerCase()))
          .map(id => ({
            id,
            ...markerIconMap[id]
          })) || [];
      setResults(filtered);
    } else setResults(markers);
  };

  const renderGroupDropdown = () => {
    const linkedGroups = Object.values(builder.byId).filter(({element}) => element === GROUP);
    return (
      linkedGroups.length > 0 && (
        <FormField>
          <InputSelect
            testId="edit.linkedGroup"
            name="parentName"
            options={linkedGroups}
            label="Parent Group"
            defaultValue={targetElement.parentName}
          />
        </FormField>
      )
    );
  };

  const renderUniqueWarning = () => {
    const warnings = [];
    const p = [];

    field.internalIds.map(({value}, index) => {
      if (index > 0 && field.internalIds[index - 1]) p.push(field.internalIds[index - 1].value);

      if (value !== "" && p?.includes(value))
        warnings.push(`${value} exists in current ${targetElement.element.toUpperCase()} help`);

      Object.keys(builder.byId).map(id => {
        const target = builder.byId[id];
        if (
          value !== "" &&
          target.name !== targetElement.name &&
          target?.internalIds?.filter(({value: val}) => val === value).length === 1
        )
          warnings.push(`${value} exists in ${target.label} ${target.element.toUpperCase()} help`);
      });
    });

    let message = "";
    if (warnings.length === 1) message = warnings.toString();
    if (warnings.length === 2) message = warnings.join(" and ");
    if (warnings.length > 2)
      warnings.map((warning, index) => {
        message = +index < warnings.length - 1 ? `${warning}, ` : `and ${warning}`;
      });

    return (
      warnings?.length > 0 && (
        <Duplicates>
          <span>Warning!</span> The following values are not unique. {message}.
        </Duplicates>
      )
    );
  };

  const renderOptions = ({hasHelp, hasGlobalPrompt}) => (
    <FormGroup>
      {targetElement.element === GROUP && (
        <FormField>
          <InputTextGroup
            name="internalIds"
            label={`INTERNAL ID${targetElement.element === COMPONENT ? " / SERIAL #" : ""}`}
            canSetKey
          />
          {renderUniqueWarning()}
        </FormField>
      )}
      {targetElement.element === COMPONENT && (
        <FormField>
          <InputCheck name="grid" testId="edit.grid">
            <Text inline>
              Display {targetElement.label} as grid in form
              <Help>Show fields within this component horizontally.</Help>
            </Text>
          </InputCheck>
        </FormField>
      )}
      {targetElement.element === GROUP && (
        <FormField>
          <InputCheck name="hasGlobalPrompt" testId="edit.hasGlobalPrompt">
            <Text inline>
              Do you want to add a global prompt
              <Help>
                Provide a general description that will be shown at the top of {targetElement.label}
              </Help>
            </Text>
          </InputCheck>
        </FormField>
      )}
      {hasGlobalPrompt && (
        <FormField>
          <InputTextArea
            name="globalPrompt"
            testId="edit.globalPrompt"
            placeholder="Prompt for all components in group."
          />
        </FormField>
      )}
      <FormField>
        <InputCheck name="hasHelp" testId="edit.hasHelp">
          <Text inline>
            Do you want to provide additional help
            <Help>
              These descriptions will be available when filling out {targetElement.label}&apos;s
              fields.
            </Help>
          </Text>
        </InputCheck>
      </FormField>
      {hasHelp && (
        <>
          <InputFileGroup name="files" callback={addFile} />
          <FormField>
            <ElementHelp
              testId="edit.help"
              name="help"
              label={`${targetElement.element.toUpperCase()} HELP`}
              triggerAppend={triggerAppend}
            />
          </FormField>
        </>
      )}
      {targetElement.element === GROUP && (
        <>
          <FormField>
            <InputCheck name="hasAddress" testId="edit.hasAddress">
              Provide location?
            </InputCheck>
          </FormField>
          {hasAddress && (
            <>
              <Location targetElement={targetElement} form={form} />

              {markers?.length > 0 && targetElement.element === GROUP && (
                <FormField>
                  <SearchWrapper>
                    <SearchSelect
                      label="Map Marker"
                      search={search}
                      results={results}
                      setResults={setResults}
                      add={({id}) => setValue("markerId", id)}
                      showAll
                    />
                  </SearchWrapper>
                </FormField>
              )}

              <button type="button" onClick={() => setValue("markerId", null)}>
                <Marker>
                  {markerIconMap[markerId]?.label || DEFAULT_MARKER.label}
                  <Icon
                    icon={markerIconMap[markerId]?.icon || DEFAULT_MARKER.icon}
                    color={`#${markerIconMap[markerId]?.color || DEFAULT_MARKER.color}`}
                  />
                  {markerIconMap[markerId]?.label && <Icon icon={faClose} />}
                </Marker>
              </button>
            </>
          )}
        </>
      )}
    </FormGroup>
  );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Edit {toTitleCase(targetElement.element)}</HeadingCenter>

      <FormProvider {...form}>
        <Form
          onSubmit={handleSubmit(values => persistEdit({...targetElement, ...values}))}
          noValidate>
          <FormGroup>
            {targetElement.element === COMPONENT && renderGroupDropdown(targetElement)}
            <FormField>
              <InputText
                testId="edit.label"
                name="label"
                label="NAME"
                placeholder={targetElement.label}
                required
              />
            </FormField>
            {renderOptions(field || targetElement)}
          </FormGroup>
          <Button data-testid="edit.save" type="submit">
            Save
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalEditElement.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  setTargetElement: PropTypes.func.isRequired,
  persistEdit: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  markerIconMap: PropTypes.objectOf(PropTypes.any)
};

// Style Overrides
const Marker = styled(Pill)`
  background: ${({theme}) => theme.secondary};
`;

const Icon = styled(FontAwesomeIcon)`
  padding-left: ${pad / 2}px;
  fill: ${props => props?.color ?? props.theme.tertiary};
`;

const Duplicates = styled(Text)`
  ${voice.quiet};

  span {
    color: ${({theme}) => theme.warning};
  }
`;

export default ModalEditElement;
