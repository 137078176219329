import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";

// Components
import Modal from "../../components/Modal.js";
import BaseTable from "../../components/BaseTable.js";
import Dropdown from "../../components/Dropdown.js";

// Utils
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  HeadingCenter,
  Inline,
  Search,
  SearchIcon,
  SearchWrapper,
  TableFooter,
  TableMenu,
  TableWrapper
} from "../../style/components/general.js";

const DEFAULT_HEADERS = {
  preview: {
    enabled: true,
    override: false,
    header: "Preview"
  },
  name: {
    enabled: true,
    override: false,
    header: "Name"
  },
  createdBy: {
    enabled: true,
    override: false,
    header: "Created By"
  },
  createdAt: {
    enabled: true,
    override: false,
    header: "Created At"
  }
};

const ModalCustomTableTarget = ({
  visible,
  setVisible,
  target,
  setTarget,
  hasBackButton,
  goBack = () => {}
}) => {
  const {settings} = useContext(SettingsContext);
  const {timezone} = settings;

  const isMounted = useMountedState();

  // Custom Table
  const [tableData, setTableData] = useState();
  const [folderId, setFolderId] = useState(target.id);
  const [breadcrumbs, setBreadcrumbs] = useState([{id: target.id, name: target.name}]);
  const [maxBreadcrumbs, setMaxBreadcrumbs] = useState(3);
  // Base Table
  const [items, setItems] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [query, setQuery] = useState();
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("name");
  const [limit, setLimit] = useState(10);
  const [loadMore, setLoadMore] = useState();

  // Previous Hooks
  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);
  const prevLimit = usePrevious(limit);

  const showDeleted = useRef(false);
  const scrollContainer = useRef();

  const {api: apiMS, loading} = useApi("microsoft", {suppress: {longRequest: false}});

  const formatTableData = useCallback(
    payload =>
      payload.map(item => {
        const newItem = {};
        Object.keys(DEFAULT_HEADERS).forEach(heading => {
          if (heading === "preview")
            newItem[heading] = item?.folder ? (
              <Button
                onClick={() => {
                  setTarget(item);
                  goBack();
                }}>
                Select
              </Button>
            ) : (
              ""
            );
          else if (heading === "name")
            newItem[heading] = item?.folder ? (
              <FolderButton
                folder={item.folder}
                onClick={() => {
                  setFolderId(item.id);
                  setBreadcrumbs(prev => [...prev, {id: item.id, name: item.name.toUpperCase()}]);
                }}>
                <Abbr title={item.name}>{item.name}</Abbr>
              </FolderButton>
            ) : (
              item.name
            );
          else if (heading === "createdAt")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone);
          else if (heading === "createdBy") newItem[heading] = item[heading].user.displayName;
        });

        return newItem;
      }),
    [goBack, setTarget, timezone]
  );

  const getTableData = useCallback(
    id => {
      setItems([]);
      setShowOptions(false);

      const filter = {showDeleted: showDeleted.current};

      if (query) filter.Search = query;

      const payload = {
        resourceType: "sharepoint",
        resourcePath: "drive",
        resourceId: id,
        orderBy: orderBy,
        groupBy,
        limit,
        filter: JSON.stringify(filter)
      };

      apiMS.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data?.drive) {
          const formatted = formatTableData(data.drive);
          setTableData(formatted);
          setLoadMore(data?.next);
        }
      });
    },
    [apiMS, groupBy, limit, orderBy, query, formatTableData]
  );

  // Folder Changes
  useEffect(() => {
    if (isMounted() && folderId) getTableData(folderId);
  }, [isMounted, folderId, getTableData]);

  // Update rows on sort or limit
  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      prevLimit &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy || prevLimit !== limit)
    )
      getTableData();
  }, [getTableData, groupBy, isMounted, limit, orderBy, prevGroupBy, prevLimit, prevOrderBy]);

  const getBreadCrumbMax = useCallback(() => {
    let maxCrumbs = breadcrumbs.length;
    if (window.innerWidth < 475) maxCrumbs = 2;
    else if (window.innerWidth < 600) maxCrumbs = 3;
    else if (window.innerWidth < 768) maxCrumbs = 4;
    else if (window.innerWidth < 850) maxCrumbs = 2;
    else if (window.innerWidth < 1000) maxCrumbs = 3;
    else if (window.innerWidth < 1300) maxCrumbs = 5;
    setMaxBreadcrumbs(maxCrumbs);
  }, [breadcrumbs.length]);

  useEffect(() => {
    if (isMounted()) window.addEventListener("resize", getBreadCrumbMax);

    return () => {
      window.removeEventListener("resize", getBreadCrumbMax);
    };
  }, [isMounted, breadcrumbs, getBreadCrumbMax]);

  // Search
  useEffect(() => {
    if (query || query === "") getTableData();
  }, [query, getTableData]);

  const handleSearch = e => setQuery(e.target.value);

  // Load more on Scroll
  const handleScroll = useCallback(() => {
    const maxScrollTop =
      scrollContainer.current.scrollHeight - scrollContainer.current.clientHeight;
    if (scrollContainer.current.scrollTop + 1 >= maxScrollTop && loadMore && !loading)
      apiMS
        .callGet(null, {
          resourceType: "sharepoint",
          resourcePath: "drive",
          next: loadMore
        })
        .then(({status, data}) => {
          if (status === 200 && data?.drive) {
            setTableData(prev =>
              prev ? [...prev, ...formatTableData(data.drive)] : formatTableData(data.drive)
            );
            setLoadMore(data?.next);
          }
        });
  }, [loadMore, loading, apiMS, formatTableData]);

  useEffect(() => {
    const modal = scrollContainer.current;
    modal.addEventListener("scroll", handleScroll);
    return () => modal.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={hasBackButton}
      goBack={goBack}
      scrollRef={scrollContainer}>
      <HeadingCenter>Target Folder</HeadingCenter>
      <TableWrapper>
        <RowWrapper>
          <HeaderMenu>
            <MenuItems>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search name="search" type="text" placeholder="Rows..." onChange={handleSearch} />
              </SearchWrapper>
              <CustomTableMenu>
                {breadcrumbs?.length > maxBreadcrumbs && "... "}
                {breadcrumbs?.map((crumb, idx) => (
                  <BreadcrumbButton
                    key={crumb.id}
                    title={crumb.name}
                    root={idx === 0 && breadcrumbs.length === 1}
                    active={breadcrumbs.length - 1 === idx}
                    hide={
                      breadcrumbs?.length > maxBreadcrumbs &&
                      idx < breadcrumbs.length - maxBreadcrumbs
                    }
                    onClick={() => {
                      if (idx === 0 && breadcrumbs.length === 1) {
                        setTarget();
                        goBack();
                      } else {
                        setFolderId(crumb.id);
                        const crumbs = [];
                        for (let i = 0; i < breadcrumbs.length; i++) {
                          if (breadcrumbs[i].id !== crumb.id) crumbs.push(breadcrumbs[i]);
                          else {
                            crumbs.push(breadcrumbs[i]);
                            break;
                          }
                        }
                        setBreadcrumbs(crumbs);
                      }
                    }}>
                    {idx !== 0 && <span style={{fontWeight: "normal"}}>/</span>}&nbsp;{crumb.name}
                    &nbsp;
                  </BreadcrumbButton>
                ))}
              </CustomTableMenu>
            </MenuItems>
          </HeaderMenu>
          <BaseTable
            headings={DEFAULT_HEADERS}
            data={tableData}
            loading={loading}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            items={items}
            setItems={setItems}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
          />
        </RowWrapper>
        <TableFooter>
          <PageLimit>
            <Dropdown
              options={[10, 20]}
              selection={limit}
              setSelection={selection => setLimit(selection)}
            />
          </PageLimit>
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalCustomTableTarget.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  target: PropTypes.objectOf(PropTypes.any).isRequired,
  setTarget: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

// Style Overrides
const HeaderMenu = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;
  padding: ${pad}px;

  ${bp(2)} {
    display: flex;
  }
`;

const CustomTableMenu = styled(TableMenu)`
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: ${pad}px;
`;

const MenuItems = styled(Inline)`
  justify-content: space-between;
  width: 100%;
  opacity: ${({active}) => active};
`;

const RowWrapper = styled.div`
  position: relative;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const BreadcrumbButton = styled.button`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: min-content;
  display: ${({hide}) => (hide ? "none" : "block")};
  font-weight: ${({active}) => (active ? "bold" : "normal")};
  cursor: ${({active}) => (active ? "default" : "pointer")};
  pointer-events: ${({root, active}) => (!root && active ? "none" : "inherit")};

  ${({root}) =>
    root &&
    css`
      background: ${({theme}) => theme.secondary};
      color: ${({theme}) => theme.tertiary};
      border-radius: ${radius};
    `}
`;

const FolderButton = styled.button`
  text-align: left;
  text-decoration: ${({folder}) => (folder ? "underline" : "none")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: min-content;
`;

export default ModalCustomTableTarget;
