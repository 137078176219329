import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// Components
import Modal from "../../components/Modal.js";
import Accordion from "../../components/Accordion.js";
import RenderFacilityBuilder from "./RenderFacilityBuilder.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import {prettyDateWithDayInUserTimezone} from "../../utils/helpers.js";

// Style
import {
  HeadingCenter,
  Button,
  NotLoaded,
  Loader,
  Text,
  Inline,
  HeadingSmall
} from "../../style/components/general.js";
import {border, pad, radius} from "../../style/components/variables.js";

const ModalFacilityHistory = ({visible, setVisible, facility}) => {
  const {api} = useApi("facility-versions");

  const {settings} = useContext(SettingsContext);

  const [versions, setVersions] = useState();

  const [target, setTarget] = useState();

  const [viewMode, setViewMode] = useState();

  useEffect(() => {
    api.callGet(null, {facilityId: facility.id}).then(({data, status}) => {
      if (status === 200) setVersions(data);
      else setVersions([]);
    });
  }, [api, facility]);

  const getLabel = version => {
    const {modification, builder, user, createdAt} = version;

    const formattedDate = prettyDateWithDayInUserTimezone(createdAt, settings.timezone);
    const formattedUser = `${user.firstName} ${user.lastName}`;

    const prefix = `${formattedDate} - ${formattedUser}`;
    const fallback = `${formattedDate} - ${formattedUser} edited Checksheet`;

    const [addedGroup] = Object.keys(modification).filter(
      key => modification[key] === "added" && builder.byId[key]?.element === "group"
    );

    const [removedGroup] = Object.keys(modification).filter(
      key => modification[key]?.element === "group" && modification[key].operation === "removed"
    );

    const [editedGroup] = Object.keys(modification).filter(
      key => builder.byId[key]?.element === "group"
    );

    const [addedComponent] = Object.keys(modification).filter(
      key => modification[key] === "added" && builder.byId[key]?.element === "component"
    );

    const [removedComponent] = Object.keys(modification).filter(
      key => modification[key].element === "component" && modification[key].operation === "removed"
    );

    const [editedComponent] = Object.keys(modification).filter(
      key => builder.byId[key]?.element === "component"
    );

    if (addedGroup) {
      const item = builder.byId[addedGroup];

      if (item) return `${prefix} added group ${item.label.toUpperCase()}`;

      return fallback;
    }

    if (removedGroup) {
      const item = modification[addedGroup];

      if (item) return `${prefix} removed group ${item.label.toUpperCase()}`;

      return fallback;
    }

    if (editedGroup) {
      const item = builder.byId[editedGroup];

      if (item) return `${prefix} updated group ${item.label.toUpperCase()}`;

      return fallback;
    }

    if (addedComponent) {
      const item = builder.byId[addedComponent];

      if (item) return `${prefix} added component ${item.label.toUpperCase()}`;

      return fallback;
    }

    if (removedComponent) {
      const item = modification[removedComponent];

      if (item) return `${prefix} removed component ${item.label.toUpperCase()}`;

      return fallback;
    }

    if (editedComponent) {
      const item = builder.byId[editedComponent];

      if (item) return `${prefix} updated component ${item.label.toUpperCase()}`;

      return fallback;
    }

    return fallback;
  };

  if (target)
    return (
      <Modal
        visible={visible && !!target}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setTarget(undefined);
          setViewMode(undefined);
        }}>
        <ModalTitle>
          {prettyDateWithDayInUserTimezone(target.createdAt, settings.timezone)}
        </ModalTitle>
        <HeadingSmall center>
          Edited by {target.user.firstName} {target.user.lastName}
        </HeadingSmall>
        <Explanation center>
          <Text quiet>{target.note}</Text>
        </Explanation>
        <br />
        <Button
          onClick={() =>
            setViewMode(prev => (prev === "modification" ? "builder" : "modification"))
          }>
          {viewMode === "modification" ? "View Builder" : "View Modification"}
        </Button>
        <br />
        <Builder>
          <DragDropContext onDragStart={() => {}} onDragEnd={() => {}}>
            <Droppable droppableId="version.builder" isDropDisabled>
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <RenderFacilityBuilder
                    facility={facility}
                    builder={target.builder}
                    readOnly
                    inFacilityHistory
                    modifications={viewMode === "modification" ? target.modification : undefined}>
                    {provided.placeholder}
                  </RenderFacilityBuilder>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Builder>
      </Modal>
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Builder History</ModalTitle>
      {facility.publishedAt && (
        <ModalSubHeading center>
          Published by {facility.publishedBy?.firstName} {facility.publishedBy?.lastName} on{" "}
          {prettyDateWithDayInUserTimezone(facility.publishedAt, settings?.timezone)}
        </ModalSubHeading>
      )}
      {versions?.map(version => (
        <Accordion key={`version-${version.id}`} label={getLabel(version)}>
          <>
            <Explanation>
              <Text quiet>{version.note}</Text>
            </Explanation>

            <AccordionContent>
              <Button
                type="button"
                onClick={() => {
                  setTarget(version);
                  setViewMode("builder");
                }}>
                View Builder
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setTarget(version);
                  setViewMode("modification");
                }}>
                View Modification
              </Button>
            </AccordionContent>
          </>
        </Accordion>
      ))}
      {versions === undefined && (
        <NotLoaded>
          <Loader />
        </NotLoaded>
      )}
      {versions?.length === 0 && (
        <Centered>
          <Text>No versions found</Text>
        </Centered>
      )}
    </Modal>
  );
};

ModalFacilityHistory.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin-bottom: ${pad}px;
`;

const ModalSubHeading = styled(HeadingSmall)`
  margin-bottom: ${pad * 2}px;
`;

const AccordionContent = styled(Inline)`
  margin: 0 0 ${pad / 2}px ${pad * 2}px;
  border: ${border} solid ${({theme}) => theme.tertiary};
  gap: ${pad}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Explanation = styled.div`
  margin: ${pad / 2}px 0 ${pad / 2}px ${pad * 2}px;

  ${({center}) =>
    center &&
    css`
      margin: ${pad / 2}px 0;
      p {
        text-align: center;
      }
    `}
`;

const Centered = styled(Inline)`
  justify-content: center;
  width: 100%;
`;

const Builder = styled.div`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
`;

export default ModalFacilityHistory;
