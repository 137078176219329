import React, {useContext} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Droppable} from "react-beautiful-dnd";
import styled, {css} from "styled-components";

// Utils
import {AuthContext} from "../contexts/auth.js";
import {hexToRgba} from "../utils/helpers.js";
import {iconLookup} from "./form/InputFieldType.js";
import {COMPONENT, FIELD, GROUP} from "../utils/builder.js";

// Components
import AddElement from "../pages/facility-builder/AddElement.js";

// Style
import {voice} from "../style/components/typography.js";
import {flex, z} from "../style/components/mixins.js";
import {border, navHeight, pad, radius, shadow, status} from "../style/components/variables.js";
import {
  Abbr,
  Button,
  Error,
  Inline,
  Label,
  Pill,
  StickyWrapper
} from "../style/components/general.js";

const icons = iconLookup();

const ElementOptions = ({
  builder,
  element,
  renderOptions,
  children,
  activeDroppableId,
  handleAdd,
  setShowSaveTemplateModal,
  togglePreview,
  modification,
  inFacilityBuilder = false,
  inHistory = false,
  handleAddComponent,
  setTarget,
  error,
  setElementLocation,
  numOptions = 0,
  templateLabel,
  disableDrag = false,
  readOnly = false
}) => {
  const {byId} = builder;

  const {atLeast, atMost, roleCanAccessResource} = useContext(AuthContext);

  const {element: elType, toggle} = element;

  const hasFieldDescendant = ids => {
    if (!ids) return false;
    let result = false;
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const el = byId[id];
      if (el !== undefined) {
        if (el.element === FIELD && el.toggle) return true;
        const {children: ch} = el;
        if (ch && ch.length > 0) result = hasFieldDescendant(ch);
        if (result) return result;
      }
    }
    return result;
  };

  const outermost = target =>
    target.toggle &&
    (!target.parentName || target.parentName === "") &&
    hasFieldDescendant(element.children);

  const getDependsLabel = () => {
    if (element.condition && element.type === "generated") return "Conditional Formula";
    if (element.condition) return "Conditional";
    if (
      element.type === "generated" &&
      element?.depends?.filter(key => byId[key]?.type === "time")?.length > 0
    )
      return "Elapsed";
    return "Formula";
  };

  const getModificationType = () => {
    if (!modification) return undefined;
    if (typeof modification === "string") return modification;
    return "changed";
  };

  return (
    <ElementWrapper
      element={elType}
      toggle={toggle || inFacilityBuilder || readOnly}
      data-testid="element.outerWrapper"
      hideBorder={readOnly && !inHistory ? 1 : 0}
      modification={getModificationType()}>
      {outermost(element) && togglePreview && (
        <StickyButton>
          <Button type="button" onClick={() => togglePreview(element.name)}>
            Preview
          </Button>
        </StickyButton>
      )}
      <Droppable
        droppableId={element.name}
        isDropDisabled={activeDroppableId !== element.name || disableDrag || readOnly}
        type={element.name}>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            data-testid={`${elType}-${element.name}`}>
            <Wrapper
              element={elType}
              data-testid="element.wrapper"
              outermost={() => outermost(element)}
              allowToggle={!!togglePreview}>
              <ElementContainer
                element={elType}
                toggle={toggle || inFacilityBuilder || readOnly}
                data-testid="element.content">
                <Element
                  element={elType}
                  toggle={toggle || inFacilityBuilder || readOnly}
                  numOptions={numOptions}
                  readOnly={readOnly ? 1 : 0}
                  data-testid="element.name">
                  <ElementLabel>
                    {modification && typeof modification !== "string" && modification.label && (
                      <>
                        <ElementLabelInnerWrapper isOld>
                          <Abbr title={modification.label}>{modification.label}</Abbr>
                        </ElementLabelInnerWrapper>
                        &nbsp;&nbsp;
                      </>
                    )}
                    <ElementLabelInnerWrapper
                      isNew={
                        modification && typeof modification !== "string" && modification.label
                      }>
                      <Abbr title={element.label}>{element.label}</Abbr>
                    </ElementLabelInnerWrapper>
                    {element.tag && (
                      <>
                        &nbsp;
                        <Pill>
                          <Abbr title={element.tag}>{element.tag}</Abbr>
                        </Pill>
                      </>
                    )}
                    {element.element === "field" && icons[element.type] && (
                      <>
                        &nbsp;
                        <FontAwesomeIcon icon={icons[element.type]} />
                      </>
                    )}
                    {(element.type === "generated" || element.condition) && (
                      <>
                        &nbsp;
                        <FontAwesomeIcon icon={faLink} />
                        &nbsp;
                        <span>
                          <Abbr title={getDependsLabel()}>{getDependsLabel()}</Abbr>
                        </span>
                      </>
                    )}
                    {templateLabel && (
                      <>
                        &nbsp;
                        <FontAwesomeIcon icon={faLink} />
                        &nbsp;
                        <span>
                          <Abbr title={templateLabel}>{templateLabel}</Abbr>
                        </span>
                      </>
                    )}
                  </ElementLabel>
                </Element>

                {atLeast("creator") && renderOptions && renderOptions(element)}

                {(atMost("admin") || !roleCanAccessResource("facility", "create")) &&
                  setElementLocation &&
                  inFacilityBuilder &&
                  !inHistory &&
                  !element.hasAddress &&
                  element.parentName === "" && (
                    <SetLocation>
                      <Option
                        type="button"
                        onClick={() => setElementLocation(element)}
                        data-testid="element.setLocation">
                        Set Location
                      </Option>
                    </SetLocation>
                  )}
              </ElementContainer>
            </Wrapper>
            {error?.includes(element.name) && element.element !== FIELD && (
              <ErrorText>
                Active {element.element}s must contain at least one element
                {element.element === GROUP ? " descendant and no empty components" : ""}.
              </ErrorText>
            )}
            {children}
            {provided.placeholder}
            {atLeast("creator") &&
              roleCanAccessResource("facility", "create") &&
              elType === GROUP &&
              handleAddComponent &&
              inFacilityBuilder &&
              !inHistory && (
                <BuilderElement data-testid="builder.element">
                  <AddElement
                    builder={builder}
                    addElement={handleAddComponent}
                    linkedGroup={element.name}
                  />
                </BuilderElement>
              )}
          </div>
        )}
      </Droppable>
      <Inline>
        {toggle && elType !== FIELD && !inFacilityBuilder && !inHistory && (
          <AddButton
            type="button"
            onClick={() => handleAdd(element)}
            data-testid="checksheet.addField">
            <FontAwesomeIcon icon={faPlus} /> Field
          </AddButton>
        )}
        {roleCanAccessResource("component", "create") &&
          toggle &&
          elType === COMPONENT &&
          element.children &&
          element.children.length > 0 &&
          !inHistory &&
          setShowSaveTemplateModal && (
            <AddButton
              type="button"
              onClick={() => {
                setTarget(element);
                setShowSaveTemplateModal(true);
              }}>
              Save as Template
            </AddButton>
          )}
      </Inline>
    </ElementWrapper>
  );
};

ElementOptions.propTypes = {
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  renderOptions: PropTypes.func,
  children: PropTypes.node,
  activeDroppableId: PropTypes.string,
  handleAdd: PropTypes.func,
  setShowSaveTemplateModal: PropTypes.func,
  setTarget: PropTypes.func,
  togglePreview: PropTypes.func,
  modification: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
  inFacilityBuilder: PropTypes.bool,
  inHistory: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleAddComponent: PropTypes.func,
  error: PropTypes.arrayOf(PropTypes.string),
  setElementLocation: PropTypes.func,
  numOptions: PropTypes.number,
  templateLabel: PropTypes.string,
  disableDrag: PropTypes.bool,
  readOnly: PropTypes.bool
};

// Style overrides
const ElementWrapper = styled.div`
  height: min-content;
  border-radius: ${radius};
  padding: ${pad}px;
  background: ${({theme}) => theme.tertiary};

  ${({hideBorder, toggle, theme, element}) =>
    !hideBorder &&
    css`
      border: ${border} solid ${toggle ? theme[element] : hexToRgba(theme[element], 0.4)};
    `}

  ${({modification}) => {
    if (modification === "added")
      return css`
        box-shadow: 2px 2px 10px ${status.success};
      `;
    if (modification === "removed")
      return css`
        box-shadow: 2px 2px 10px ${status.error};
      `;
    if (modification === "changed")
      return css`
        box-shadow: 2px 2px 10px ${status.warning};
      `;
    return "";
  }}
`;

const Wrapper = styled.div`
  min-width: 200px;

  ${({outermost, allowToggle}) =>
    outermost() && allowToggle
      ? css`
          width: calc(100% - 90px);
        `
      : css`
          position: relative;
          width: 100%;
        `}

  &:hover {
    .renderOptions {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const Element = styled.div`
  width: 100%;

  ${({readOnly, numOptions}) =>
    !readOnly &&
    css`
      /* Number of options * fixed button width (~40px) + margin (10px)*/
      max-width: calc(100% - ${numOptions * 45}px);
    `}

  ${Label} {
    display: flex;
    font-weight: bold;
    color: ${({toggle, theme, element}) =>
      toggle ? theme[element] : hexToRgba(theme[element], 0.4)};

    ${({element}) =>
      element === GROUP &&
      css`
        ${voice.medium};
      `};

    span {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      fill: ${({toggle, theme, element}) =>
        toggle ? theme[element] : hexToRgba(theme[element], 0.4)};
    }
  }
`;

const ElementContainer = styled.div`
  width: 100%;
  margin-bottom: ${({element}) => (element === FIELD ? 0 : pad / 2)}px;

  ${flex("row", "nowrap", "space-between", "center")};

  &:hover .renderOptions {
    visibility: visible;
    opacity: 1;
  }
`;

const AddButton = styled(Button)`
  margin: 0 ${pad}px 0 0;
`;

const StickyButton = styled(StickyWrapper)`
  top: ${navHeight + pad * 2}px;
  z-index: ${z("above")};
  margin: 2px auto;
  float: right;

  button {
    box-shadow: ${shadow};
  }
`;

const BuilderElement = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
`;

const ErrorText = styled(Error)`
  position: relative;
  display: block;
  margin: 0 0 ${pad}px 0;
  color: ${({theme}) => theme.error};
`;

const SetLocation = styled.div`
  ${flex("row", "nowrap", "center", "center")};

  ${({element}) =>
    element === COMPONENT &&
    css`
      top: ${pad / 2}px;
    `}
`;

const Option = styled(Button)`
  margin: 0 0 0 ${pad}px;
  height: 100%;
`;

const ElementLabel = styled(Label)`
  align-items: center;
`;

const ElementLabelInnerWrapper = styled.span`
  ${({isOld, isNew}) => {
    if (isOld)
      return css`
        color: ${status.error};
        text-decoration: line-through;
      `;
    if (isNew)
      return css`
        color: ${status.success};
      `;
    return "";
  }}
`;

export default ElementOptions;
