import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

// Style
import {flex} from "../style/components/mixins";
import {voice} from "../style/components/typography";
import {bp} from "../style/components/breakpoints";
import {border} from "../style/components/variables";

const ToggleSwitch = ({
  iconLeft,
  iconRight,
  textLeft,
  textRight,
  onChange,
  options,
  defaultSelected,
  inset = false
}) => {
  const [position, setPosition] = useState(
    defaultSelected && options.includes(defaultSelected) && options.indexOf(defaultSelected) > 0
      ? "right"
      : "left"
  );

  return (
    <SwitchWrapper data-testid="switch" inset={inset ? 1 : 0}>
      <SwitchOutline inset={inset ? 1 : 0}>
        <SwitchIcon position={position} inset={inset ? 1 : 0}>
          {position === "left" && <FontAwesomeIcon icon={iconLeft} alt={textLeft} />}
          {position === "right" && <FontAwesomeIcon icon={iconRight} alt={textRight} />}
          <CheckInput
            data-testid="input-switch"
            type="checkbox"
            defaultChecked={
              defaultSelected &&
              options.includes(defaultSelected) &&
              options.indexOf(defaultSelected) > 0
            }
            onChange={() => {
              setPosition(prev => (prev === "left" ? "right" : "left"));
              onChange();
            }}
          />
        </SwitchIcon>
      </SwitchOutline>
    </SwitchWrapper>
  );
};

ToggleSwitch.propTypes = {
  iconLeft: PropTypes.objectOf(PropTypes.any).isRequired,
  iconRight: PropTypes.objectOf(PropTypes.any).isRequired,
  textLeft: PropTypes.string.isRequired,
  textRight: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultSelected: PropTypes.string,
  inset: PropTypes.bool
};

// Style Overrides
const SwitchWrapper = styled.label`
  display: flex;
  position: relative;
  border-radius: 15px;
  background-color: ${({inset, theme}) => (inset ? theme.secondary : theme.tertiary)};
`;

const SwitchOutline = styled.div`
  ${flex("row", "wrap", "center", "center")};
  position: relative;
  border-radius: 15px;
  border: ${border} solid ${({theme}) => theme.secondary};
  width: 40px;
  height: 28px;

  ${bp(2)} {
    border-radius: 15px;
    width: 55px;
    height: 35px;
  }

  &:hover {
    cursor: pointer;
  }

  ${({inset, theme}) =>
    inset &&
    css`
      box-shadow: inset 0 0 10px rgba(0, 0, 0, ${theme.name === "dark" ? 0.3 : 0.8});
    `}

  ${({disabled}) =>
    disabled &&
    css`
      &:hover {
        cursor: auto;
      }
    `}
`;

const SwitchIcon = styled.span`
  cursor: inherit;
  ${flex("row", "wrap", "center", "center")};
  position: absolute;
  background: transparent;
  background: ${({inset, theme}) => (inset ? theme.primary : theme.secondary)};
  border-radius: 50%;
  transition: all ease 0.1s;
  width: 20px;
  height: 20px;
  ${voice.quiet};

  ${bp(2)} {
    width: 25px;
    height: 25px;
    ${voice.normal};
  }

  ${({position}) =>
    position === "left"
      ? css`
          left: 3px;
        `
      : css`
          right: 3px;
        `}

  svg {
    ${({inset, theme}) =>
      !inset &&
      css`
        fill: ${theme.tertiary};
      `}
  }
`;

const CheckInput = styled.input`
  display: none;

  &:checked + span:after {
    left: unset;
    right: 0;
  }
`;

export default ToggleSwitch;
