import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {pad, radius} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {Arrow} from "../style/components/general.js";

const Pagination = ({current, setCurrent, pageTotal, updateData, loading = false}) => {
  const prevPage = useRef(1);
  const changePage = offset => {
    if (offset + current !== 0 && offset + current !== pageTotal + 1) {
      setCurrent(Number.parseInt(current, 10) + offset);
    }
  };

  const setPage = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      let page = 1;
      const target = parseInt(e.target.value, 10);
      page = target < 1 ? 1 : target;
      page = target > pageTotal ? pageTotal : page;
      e.target.value = page;
      setCurrent(Number.parseInt(page, 10));
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (current !== prevPage.current) updateData();
    prevPage.current = current;
  }, [current, updateData]);

  return (
    pageTotal > 1 && (
      <PaginationWrapper data-testid="pagination">
        <PaginationButton
          id="backPage"
          disabled={current === 1 || loading}
          onClick={() => changePage(-1)}>
          <Arrow />
        </PaginationButton>
        <Selector>
          Page&nbsp;
          <SelectorInput
            type="number"
            placeholder={current}
            min={1}
            max={pageTotal}
            onKeyUp={setPage}
          />
          &nbsp;of {pageTotal}
        </Selector>
        <PaginationButton
          id="forwardPage"
          disabled={current === pageTotal || loading}
          onClick={() => changePage(1)}>
          <Arrow rotate="-180deg" />
        </PaginationButton>
      </PaginationWrapper>
    )
  );
};

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
  pageTotal: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

// Style Overrides
const PaginationWrapper = styled.div`
  width: fit-content;
  align-self: flex-end;
  ${flex("row", "nowrap", "center", "center")}
`;

const PaginationButton = styled.button`
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 2}px 0;
  border: 0;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const Selector = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  margin: 0 ${pad}px;
  min-width: 125px;
  color: ${({theme}) => theme.secondary};
`;

const SelectorInput = styled.input`
  width: 30px;
  min-width: 30px;
  background: transparent;
  border-radius: ${radius};
  color: ${({theme}) => theme.secondary};
`;

export default Pagination;
