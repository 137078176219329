import React, {useCallback} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import {generateUniqueKey} from "../../utils/helpers.js";
import {EMPTY_TABLE_HEIGHT} from "../../utils/builder.js";

// Components
import Region from "./Region.js";

// Style
import {border, pad, radius} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {Section} from "../../style/components/general.js";

const LEFT_COLUMN = 0;
const RIGHT_COLUMN = 1;

const RenderReportBuilder = ({
  report,
  handleShowOptions,
  showPlaceholders = true,
  fontSize = 14,
  alignment = "left"
}) => {
  const pushColumns = useCallback((allTables, leftColumn, rightColumn, groupKey) => {
    allTables.push(
      <GroupWrapper key={groupKey}>
        <Column>{leftColumn}</Column>
        <Column>{rightColumn}</Column>
      </GroupWrapper>
    );
  }, []);

  const arrangeTables = () => {
    const allTables = [];
    let pushedEmpty = false;
    report.groups.map(group => {
      if (group?.columns?.length === 1) {
        pushedEmpty = false;

        // full width columns only have one row
        const row = group.columns[0].rows[0];
        const {region, id} = row;

        if (!row) return;

        allTables.push(
          <GroupWrapper key={id} full>
            <Row full>
              <Region
                {...region}
                handleShowOptions={handleShowOptions}
                id={id}
                fontSize={fontSize}
                alignment={alignment}
              />
            </Row>
          </GroupWrapper>
        );
      } else if (group?.columns?.length === 2) {
        let leftColumnHeight = 0;
        let rightColumnHeight = 0;
        pushedEmpty = false;

        const leftColumn = group.columns[LEFT_COLUMN].rows
          .filter(row => !!row)
          .map(row => {
            const {height, id, region} = row;
            leftColumnHeight += height;
            return (
              <Row key={id}>
                <Region
                  {...region}
                  handleShowOptions={handleShowOptions}
                  id={id}
                  fontSize={fontSize}
                  alignment={alignment}
                />
              </Row>
            );
          });

        const rightColumn = group.columns[RIGHT_COLUMN].rows
          .filter(row => !!row)
          .map(row => {
            const {region, height, id} = row;
            rightColumnHeight += height;
            return (
              <Row key={id}>
                <Region
                  {...region}
                  handleShowOptions={handleShowOptions}
                  id={id}
                  fontSize={fontSize}
                  alignment={alignment}
                />
              </Row>
            );
          });

        // When either column is empty, fill space with empty section
        if (showPlaceholders && (!leftColumnHeight || !rightColumnHeight)) {
          pushedEmpty = true;
          if (!leftColumnHeight) {
            leftColumn.push(
              <Row key={generateUniqueKey("row")} empty>
                Section
              </Row>
            );
          }
          if (!rightColumnHeight) {
            rightColumn.push(
              <Row key={generateUniqueKey("row")} empty>
                Section
              </Row>
            );
          }
        }

        // When the difference in column height is greater than empty region, fill space with empty section
        if (
          !pushedEmpty &&
          showPlaceholders &&
          Math.abs(leftColumnHeight - rightColumnHeight) >= EMPTY_TABLE_HEIGHT
        ) {
          pushedEmpty = true;

          if (leftColumnHeight > rightColumnHeight) {
            rightColumn.push(
              <Row key={generateUniqueKey("row")} empty>
                Section
              </Row>
            );
          } else {
            leftColumn.push(
              <Row key={generateUniqueKey("row")} empty>
                Section
              </Row>
            );
          }
        }

        if (leftColumn.length || rightColumn.length)
          pushColumns(allTables, leftColumn, rightColumn, group.id);
      }
    });

    // if we haven't pushed any empty rows, add one at the bottom of the report
    if (showPlaceholders && !pushedEmpty)
      allTables.push(
        <GroupWrapper key={generateUniqueKey("group")}>
          <Row empty>Section</Row>
        </GroupWrapper>
      );
    return allTables;
  };

  return <ReportBuilderSection>{report && arrangeTables()}</ReportBuilderSection>;
};

RenderReportBuilder.propTypes = {
  report: PropTypes.objectOf(PropTypes.any),
  handleShowOptions: PropTypes.func,
  showPlaceholders: PropTypes.bool,
  fontSize: PropTypes.number,
  alignment: PropTypes.string
};

// Style Overrides
const ReportBuilderSection = styled(Section)`
  ${flex("column", "nowrap", "flex-start", "center")};
  width: 100%;
  padding: 0;
  gap: ${pad / 2}px;
`;

const Row = styled.div`
  width: 100%;
  height: 100%;
  flex: 0 0 ${props => (props.full ? "100%" : "min-content")};

  ${props =>
    props.empty &&
    css`
      background: ${props.theme.tertiary};
      width: 100%;
      min-height: ${props.minHeight || EMPTY_TABLE_HEIGHT}px;
      color: ${props.theme.primary};
      border-radius: ${radius};
      border: ${border} dashed ${props.theme.primary};
      ${flex("row", "wrap", "center", "center")};
      flex: 0 1 100%;
    `}
`;

const Column = styled.div`
  width: 50%;
  align-self: stretch;
  ${props =>
    props.row
      ? flex("row", "nowrap", "flex-start", "start")
      : flex("column", "nowrap", "flex-start", "start")}
  gap: ${pad / 2}px;
`;

const GroupWrapper = styled.div`
  width: 100%;
  height: auto;
  align-self: stretch;
  width: 100%;
  gap: ${pad / 2}px;
  ${flex("row", "nowrap", "flex-start", "start")}
`;

export default RenderReportBuilder;
