import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import FilePreview from "../../components/FilePreview.js";

// Style
import {Heading, Loader, Text} from "../../style/components/general.js";

const ModalFileView = ({visible, setVisible, file, hasBackButton = false, goBack = () => {}}) => {
  const isMounted = useMountedState();

  const {api, loading} = useApi("files");
  const {settings} = useContext(SettingsContext);

  const [selected, setSelected] = useState(file);

  useEffect(() => {
    if (isMounted() && !file.link)
      api.callGet(file.id, {download: true}).then(({status, data}) => {
        if (status === 200) setSelected(data);
      });
  }, [isMounted, file, api]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton={hasBackButton}
      goBack={goBack}
      width={file.extension.name.includes("image") ? "min-content" : null}
      maxWidth={file.extension.name === "application/pdf" ? "90%" : null}
      minWidth="50%">
      {!loading && selected ? (
        <>
          <Heading smaller>{selected.label}</Heading>

          <Text>
            Uploaded by {selected.createdBy} on{" "}
            {prettyDateInUserTimezone(selected.createdAt, settings.timezone)}
          </Text>

          {selected.link && <FilePreview selected={selected} />}
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

ModalFileView.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  file: PropTypes.objectOf(PropTypes.any).isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

export default ModalFileView;
