import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {get, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Utils
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";

// Components
import InputError from "./InputError.js";
import InputTextArea from "./InputTextArea.js";
import Help from "../Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {Abbr, Error, FormFieldWrapper, Label, Pill} from "../../style/components/general.js";

const InputCheck = ({children, ...props}) => {
  const {
    testId = "input-checkbox",
    name,
    label,
    required = false,
    hidden = false,
    disabled = false,
    showError = true,
    minWidth,
    globalUnacceptable,
    globalUnacceptableTitles,
    hasAlert = false,
    alertMessage,
    alertCondition,
    condition,
    invertColors = false,
    tag
  } = props;

  const {
    register,
    watch,
    formState: {errors}
  } = useFormContext();

  const value = watch(name);

  const hasYupErrors = useMemo(() => {
    const {message} = get(errors, name) || {};
    return !!message;
  }, [errors, name]);

  return (
    <FormFieldWrapper data-testid={testId} hidden={hidden} standard>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {globalUnacceptable}
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}
      <CheckLabel>
        <CheckWrapper disabled={disabled}>
          <CheckInput
            type="checkbox"
            id={name}
            disabled={disabled}
            data-testid={`${testId}-input`}
            {...props}
            {...register(name, {required})}
          />
          <CheckMark />
        </CheckWrapper>
        {children && (
          <Description minWidth={minWidth} inverted={invertColors}>
            {children}
          </Description>
        )}
      </CheckLabel>

      {showError && errors && (
        <>
          <InputError errors={errors} name={name} />
          {!hasYupErrors && globalUnacceptableTitles?.length && (
            <Error>
              Parameter violation{globalUnacceptableTitles.length > 1 ? "s" : ""}:{" "}
              {globalUnacceptableTitles.join(", ")}
            </Error>
          )}
        </>
      )}

      {hasAlert && value === alertCondition && (
        <InputTextArea
          name={`${name}_comment`}
          label={alertMessage || defaultUnacceptableParameterPrompt}
          required
          maxLength={1000}
        />
      )}
    </FormFieldWrapper>
  );
};

InputCheck.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  hidden: PropTypes.bool,
  testId: PropTypes.string,
  minWidth: PropTypes.string,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitles: PropTypes.arrayOf(PropTypes.string),
  hasAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertCondition: PropTypes.any,
  condition: PropTypes.string,
  invertColors: PropTypes.bool,
  tag: PropTypes.string
};

// Style Overrides
const CheckLabel = styled(Label)`
  ${flex("row", "nowrap", "start", "start")};
  gap: ${pad / 2}px;
`;

const Description = styled.span`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  white-space: break-spaces;

  ${voice.normal};

  ${({inverted, theme}) =>
    inverted &&
    css`
      color: ${theme.tertiary};
    `};

  ${({minWidth}) =>
    minWidth
      ? css`
          min-width: ${minWidth};
        `
      : css`
          max-width: 85%;
        `}
`;

const CheckWrapper = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  position: relative;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  background: ${({theme}) => theme.tertiary};

  &:hover {
    cursor: pointer;
  }

  ${({disabled}) =>
    disabled &&
    css`
      background-color: ${colors.grayLight};
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

const CheckMark = styled.span`
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: inherit;
  transition: all ease 0.1s;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
    width: 37%;
    height: 85%;
    border: ${border} solid ${({theme}) => theme.secondary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: all ease 0.1s;
    opacity: 0;
  }
`;

const CheckInput = styled.input`
  display: none;

  &:checked + span:after {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const LabelWrapper = styled(Label)`
  gap: 6px;
  margin-bottom: 2px;
`;
export default InputCheck;
