import React, {useContext, useState, useEffect, useRef, useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {NavContext} from "../../contexts/nav.js";
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";
import {useBanner} from "../../contexts/banner.js";
import {useToast} from "../../contexts/toast.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Utils
import {
  convertToUserTimezone,
  exists,
  getCompletionRate,
  getWithExpiry,
  prettyDateInUserTimezone,
  setWithExpiry
} from "../../utils/helpers.js";
import {
  COMPONENT,
  FIELD,
  filterNonexistentField,
  getAncestors,
  GROUP
} from "../../utils/builder.js";
import {evaluateConditional, evaluateRange, getIndexedBuilderFields, scrollTo} from "./helpers.js";

// Components
import {InputDate} from "../../components/form/FormInputs.js";
import Element from "../../components/Element.js";
import ModalClearLocalStorage from "./ModalClearLocalStorage.js";
import ModalHelp from "./ModalHelp.js";
import ModalRangeAlert from "./ModalRangeAlert.js";

// Style
import {pulseDelay} from "../../style/components/animations.js";
import {pad, status} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {
  Text,
  Button,
  Form,
  Loader,
  ButtonLoader,
  Error,
  NotLoaded,
  Inline,
  Small,
  FormGroup,
  FormField,
  Label,
  Abbr
} from "../../style/components/general.js";

// Socket Constants
const PERCENT_COMPLETE = "percent_complete";
const GET_FROM_REDIS = "get_from_redis";
const SET_IN_REDIS = "set_in_redis";
const LISTEN_GET_FROM_REDIS = "redis:get";

const getCurrentDate = setTimezone => dayjs.utc().tz(setTimezone || "US/Pacific");

const getConditionRequired = (
  condition,
  values,
  required,
  byId = null,
  dateForConditional = null,
  weather = null
) => {
  let result = false;
  const results = [];
  const operator = condition.operator || "or";
  for (let i = 0; i < condition.list.length; i++) {
    if (!["weekday", "date", "rainfall", "cumulative"].includes(condition.list[i].depends))
      result = evaluateConditional(
        values[i],
        condition.list[i].check,
        condition.list[i].compare,
        byId[condition.list[i].depends],
        condition.list[i].operator
      );
    else if (["rainfall", "cumulative"].includes(condition.list[i].depends)) {
      result = evaluateConditional(
        weather ? weather[condition.list[i].depends] : null,
        condition.list[i].check,
        condition.list[i].compare,
        byId[condition.list[i].depends],
        condition.list[i].operator
      );
    } else {
      result = evaluateConditional(
        dateForConditional,
        condition.list[i].check,
        condition.list[i].compare,
        condition.list[i].depends,
        condition.list[i].operator
      );
    }

    if (result && operator === "or") {
      if (condition.action.includes("REQUIRED")) return true;
      if (condition.action.includes("OPTIONAL") || condition.action.includes("Hide")) return false;
    }

    if (!result && operator === "and") {
      if (condition.action.includes("REQUIRED") || condition.action.includes("Show")) return false;
      if (condition.action.includes("OPTIONAL")) return true;
      if (condition.action.includes("Hide")) return required;
    }
    results.push(result);
  }

  if (results.every(r => !!r) && operator === "and") {
    if (condition.action.includes("REQUIRED")) return true;
    if (condition.action.includes("OPTIONAL") || condition.action.includes("Hide")) return false;
  }

  if (condition.action.includes("REQUIRED") || condition.action.includes("Show")) return false;
  if (condition.action.includes("OPTIONAL")) return true;
  return required;
};

const getFormulaRequired = (values, depends, offset) => {
  let req = false;
  for (let i = 0; i < depends.length; i++) {
    // values is list of values, beginning with conditional field dependencies
    // and ending with the formula dependencies

    // offset is index of the first formula dependency
    if (!exists(values[offset + i])) {
      req = true;
      break;
    }
  }
  return req;
};

const transformNumber = (v, o) => {
  if (Number.isNaN(o) || !exists(o)) return null;
  if (o === "") return null;
  if (o === "∞") return 0;
  return v;
};

export const buildValidationObject = (field, byId, dateForConditional = null, weather = null) => {
  const {
    name,
    type,
    label,
    required,
    hasRange,
    min,
    max,
    degree,
    condition,
    formula,
    trueFormula,
    hidden,
    rangeMin,
    rangeMax,
    restrictedRangeException
  } = field;

  const form = trueFormula ?? formula;

  const depends = [];
  if (form)
    form.map(part => {
      if (part && !part.previous && part.value in byId) depends.push(part.value);
    });

  let validation;

  if (hidden) return yup.mixed().nullable();
  if (type === "number" || type === "range") {
    const minToUse = type === "range" ? rangeMin : min;
    const maxToUse = type === "range" ? rangeMax : max;
    validation = yup
      .number()
      .nullable(true)
      .transform(transformNumber)
      .typeError("Value must be a number.");
    if (hasRange) {
      if (exists(minToUse))
        validation = validation.min(
          minToUse,
          restrictedRangeException || `Value must be > ${minToUse}.`
        );
      if (exists(maxToUse))
        validation = validation.max(
          maxToUse,
          restrictedRangeException || `Value must be < ${maxToUse}.`
        );
    }
    if (degree === "Number")
      validation = validation.test({
        message: "Must be a whole number.",
        test: value => degree === "Number" && value % 1 === 0
      });
  } else if (type === "generated") {
    validation = yup
      .mixed()
      .nullable()
      .test({
        test: val => {
          if (val === "_NO_PREVIOUS" || val === "_DEP_IS_INF") return true;
          if (!exists(val) || Number.isNaN(val)) return false;
          return true;
        },
        message: `${label} is a required field.`
      })
      .test({
        test: val => {
          if (val === "_NO_PREVIOUS" || val === "_DEP_IS_INF") return true;

          if (exists(val) && exists(min)) {
            const parsed = Number.parseFloat(val);
            if (!Number.isNaN(parsed) && parsed < min) return false;
          }
          return true;
        },
        message: restrictedRangeException || `Value must be > ${min}.`
      })
      .test({
        test: val => {
          if (val === "_NO_PREVIOUS" || val === "_DEP_IS_INF") return true;

          if (exists(val) && exists(max)) {
            const parsed = Number.parseFloat(val);
            if (!Number.isNaN(parsed) && parsed > max) return false;
          }
          return true;
        },
        message: restrictedRangeException || `Value must be < ${max}.`
      });
  } else if (type === "upload") {
    validation = yup.string().nullable();
  } else if (type === "checkbox" || type === "switch") {
    validation = yup.boolean().nullable();
  } else if (type === "multiselect") {
    validation = yup.lazy(val => {
      if (val === "") return yup.string().nullable();
      return yup.array().of(yup.string()).nullable();
    });
    if (required)
      validation = yup
        .mixed()
        .nullable()
        .test({
          test: val => Array.isArray(val) && val.length > 0,
          message: `${label} is a required field, please select at least one option.`
        });
  } else if (type === "radio" || type === "dropdown") {
    validation = yup.string().nullable();
  } else if (type === "confirm") {
    validation = yup
      .mixed()
      .nullable()
      .test({
        message: `${label} is a required field, please confirm to continue.`,
        test: value => value === "confirmed"
      });
  } else validation = yup.string().nullable();

  if (condition && condition.list && condition.list.length > 0) {
    return validation.when(
      [
        ...condition.list.map(cond => cond.depends),
        ...(depends ? depends.filter(d => d !== name) : [])
      ],
      {
        is: (...values) =>
          getConditionRequired(condition, values, required, byId, dateForConditional, weather),
        then: () => validation.nullable().required(`${label} is a required field.`),
        otherwise: () => yup.mixed().nullable()
      }
    );
  }

  if (required && !["confirm", "multiselect", "switch", "checkbox"].includes(type))
    validation = validation.required(`${label} is a required field.`);

  return validation;
};

const RenderChecksheet = ({
  room,
  task,
  taskRecord,
  stage,
  readOnly = false,
  responses,
  setSubmission,
  setDraft,
  draftLoading,
  persistToLocalStorage,
  completeLoading,
  dateDue,
  startDate,
  overdue,
  showDates,
  simulatedDate,
  cancelFunction,
  previousSubmission,
  user,
  hideMeta,
  draftDate,
  completedAt,
  hideCompleted,
  allowDeleteFromLocalStorage,
  visible,
  filterResults
}) => {
  const {allIds, byId} = task.builder;

  const isMounted = useMountedState();

  const {addToast} = useToast();
  const {addBanner, removeBanner} = useBanner();

  const socket = useSocket();

  const {settings} = useContext(SettingsContext);
  const {currentUser} = useContext(AuthContext);

  const {setOnline, setIdleVisible} = useContext(NavContext);

  const conditionsSatisfied = useRef({});
  const globalUnacceptable = useRef({});
  const typing = useRef(null);
  const initialOpenGroups = useRef();
  const redisTimeout = useRef(null);

  const taskType = useMemo(() => (!task?.stages ? "checksheet" : "event"), [task]);

  const hasGroups = useMemo(() => {
    for (let i = 0; i < allIds.length; i++) {
      const id = allIds[i];
      if (byId[id].element === GROUP) return true;
    }
    return false;
  }, [allIds, byId]);

  const [showHelp, setShowHelp] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);
  const [savedRecords, setSavedRecords] = useState({});
  const [initialValues, setInitialValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [submitTime, setSubmitTime] = useState(null);
  const [indexedFields, setIndexedFields] = useState({});
  const [unHandled, setUnhandled] = useState(false);
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [multiUnacceptableModalTarget, setMultiUnacceptableModalTarget] = useState(false);

  const [outsideRange, setOutsideRange] = useState(null);
  const [outsideRangeMultiple, setOutsideRangeMultiple] = useState(null);
  const [notifyFieldCondition, setNotifyFieldCondition] = useState(null);
  const [tempSubmission, setTempSubmission] = useState(null);
  const [showClearLocalStorage, setShowClearLocalStorage] = useState(null);
  const [isOpen, setIsOpen] = useState(null);
  const [hiddenGroups, setHiddenGroups] = useState({});
  const [formulasEvaluating, setFormulasEvaluating] = useState({});

  const notificationCommentKeys = useMemo(
    () =>
      task?.notifications?.byId
        ? Object.keys(task.notifications.byId)
            .filter(key => task.notifications.byId[key]?.trigger === "Unacceptable Parameter")
            .map(key => `${key}_comment`)
        : null,
    [task]
  );

  const dateForConditional = useMemo(() => {
    if (simulatedDate) return dayjs(simulatedDate);
    if (overdue) return convertToUserTimezone(dateDue, settings.timezone);
    if (completedAt) return convertToUserTimezone(completedAt, settings.timezone);
    return getCurrentDate(settings.timezone);
  }, [simulatedDate, overdue, dateDue, settings, completedAt]);

  const allOpen = useMemo(() => {
    if (!isOpen) return null;
    const vals = Object.entries(isOpen)
      .filter(([key]) => !hiddenGroups[key])
      .map(([, val]) => val);
    return vals.every(val => !!val);
  }, [hiddenGroups, isOpen]);

  const schema = yup.object().shape({...validationSchema});

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
    shouldFocusError: false
  });

  const {
    handleSubmit,
    setFocus,
    formState: {errors, isDirty, isSubmitting},
    reset,
    watch,
    clearErrors,
    setValue
  } = form;
  const watchInputs = watch();

  const prevInputs = usePrevious(watchInputs || {});
  const prevReadOnly = usePrevious(readOnly);

  const getGroupAncestor = useCallback(
    key => {
      if (!key || !byId || !byId[key]) return null;
      const {element: elementType, parentName, name} = byId[key];
      if (elementType === GROUP) return name;
      if (parentName) return getGroupAncestor(parentName);
      return null;
    },
    [byId]
  );

  // passing falsy value for groupNames sets all groups to open
  const setToOpen = useCallback(
    groupNames => {
      setIsOpen(
        Object.fromEntries(
          Object.keys(byId)
            .filter(key => byId[key].element === GROUP)
            .map(key => [key, !groupNames || groupNames.includes(key)])
        )
      );
    },
    [byId]
  );

  const setInRedis = useCallback(
    (key, values, expiry = -1) => {
      // -1 is a special value that indicates expiry of end of day (midnight)
      socket.emit(SET_IN_REDIS, key, values, expiry);
    },
    [socket]
  );

  // contains timeout of 2 seconds, after which we check if inital values have been populated
  // by redis retrieval. Otherwise, set to saved values (which stores the draft submission)
  const getFromRedis = useCallback(
    key => {
      socket.emit(GET_FROM_REDIS, room, key, currentUser.publicId);
      clearTimeout(redisTimeout.current);
      const timer = setTimeout(() => {
        if (cancelFunction) cancelFunction(false);
        setOnline(false);
        setIdleVisible(true);
      }, 8000);
      redisTimeout.current = timer;
    },
    [socket, room, currentUser, setOnline, setIdleVisible, cancelFunction]
  );

  // Manage formula banner
  useEffect(() => {
    if (
      isSubmitting &&
      Object.values(formulasEvaluating)?.length > 0 &&
      !Object.values(formulasEvaluating).some(val => !!val)
    )
      removeBanner();
  }, [isSubmitting, formulasEvaluating, removeBanner]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && initialValues && isOpen && !loaded) {
      reset({
        ...initialValues,
        completedAt: completedAt
          ? convertToUserTimezone(completedAt, settings.timezone).format("YYYY-MM-DD")
          : undefined
      });
      setLoaded(true);
    }
  }, [isMounted, initialValues, reset, isOpen, loaded, completedAt, settings]);

  // Get sorted fields based on location in checksheet for purposes of finding first error
  useEffect(() => {
    if (allIds.length > 0) {
      const result = {};
      getIndexedBuilderFields(allIds, task.builder, result);
      const sortedResult = Object.fromEntries(Object.entries(result).sort());
      setIndexedFields(sortedResult);
    }
  }, [allIds, task]);

  const formatDisplayValue = (field, response) => {
    const {type, on} = field;

    if (type === "checkbox")
      response =
        typeof response === "string" ? response.toLowerCase() === "checked" : response === true;

    if (type === "switch") response = response === on || response === true;

    return response;
  };

  const formatDisplayValues = useCallback(
    (current, builder) => {
      const result = {};
      const groupsWithEmptyResponses = {};

      Object.keys(current).map(fieldName => {
        const field = builder.byId[fieldName];
        const value =
          current[fieldName] === "_CONDITION_UNSATISFIED" || current[fieldName] === "_NEEDS_COMMENT"
            ? ""
            : current[fieldName];
        let parentField = field;
        let group = null;

        if (notificationCommentKeys?.includes(fieldName)) result[fieldName] = value;
        else if (
          fieldName.match(/.*_comment$/) &&
          builder.byId[fieldName.replace(/_comment$/, "")] &&
          (builder.byId[fieldName.replace(/_comment$/, "")].hasARange ||
            builder.byId[fieldName.replace(/_comment$/, "")].hasAlert)
        ) {
          const parentFieldName = fieldName.replace(/_comment$/, "");
          parentField = byId[parentFieldName];
          group = getGroupAncestor(parentFieldName);
          result[fieldName] = value;
        } else if (
          fieldName.match(/.*_qualifier$/) &&
          builder.byId[fieldName.replace(/_qualifier$/, "")] &&
          builder.byId[fieldName.replace(/_qualifier$/, "")].hasQualifier
        ) {
          const parentFieldName = fieldName.replace(/_qualifier$/, "");
          parentField = byId[parentFieldName];
          group = getGroupAncestor(parentFieldName);
          result[fieldName] = current[fieldName];
        } else if (
          fieldName.match(/.*_qualifierEnabled$/) &&
          builder.byId[fieldName.replace(/_qualifierEnabled$/, "")] &&
          builder.byId[fieldName.replace(/_qualifierEnabled$/, "")].hasQualifier
        ) {
          const parentFieldName = fieldName.replace(/_qualifierEnabled$/, "");
          parentField = byId[parentFieldName];
          group = getGroupAncestor(parentFieldName);
          result[fieldName] = current[fieldName];
        } else if (fieldName.match(/.*_infinite$/)) {
          const parentFieldName = fieldName.replace(/_infinite$/, "");
          parentField = byId[parentFieldName];
          group = getGroupAncestor(parentFieldName);
          result[fieldName] = current[fieldName];
        } else if (fieldName.match(/.*_rainfall$/)) {
          const parentFieldName = fieldName.replace(/_rainfall$/, "");
          parentField = byId[parentFieldName];
          group = getGroupAncestor(parentFieldName);
          result[fieldName] = current[fieldName];
        } else if (field) {
          group = getGroupAncestor(fieldName);
          let trueValue = value;
          if (
            ["number", "generated"].includes(field.type) &&
            trueValue?.includes &&
            trueValue.includes(" ")
          ) {
            const temp = value?.split(" ");
            if (temp) [trueValue] = temp;
          }
          result[fieldName] = formatDisplayValue(field, trueValue);
        }

        // must derive everything from parent field if field is a pseudofield, parentField will be
        // the field otherwise
        let isRequired = !parentField || (parentField.required && !parentField.hidden);

        if (parentField && parentField.condition) {
          const depends = parentField.condition.list.map(cond => cond.depends);
          const values = depends.map(depend => current[depend]);
          isRequired = getConditionRequired(
            parentField.condition,
            values,
            parentField.required,
            byId,
            dateForConditional,
            task.weather
          );
        }

        if (isRequired && parentField && parentField.formula && parentField.depends) {
          const depends = parentField.depends.filter(d => d !== parentField.name);
          const values = depends.map(depend => current[depend]);
          isRequired = getFormulaRequired(values, depends, 0);
        }

        if (!exists(value) && group && isRequired) groupsWithEmptyResponses[group] = true;
      });

      // any group with empty responses will be opened by default in subsequent call to setToOpen
      initialOpenGroups.current = Object.keys(groupsWithEmptyResponses);

      return result;
    },
    [getGroupAncestor, byId, dateForConditional, task, notificationCommentKeys]
  );

  useEffect(() => {
    if (
      savedRecords.localStorage !== undefined &&
      savedRecords.incomplete !== undefined &&
      savedRecords.redis !== undefined &&
      !initialValues
    ) {
      let maxPercent;
      let maxResponses;

      Object.keys(savedRecords).map(key => {
        const vals = savedRecords[key];
        const percentage = getCompletionRate(vals);
        if (!exists(maxPercent) || percentage > maxPercent) {
          maxPercent = percentage;
          maxResponses = vals;
        }
      });

      const formatted = formatDisplayValues(maxResponses, task.builder);

      if (initialOpenGroups.current && initialOpenGroups.current.length > 0)
        setToOpen(initialOpenGroups.current);
      else {
        // otherwise, open first group
        const groups = allIds.filter(id => byId[id].element === GROUP);
        if (groups.length > 0) setToOpen([groups[0]]);
        else setToOpen();
      }
      initialOpenGroups.current = undefined;
      setInitialValues(formatted || {});
    }
  }, [allIds, byId, setToOpen, savedRecords, formatDisplayValues, initialValues, task]);

  const restoreFromRedis = useCallback(response => {
    const redisSave = JSON.parse(response.responses);

    setSavedRecords(prev => (prev ? {...prev, redis: redisSave} : {redis: redisSave}));
  }, []);

  const received = useCallback(
    response => {
      if (
        response &&
        response.userId === currentUser.publicId &&
        response.status === "success" &&
        response.responses
      )
        restoreFromRedis(response);
      else if (response?.userId === currentUser.publicId)
        setSavedRecords(prev => (prev ? {...prev, redis: null} : {redis: null}));

      clearTimeout(redisTimeout.current);
    },
    [currentUser, restoreFromRedis]
  );

  // Socket Management
  useEffect(() => {
    // storedValues: redis, savedValues: db
    if (isMounted()) socket.on(LISTEN_GET_FROM_REDIS, received);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LISTEN_GET_FROM_REDIS, received);
    };
  }, [socket, isMounted, received, currentUser]);

  const conditionalFormulaValidationHelper = useCallback(
    el => {
      if (el && el.hide) return yup.mixed().nullable();
      if (el && el.depends && el.depends.length > 0 && conditionsSatisfied.current) {
        let doNotRequire = false;
        for (let i = 0; i < el.depends.length; i++) {
          const depend = el.depends[i];
          if (depend in conditionsSatisfied.current && !conditionsSatisfied.current[depend]) {
            doNotRequire = true;
            break;
          }
        }
        if (doNotRequire) return yup.mixed().nullable();
      }

      return buildValidationObject(el, byId, dateForConditional, task.weather);
    },
    [byId, task, dateForConditional]
  );

  useEffect(() => {
    if (!visible) {
      setInitialValues(null);
      setLoaded(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const incompleteSave = {};
      const partialValidationSchema = {};
      const byIdKeys = Object.keys(byId);

      const groupsWithEmptyResponses = {};

      byIdKeys
        .filter(id => filterNonexistentField(id, byId, responses))
        .map(id => {
          const el = byId[id];

          if (el && el.element !== FIELD) return;

          // Fallback to false when stillShow and check are undefined
          if (el.condition) conditionsSatisfied.current[el.name] = false;

          const validation = conditionalFormulaValidationHelper(el);
          partialValidationSchema[el.name] = validation;

          if (el.hasARange) {
            partialValidationSchema[`${el.name}_comment`] = yup
              .string()
              .nullable()
              .when([el.name, `${el.name}_qualifier`], {
                is: (val, qualifier) => evaluateRange(val, el, qualifier),
                then: () =>
                  yup
                    .string()
                    .required("Please provide an explanation for the unacceptable parameter")
              });

            incompleteSave[`${el.name}_comment`] =
              responses &&
              responses[`${el.name}_comment`] &&
              responses[`${el.name}_comment`] !== "_NEEDS_COMMENT"
                ? responses[`${el.name}_comment`]
                : `${completedAt ? "Field was submitted with an unacceptable parameter" : ""}`;
          }

          if (el.hasAlert) {
            partialValidationSchema[`${el.name}_comment`] = yup
              .string()
              .nullable()
              .when([el.name, `${el.name}_qualifier`], {
                is: (val, qualifier) => evaluateRange(val, el, qualifier),
                then: () =>
                  yup
                    .string()
                    .required("Please provide an explanation for the unacceptable parameter")
              });

            incompleteSave[`${el.name}_comment`] =
              responses &&
              responses[`${el.name}_comment`] &&
              responses[`${el.name}_comment`] !== "_NEEDS_COMMENT"
                ? responses[`${el.name}_comment`]
                : `${completedAt ? "Field was submitted with an unacceptable parameter" : ""}`;
          }

          if (el.hasQualifier) {
            partialValidationSchema[`${el.name}_qualifierEnabled`] = yup.bool().nullable();
            partialValidationSchema[`${el.name}_qualifier`] = yup
              .string()
              .nullable()
              .when(`${el.name}_qualifierEnabled`, {
                is: val => !!val,
                then: () => yup.string().typeError("Select qualifier").required("Select qualifier")
              });
            if (responses && Object.keys(responses).length > 0) {
              incompleteSave[`${el.name}_qualifier`] = responses[`${el.name}_qualifier`];
              incompleteSave[`${el.name}_qualifierEnabled`] =
                responses[`${el.name}_qualifierEnabled`];
            } else {
              incompleteSave[`${el.name}_qualifier`] = null;
              incompleteSave[`${el.name}_qualifierEnabled`] = null;
            }
          }

          if (el.other)
            partialValidationSchema[`${el.name}_other`] = yup
              .string()
              .nullable()
              .when(el.name, {
                is: val => val === "Other",
                then: () => yup.string().required("Please provide description for other option.")
              });

          let value = responses ? responses[el.name] : null;
          const group = getGroupAncestor(el.name);
          if (!value) groupsWithEmptyResponses[group] = true;
          // Set Default Value
          if ((el.type === "number" || el.type === "range") && value) {
            [value] = value.length > 1 ? value.split(" ") : [value];
            value = value.replace(/,/g, "");

            if (value === "∞") incompleteSave[`${el.name}_infinite`] = true;
          }

          if (el.type === "confirm" && value) value = value.toLowerCase();

          if (el.type === "multiselect" && value && !Array.isArray(value))
            value = value.split(", ");

          // Has Conditional
          if (value && el?.condition?.list && el?.condition?.action) {
            const {condition} = el;

            const operator = condition.operator || "or";

            let show = true;
            let check = el !== undefined;
            const alwaysShow =
              !condition.action.includes("Show") && !condition.action.includes("Hide");
            const hideWhenTrue = condition.action.includes("Hide");
            const showWhenTrue = condition.action.includes("Show");

            const results = [];

            for (let i = 0; i < condition.list.length; i++) {
              //  Field Comparisons
              if (
                !["weekday", "date", "rainfall", "cumulative"].includes(condition.list[i].depends)
              )
                check = evaluateConditional(
                  value,
                  condition.list[i].check,
                  condition.list[i].compare,
                  byId[condition.list[i].depends],
                  condition.list[i].operator
                );
              else if (["rainfall", "cumulative"].includes(condition.list[i].depends)) {
                check = evaluateConditional(
                  task.weather ? task.weather[condition.list[i].depends] : null,
                  condition.list[i].check,
                  condition.list[i].compare,
                  byId[condition.list[i].depends],
                  condition.list[i].operator
                );
              } else {
                check = evaluateConditional(
                  dateForConditional,
                  condition.list[i].check,
                  condition.list[i].compare,
                  condition.list[i].depends,
                  condition.list[i].operator
                );
              }

              // Weekday and Date Comparisons
              if (condition.list[i].depends !== "weekday" && condition.list[i].depends !== "date")
                check = evaluateConditional(
                  responses[condition.list[i].depends],
                  condition.list[i].check,
                  condition.list[i].compare,
                  byId[condition.list[i].depends],
                  condition.list[i].operator
                );
              else
                check = evaluateConditional(
                  dateForConditional,
                  condition.list[i].check,
                  condition.list[i].compare,
                  condition.list[i].depends,
                  condition.list[i].operator
                );

              if (check) break;

              if (check && operator === "or") break;

              results.push(check);
            }

            if (operator === "and") check = results.every(r => !!r);

            show = (check && showWhenTrue) || (!check && hideWhenTrue) || alwaysShow;

            conditionsSatisfied.current[el.name] = show;
          }

          incompleteSave[el.name] = value !== "_CONDITION_UNSATISFIED" ? value : "";
        });

      if (responses && notificationCommentKeys?.length)
        notificationCommentKeys.map(key => {
          incompleteSave[key] = responses[key];
        });

      if (!readOnly)
        partialValidationSchema["completedAt"] = yup
          .string()
          .nullable()
          .test({
            message: "Please select a date of completion.",
            test: val => !!val
          })
          .test({
            message:
              "Selected date is in the future, please select date within the task's availability.",
            test: val => {
              const completed = dayjs(val);
              const today = convertToUserTimezone(dayjs(), settings.timezone);
              return completed.isSameOrBefore(today, "day");
            }
          })
          .test({
            message: "Please select date within the task's availability.",
            test: val => {
              if (!startDate) return true;

              const completed = dayjs(val);
              const dueStart = convertToUserTimezone(startDate, settings.timezone);
              return completed.isSameOrAfter(dueStart, "day");
            }
          });

      let key = null;
      if (taskType === "checksheet") key = `f${task.facilityId}-c${task.id}`;
      else key = `f${task.facilityId}-e${task.id}`;

      let localStorageSave = getWithExpiry(key);
      localStorageSave = localStorageSave ? JSON.parse(localStorageSave) : null;

      setValidationSchema(partialValidationSchema);
      if (persistToLocalStorage && key && !initialValues) {
        getFromRedis(key);
        setSavedRecords(prev =>
          prev
            ? {...prev, incomplete: incompleteSave, localStorage: localStorageSave}
            : {incomplete: incompleteSave, localStorage: localStorageSave}
        );
      } else if (!initialValues) {
        const formattedIncomplete = formatDisplayValues(incompleteSave, task.builder);
        setInitialValues(formattedIncomplete);
        setIsOpen(
          Object.fromEntries(
            Object.keys(byId)
              .filter(k => byId[k].element === GROUP)
              .map(k => [k, true])
          )
        );
      }
    }
  }, [
    allIds,
    byId,
    persistToLocalStorage,
    responses,
    task,
    taskType,
    notificationCommentKeys,
    formatDisplayValues,
    dateDue,
    startDate,
    readOnly,
    draftDate,
    settings,
    getFromRedis,
    initialValues,
    conditionalFormulaValidationHelper,
    getGroupAncestor,
    setToOpen,
    dateForConditional,
    visible,
    completedAt
  ]);

  const scrollHandler = data => {
    if (data.length > 0) {
      const errorMapWithIndex = {};
      data.forEach(id => {
        const index = indexedFields[id];
        errorMapWithIndex[index] = id;
      });

      const firstError = Object.entries(errorMapWithIndex).sort(([a], [b]) => a - b)[0][1];
      if (firstError in byId) {
        const groupName = getGroupAncestor(firstError);
        let firstErrorInput;
        let firstErrorGroup;
        if (groupName) {
          firstErrorGroup = document.getElementById(`accordion-${groupName}`);
          firstErrorInput = document.getElementById(firstError);

          if (firstErrorInput && firstErrorGroup) {
            scrollTo(firstErrorInput, -150, document.getElementById("modal") ?? window);
            if (isOpen && !isOpen[firstErrorGroup])
              setIsOpen(prev => ({...prev, [groupName]: true}));
            else if (!isOpen) setIsOpen({[groupName]: true});
            setTimeout(() => {
              if (firstErrorInput) setFocus(firstError);
              else setUnhandled(true);
            }, 1000);
          }
        }
      }
    }
  };

  // construct ordered list of field errors then focus/scroll to first (highest) field
  useEffect(() => {
    const errorInputNames = Object.keys(errors);
    scrollHandler(errorInputNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setFocus, indexedFields]);

  useEffect(() => {
    setSubmitTime(prettyDateInUserTimezone(new Date(), settings.timezone));
    // Update every 10 seconds
    const interval = setInterval(
      () => setSubmitTime(prettyDateInUserTimezone(new Date(), settings.timezone)),
      10000
    );

    return () => clearInterval(interval);
  }, [settings.timezone]);

  const fillInPlaceholders = useCallback(
    inputs => {
      const keys = Object.keys(inputs).filter(key => !key.match(/_comment$/g));

      const values = {...inputs};

      for (let i = 0; i < keys.length; i++) {
        const name = keys[i];
        const item = byId[name];
        if (item?.hasARange)
          if (evaluateRange(values[name], item, values[`${name}_qualifier`]))
            values[`${name}_comment`] =
              `${name}_comment` in values && values[`${name}_comment`]
                ? values[`${name}_comment`]
                : "_NEEDS_COMMENT";
          else values[`${name}_comment`] = "";

        if (item?.hasAlert) {
          const {type, alertCondition, on, off} = item;

          let condition = alertCondition;
          let value = values[name];

          if (type === "switch") {
            condition = condition === on;
            value = value === on ? on : off;
          }

          if (type === "checkbox") {
            condition = condition === "checked";
            value = value === "checked" ? "checked" : "unchecked";
          }

          if (
            values[name] === condition ||
            (Array.isArray(condition) && condition.includes(values[name]))
          ) {
            values[`${name}_comment`] =
              `${name}_comment` in values && values[`${name}_comment`]
                ? values[`${name}_comment`]
                : "_NEEDS_COMMENT";
          } else values[`${name}_comment`] = "";
        }

        if (item?.hasInfinity && `${name}_infinite` in keys && inputs[`${name}_infinite`])
          values[name] = "∞";
        else if (!(name in conditionsSatisfied.current) || conditionsSatisfied.current[name])
          values[name] = inputs[name];
        else values[name] = "_CONDITION_UNSATISFIED";
      }

      return values;
    },
    [byId]
  );

  const handleInputRateLimit = useCallback(() => {
    if (typing.current) clearTimeout(typing.current);
    const timer = setTimeout(() => {
      const {completedAt: _ca, note: _note, ...rest} = watchInputs;
      socket.emit(
        PERCENT_COMPLETE,
        room,
        `checksheet_${task.id}`,
        currentUser.publicId,
        getCompletionRate({...rest})
      );

      let key = null;
      if (taskType === "checksheet") key = `f${task.facilityId}-c${task.id}`;
      else key = `f${task.facilityId}-e${task.id}`;

      const expiry = dateDue ? new Date(dateDue) : null;

      const formatted = fillInPlaceholders(rest);

      setInRedis(key, JSON.stringify(formatted), expiry ? expiry.getTime() / 1000 : null);
      setWithExpiry(key, JSON.stringify(formatted), null, expiry?.getTime());
      typing.current = null;
    }, 500);
    typing.current = timer;
  }, [
    socket,
    task,
    room,
    watchInputs,
    currentUser,
    taskType,
    dateDue,
    setInRedis,
    fillInPlaceholders
  ]);

  // Store current draft in redis and update percentage
  useEffect(() => {
    if (
      isMounted() &&
      isDirty &&
      persistToLocalStorage &&
      Object.entries(watchInputs).some(([key, val]) => prevInputs[key] !== val)
    )
      handleInputRateLimit();
  }, [isMounted, prevInputs, watchInputs, handleInputRateLimit, persistToLocalStorage, isDirty]);

  const confirmRange = values => {
    setAlertModalVisible(false);
    setSubmission(
      values ? {...tempSubmission, ...values} : tempSubmission,
      outsideRange,
      outsideRangeMultiple,
      notifyFieldCondition
    );
    setOutsideRange(null);
    setOutsideRangeMultiple(null);
    setNotifyFieldCondition(null);
    setTempSubmission(null);
  };

  const hasKeys = obj => !!obj && Object.keys(obj).length > 0;

  const reviseSubmission = () => {
    setAlertModalVisible(false);

    let keys = [];
    if (hasKeys(outsideRangeMultiple)) keys = Object.keys(outsideRangeMultiple);
    if (hasKeys(outsideRange)) keys = [...keys, Object.keys(outsideRange)];

    scrollHandler(keys);
    setTempSubmission(null);
    setOutsideRange(null);
    setOutsideRangeMultiple(null);
  };

  const evaluateConditionArray = (condition, values) => {
    const {name, prompt, conditionArray} = condition;
    const conditionInfo = {name, prompt, dependencyInfo: {}};

    const violation = conditionArray.every(element => {
      const {depends: condDepends, aMin, aMax, alertCondition} = element;
      const dependsObj = byId[condDepends];
      const {type, on, off, parentName, label, units} = dependsObj;

      let value = values[condDepends];

      if (type === "number" || type === "generated") {
        const qualifier = values[`${condDepends}_qualifier`];
        const infinite = values[`${condDepends}_infinite`];

        const outOfRange = evaluateRange(values[condDepends], element, qualifier, infinite);

        if (outOfRange) {
          conditionInfo.dependencyInfo[condDepends] = {
            notificationName: name,
            entered: value,
            fullName: parentName ? `${getAncestors(parentName, byId)} ${label}` : label,
            qualifier,
            units: units,
            min: aMin,
            max: aMax
          };
        }

        return outOfRange;
      }

      let outOfRange = alertCondition;

      if (type === "switch") {
        outOfRange = outOfRange === on;
        if (typeof value === "boolean") value = value ? on : off;
      }

      if (type === "checkbox") {
        outOfRange = outOfRange === "checked";
        if (typeof value === "boolean") value = value ? "checked" : "unchecked";
      }

      if (
        values[condDepends] === outOfRange ||
        (Array.isArray(outOfRange) && outOfRange.includes(values[condDepends]))
      ) {
        conditionInfo.dependencyInfo[condDepends] = {
          notificationName: name,
          prompt,
          value,
          fullName: parentName ? `${getAncestors(parentName, byId)} ${label}` : label,
          condition: alertCondition,
          alert: true
        };
        return true;
      }

      return false;
    });

    if (violation) return conditionInfo;
    return null;
  };

  const submitChecksheet = values => {
    // replacing values for conditions that are not visible with _CONDITION_UNSATISFIED
    if (Object.values(formulasEvaluating).some(val => !!val))
      addBanner(
        "Cannot submit - at least one formula field is still evaluating. Please wait and try again."
      );
    else {
      removeBanner();

      const nullConditionsReplaced = {};
      const outside = {};
      const outsideMultiple = {};
      const notify = {};
      const names = Object.keys(values);
      for (let i = 0; i < names.length; i++) {
        const name = names[i];
        if (name in byId) {
          const item = byId[name];

          // Check for Acceptable Range violations
          if (
            item &&
            item.hasARange &&
            evaluateRange(values[name], item, values[`${name}_qualifier`])
          ) {
            // Add warning for Modal
            outside[name] = {
              entered: values[name],
              min: item.aMin,
              max: item.aMax,
              fullName: item.parentName
                ? `${getAncestors(item.parentName, byId)} ${item.label}`
                : item.label,
              units: item.units,
              comment: `${name}_comment` in values ? values[`${name}_comment`] : "",
              qualifier:
                `${name}_qualifierEnabled` in values && values[`${name}_qualifierEnabled`]
                  ? values[`${name}_qualifier`]
                  : "",
              prompt: item.rangeExcpetion
            };
            if (`${name}_comment` in values)
              nullConditionsReplaced[`${name}_comment`] = values[`${name}_comment`];
          }

          // Check for Acceptable Range field alerts
          if (item?.hasAlert) {
            const {type, parentName, alertCondition, on, off} = item;

            let condition = alertCondition;
            let value = values[name];

            if (type === "switch") {
              condition = condition === on;
              value = value === on ? on : off;
            }

            if (type === "checkbox") {
              condition = condition === "checked";
              value = value === "checked" ? "checked" : "unchecked";
            }

            if (
              values[name] === condition ||
              (Array.isArray(condition) && condition.includes(values[name]))
            ) {
              // Add warning for Modal
              outside[name] = {
                alert: true,
                condition,
                value,
                fullName: parentName
                  ? `${getAncestors(item.parentName, byId)} ${item.label}`
                  : item.label,
                comment: `${name}_comment` in values ? values[`${name}_comment`] : "",
                prompt: item.alertMessage
              };
              if (`${name}_comment` in values)
                nullConditionsReplaced[`${name}_comment`] = values[`${name}_comment`];
            }
          }

          // Check for notification triggers
          if (task?.notifications?.allIds?.length > 0) {
            const {allIds: nAllIds, byId: nById} = task.notifications;
            nAllIds.map(id => {
              const {depends, conditionArray, operator, template, trigger} = nById[id];
              let subject = "";
              if (template) ({subject} = template);

              if (depends && depends === name && conditionArray) {
                const checks = conditionArray.map(curr => curr.check);
                const comparisons = conditionArray.map(curr => curr.compare);
                conditionArray.map(({check, compare}) => {
                  const violation = evaluateConditional(
                    values[name],
                    checks,
                    comparisons,
                    byId[depends],
                    operator
                  );

                  // Add warning to modal
                  if (violation)
                    notify[id] = {
                      fullName: item.parentName
                        ? `${getAncestors(item.parentName, byId)} ${item.label}`
                        : item.label,
                      compare,
                      check,
                      subject
                    };
                });
              }

              if (trigger === "Unacceptable Parameter" && conditionArray) {
                const conditionInfo = evaluateConditionArray(nById[id], values);
                if (conditionInfo) outsideMultiple[id] = conditionInfo;
              }
            });
          }

          if (item?.hasQualifier && `${name}_qualifier` in values)
            nullConditionsReplaced[`${name}_qualifier`] = values[`${name}_qualifier`];

          if (item?.hasQualifier && `${name}_qualifierEnabled` in values)
            nullConditionsReplaced[`${name}_qualifierEnabled`] = values[`${name}_qualifierEnabled`];

          if (item?.hasInfinity && `${name}_infinite` in values && values[`${name}_infinite`])
            nullConditionsReplaced[name] = "∞";
          else if (!(name in conditionsSatisfied.current) || conditionsSatisfied.current[name])
            nullConditionsReplaced[name] = values[name];
          else nullConditionsReplaced[name] = "_CONDITION_UNSATISFIED";
        }

        if (name === "completedAt") nullConditionsReplaced[name] = values[name];
      }

      localStorage.removeItem(`f${task.facilityId}-c${task.id}`);

      if (
        Object.keys(outside).length === 0 &&
        Object.keys(notify).length === 0 &&
        Object.keys(outsideMultiple).length === 0
      )
        setSubmission(nullConditionsReplaced);
      else {
        setOutsideRange(outside);
        setOutsideRangeMultiple(outsideMultiple);
        setNotifyFieldCondition(notify);
        setTempSubmission(nullConditionsReplaced);
        setAlertModalVisible(true);
      }
    }
  };

  const submitDraft = () => {
    const keys = Object.keys(watchInputs);

    if (!keys.some(key => !!watchInputs[key])) {
      addToast("Cannot submit empty draft", "error");
      return;
    }

    const values = fillInPlaceholders(watchInputs);

    setDraft(values);
  };

  const renderElements = (ids, withHelp = null, withHelpShown = null) => {
    const localHiddenGroups = {};
    let hiddenGroupsChanged = false;
    const result = ids
      .filter(id => {
        const existing = filterNonexistentField(id, byId, responses);
        if (existing && !setSubmission && filterResults && byId[id].element === FIELD) {
          return filterResults.includes(id);
        }
        return existing;
      })
      .map((id, idx) => {
        const item = byId[id];

        let check = item !== undefined;
        let show = true;
        let require = check && item.required ? item.required : false;
        if (check && item.condition && item.condition.list && item.condition.action) {
          const {condition} = item;
          const alwaysShow =
            !condition.action.includes("Show") && !condition.action.includes("Hide");
          const hideWhenTrue = condition.action.includes("Hide");
          const showWhenTrue = condition.action.includes("Show");
          const requireWhenTrue = condition.action.includes("REQUIRED");
          const optionalWhenTrue = condition.action.includes("OPTIONAL");

          const operator = condition.operator || "or";
          const results = [];

          for (let i = 0; i < condition.list.length; i++) {
            const value = watch(condition.list[i].depends);
            if (
              !["weekday", "date", "rainfall", "cumulative"].includes(condition.list[i].depends)
            ) {
              check = evaluateConditional(
                value,
                condition.list[i].check,
                condition.list[i].compare,
                byId[condition.list[i].depends],
                condition.list[i].operator
              );
            } else if (["rainfall", "cumulative"].includes(condition.list[i].depends)) {
              check = evaluateConditional(
                task.weather ? task.weather[condition.list[i].depends] : null,
                condition.list[i].check,
                condition.list[i].compare,
                byId[condition.list[i].depends],
                condition.list[i].operator
              );
            } else {
              check = evaluateConditional(
                dateForConditional,
                condition.list[i].check,
                condition.list[i].compare,
                condition.list[i].depends,
                condition.list[i].operator
              );
            }
            if (check && operator === "or") break;

            results.push(check);
          }

          if (operator === "and") check = results.every(r => !!r);

          show = (check && showWhenTrue) || (!check && hideWhenTrue) || alwaysShow || readOnly;
          if ((check || readOnly) && requireWhenTrue) require = true;
          if ((check || readOnly) && optionalWhenTrue) require = false;
        }

        if (show && item.depends !== undefined && item.depends !== null) {
          const {depends} = item;
          depends.forEach(depend => {
            if (depend in conditionsSatisfied.current && !conditionsSatisfied.current[depend]) {
              show = false;
            }
          });
        }

        if (!show || show !== conditionsSatisfied.current[item.name])
          conditionsSatisfied.current[item.name] = show;

        // withHelp only exists if parent is component
        // entry (true or false) added for each field with help depending on whether they are shown
        // if any field with help is shown, show help icon
        if (withHelp && withHelpShown && withHelp.includes(item.name)) {
          withHelpShown.push(show);
        }

        let children = null;
        const childrenWithHelp =
          item.element === COMPONENT && item.hasHelp && item.help
            ? item.help.filter(({id: helpId}) => !!helpId).map(({id: helpId}) => helpId)
            : null;

        const childrenWithHelpShown =
          item.element === COMPONENT && item.hasHelp && item.help
            ? item.help.filter(({id: helpId}) => !helpId).map(() => true)
            : null;

        if (item && show && item.children?.length)
          children = renderElements(item.children, childrenWithHelp, childrenWithHelpShown);

        const willShow =
          item !== undefined &&
          show &&
          (!item.element ||
            item.element === FIELD ||
            (Array.isArray(children) && children.length > 0 && children.some(child => !!child)));

        if (item.element === GROUP) {
          localHiddenGroups[item.name] = !willShow;
          if (!willShow !== hiddenGroups[item.name]) hiddenGroupsChanged = true;
        }

        return (
          (willShow || (!setSubmission && byId[id].element === FIELD)) && (
            <Element
              room={room}
              key={id}
              index={idx}
              byId={byId}
              task={task}
              taskRecord={taskRecord}
              taskType={taskType}
              attributes={{...item, required: require && !!willShow}}
              showPreview={!willShow && !setSubmission}
              readOnly={readOnly}
              allowNotes={setSubmission !== null}
              setShowHelpModal={
                !childrenWithHelpShown || childrenWithHelpShown.some(child => !!child)
                  ? setShowHelp
                  : null
              }
              setTarget={setCurrentElement}
              previousRead={
                previousSubmission && item.name in previousSubmission
                  ? previousSubmission[item.name]
                  : null
              }
              overdue={overdue}
              stage={stage}
              isOpen={isOpen ? isOpen[item.name] : undefined}
              setIsOpen={openGroup => {
                setIsOpen(prev =>
                  prev
                    ? Object.fromEntries(
                        Object.keys(prev).map(k => [k, openGroup === k ? !prev[k] : !!prev[k]])
                      )
                    : {[openGroup]: true}
                );
              }}
              weather={task.weather}
              setFormulasEvaluating={setFormulasEvaluating}
              globalUnacceptable={globalUnacceptable.current[item.name]?.node}
              globalUnacceptableTitles={globalUnacceptable.current[item.name]?.titles}>
              {children}
            </Element>
          )
        );
      });

    if (hiddenGroupsChanged) setHiddenGroups(localHiddenGroups);

    if (!result || (Array.isArray(result) && result.every(item => !item))) return null;

    return result;
  };

  const clearChecksheet = useCallback(() => {
    if (watchInputs) {
      const key = `f${task.facilityId}-c${task.id}`;
      setInRedis(key, null);
      localStorage.removeItem(key);
      reset(Object.fromEntries(Object.keys(watchInputs).map(k => [k, null])));
      clearErrors();
    }
  }, [clearErrors, reset, setInRedis, task, watchInputs]);

  useEffect(() => {
    if (readOnly && !prevReadOnly) clearChecksheet();
  }, [readOnly, prevReadOnly, clearChecksheet]);

  const evaluateGlobalParameters = () => {
    if (task?.notifications?.byId) {
      globalUnacceptable.current = {};

      const conditionsViolated = {};
      Object.keys(task.notifications.byId)
        .filter(
          key =>
            task.notifications.byId[key]?.trigger === "Unacceptable Parameter" &&
            task.notifications.byId[key]?.conditionArray
        )
        .map(key => {
          const notification = task.notifications.byId[key];
          const conditionInfo = evaluateConditionArray(notification, watchInputs);
          if (conditionInfo) {
            notification.conditionArray.map(({depends}) => {
              if (conditionsViolated[depends]) {
                conditionsViolated[depends][key] = conditionInfo;
                if (!conditionsViolated[depends].titles.includes(notification.name))
                  conditionsViolated[depends].titles.push(notification.name);
              } else
                conditionsViolated[depends] = {titles: [notification.name], [key]: conditionInfo};
            });
          }
        });

      Object.keys(conditionsViolated).map(depends => {
        const conditions = conditionsViolated[depends];
        const {titles} = conditions;
        delete conditions.titles;
        const hasComments = Object.keys(conditions).every(key => !!watchInputs[`${key}_comment`]);

        globalUnacceptable.current[depends] = {
          node: (
            <UnacceptableButton
              type="button"
              onClick={() => setMultiUnacceptableModalTarget(conditions)}
              hasComments={hasComments}>
              <Abbr
                title={
                  hasComments
                    ? "All explanations for unacceptable parameters collected, click to edit."
                    : "This field is involved in one or more unresolved multi-field acceptable parameter violations, click to view details."
                }>
                <UnacceptableIcon
                  icon={faExclamationCircle}
                  fill={hasComments ? "black" : status.error}
                />
              </Abbr>
            </UnacceptableButton>
          ),
          titles
        };
      });
    }
  };

  const reevaluateChecksheet = ids => {
    evaluateGlobalParameters();
    const result = renderElements(ids);

    if (!result || (Array.isArray(result) && result.every(item => !item)))
      return filterResults ? (
        <Message>No fields matching this filter</Message>
      ) : (
        <Message>No fields found for this checksheet</Message>
      );

    return result;
  };

  const filteredErrors = Object.keys(errors).filter(error => error !== "completedAt");

  const checksheetContent =
    allIds.length > 0 ? (
      reevaluateChecksheet(allIds)
    ) : (
      <NoElements>Please toggle elements for checksheet.</NoElements>
    );

  const shouldSubmit = values =>
    setSubmission
      ? submitChecksheet(values)
      : addToast("Checksheet would successfully submit.", "info");

  return loaded ? (
    <>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(shouldSubmit)} noValidate>
          {!readOnly && (
            <SpacedInline forceRightAlign={dateDue && showDates ? 0 : 1}>
              {dateDue && overdue && showDates && (
                <>
                  <DueDate>
                    Completing overdue checksheet from{" "}
                    {prettyDateInUserTimezone(dateDue, settings.timezone, "MMM DD, YYYY")}
                  </DueDate>
                  <DueDate>
                    Current date:&nbsp;
                    {prettyDateInUserTimezone(dayjs(), settings.timezone, "MMM DD, YYYY")}
                  </DueDate>
                </>
              )}
              {dateDue && !overdue && showDates && (
                <DueDate>
                  Due {prettyDateInUserTimezone(dateDue, settings.timezone, "MMM DD, YYYY")}
                </DueDate>
              )}
              {taskType === "checksheet" && hasGroups && allOpen !== null && (
                <ToggleButton
                  type="button"
                  onClick={() => {
                    if (allOpen)
                      setIsOpen(prev =>
                        prev ? Object.fromEntries(Object.keys(prev).map(key => [key, false])) : {}
                      );
                    else
                      setIsOpen(prev =>
                        prev ? Object.fromEntries(Object.keys(prev).map(key => [key, true])) : {}
                      );
                  }}>
                  {allOpen ? "Collapse All" : "Expand All"}
                </ToggleButton>
              )}
            </SpacedInline>
          )}

          {checksheetContent}

          {!readOnly && (
            <FormGroup>
              {!hideCompleted && (
                <FormField>
                  <InputDate
                    label="Completed Date"
                    name="completedAt"
                    required
                    testId="date-completedAt"
                  />
                </FormField>
              )}
              <FormField>
                <Label bold>CURRENT TIME:</Label>
                <Text>{submitTime}</Text>
                {user && (
                  <>
                    <Label bold>PARTIALLY COMPLETED BY:</Label>
                    <Text>
                      {user} on {prettyDateInUserTimezone(dayjs(draftDate), settings.timezone)}
                    </Text>
                  </>
                )}
              </FormField>
              {!hideMeta && Object.keys(filteredErrors).length > 0 && (
                <FormField>
                  {unHandled && (
                    <Error>
                      {Object.keys(filteredErrors).length} field
                      {Object.keys(filteredErrors).length > 1 ? "s have" : " has"} unhandled errors.
                      Field
                      {Object.keys(filteredErrors).length > 1 ? "s " : " "}
                      Responsible:&nbsp;
                      {Object.keys(filteredErrors)
                        .filter(error => error in byId)
                        .map(
                          (error, index) =>
                            index < 4 && (
                              <span key={error}>
                                {byId[error].label}
                                {index <
                                Math.min(
                                  Object.keys(filteredErrors).filter(e => e in byId).length - 1,
                                  3
                                )
                                  ? ", "
                                  : ""}
                              </span>
                            )
                        )}
                      {Object.keys(filteredErrors).length > 4 ? "..." : "."} Please report to your
                      system admin.
                    </Error>
                  )}
                  {!unHandled && (
                    <Error>
                      {Object.keys(filteredErrors).length} field
                      {Object.keys(filteredErrors).length > 1 ? "s have" : " has"} unhandled errors.
                      Scroll up to adjust invalid or missing responses and try again.
                    </Error>
                  )}
                </FormField>
              )}
              <Certification>
                By submitting this checksheet, I {currentUser.firstName} {currentUser.lastName},
                certify that all information provided by me in this checksheet (and/or any other
                accompanying or required documents) is correct, accurate and complete to the best of
                my knowledge and I accept this as my digital signature.
              </Certification>
              <FormOptions>
                {setSubmission ? (
                  <ButtonRow>
                    <SubmitButton
                      type="submit"
                      loading={completeLoading ? 1 : 0}
                      data-testid="renderChecksheet.submit">
                      {completeLoading && <ButtonLoader />}
                      Submit
                    </SubmitButton>
                    {setDraft && (
                      <DraftButton
                        type="button"
                        onClick={() => submitDraft()}
                        data-testid="renderChecksheet.saveDraft"
                        loading={draftLoading ? 1 : 0}>
                        {draftLoading && <ButtonLoader />}
                        Save as Incomplete
                      </DraftButton>
                    )}
                  </ButtonRow>
                ) : (
                  <ButtonRow>
                    <SubmitButton type="submit">Test Validation</SubmitButton>
                  </ButtonRow>
                )}
                <ButtonRow>
                  {allowDeleteFromLocalStorage && (
                    <ClearButton
                      data-testid="renderChecksheet.clear"
                      type="button"
                      onClick={() => setShowClearLocalStorage(true)}>
                      Clear Input
                    </ClearButton>
                  )}
                  {cancelFunction && (
                    <CancelButton
                      type="button"
                      onClick={() => {
                        clearTimeout(redisTimeout.current);
                        cancelFunction();
                      }}>
                      Cancel
                    </CancelButton>
                  )}
                </ButtonRow>
              </FormOptions>
            </FormGroup>
          )}

          {currentElement && (
            <ModalHelp
              visible={showHelp}
              setVisible={setShowHelp}
              target={currentElement}
              conditionsSatisfied={conditionsSatisfied}
              byId={byId}
            />
          )}

          {showClearLocalStorage && (
            <ModalClearLocalStorage
              visible={showClearLocalStorage}
              setVisible={setShowClearLocalStorage}
              reset={clearChecksheet}
            />
          )}
        </Form>
      </FormProvider>

      {alertModalVisible &&
        (hasKeys(outsideRangeMultiple) ||
          hasKeys(outsideRange) ||
          hasKeys(notifyFieldCondition)) && (
          <ModalRangeAlert
            visible={alertModalVisible}
            setVisible={setAlertModalVisible}
            outsideRange={outsideRange}
            outsideRangeMultiple={outsideRangeMultiple}
            notifyFieldCondition={notifyFieldCondition}
            confirmFunction={confirmRange}
            reviseFunction={reviseSubmission}
            isSubmitting
            values={watchInputs}
          />
        )}

      {multiUnacceptableModalTarget && (
        <ModalRangeAlert
          visible={!!multiUnacceptableModalTarget}
          setVisible={state => {
            if (!state) setMultiUnacceptableModalTarget(null);
          }}
          outsideRangeMultiple={multiUnacceptableModalTarget}
          confirmFunction={values => {
            Object.keys(values).map(key => {
              setValue(key, values[key]);
            });
            setMultiUnacceptableModalTarget(null);
          }}
          reviseFunction={() => setMultiUnacceptableModalTarget(null)}
          values={watchInputs}
        />
      )}
    </>
  ) : (
    <NotLoaded>
      <Loader data-testid="renderChecksheet.loader" />
    </NotLoaded>
  );
};

RenderChecksheet.propTypes = {
  room: PropTypes.string,
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  taskRecord: PropTypes.objectOf(PropTypes.any),
  stage: PropTypes.string,
  readOnly: PropTypes.bool,
  startDate: PropTypes.string,
  responses: PropTypes.objectOf(PropTypes.any),
  setSubmission: PropTypes.func,
  draft: PropTypes.bool,
  draftDate: PropTypes.string,
  draftLoading: PropTypes.bool,
  setDraft: PropTypes.func,
  persistToLocalStorage: PropTypes.bool,
  dateDue: PropTypes.string,
  overdue: PropTypes.bool,
  cancelFunction: PropTypes.func,
  previousSubmission: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.string,
  hideMeta: PropTypes.bool,
  showDates: PropTypes.bool,
  simulatedDate: PropTypes.string,
  completedAt: PropTypes.string,
  completeLoading: PropTypes.bool,
  hideCompleted: PropTypes.bool,
  allowDeleteFromLocalStorage: PropTypes.bool,
  visible: PropTypes.bool,
  filterResults: PropTypes.arrayOf(PropTypes.any)
};

RenderChecksheet.defaultProps = {
  room: null,
  taskRecord: null,
  stage: null,
  readOnly: false,
  responses: null,
  draft: false,
  draftDate: null,
  draftLoading: false,
  setDraft: null,
  setSubmission: null,
  persistToLocalStorage: false,
  dateDue: null,
  overdue: false,
  cancelFunction: null,
  previousSubmission: null,
  user: null,
  hideMeta: false,
  showDates: false,
  simulatedDate: null,
  completedAt: null,
  startDate: null,
  hideCompleted: false,
  completeLoading: false,
  allowDeleteFromLocalStorage: false,
  visible: true,
  filterResults: undefined
};

// Style Overrides
const NoElements = styled(Text)`
  color: ${({theme}) => theme.error};
`;

const DueDate = styled(Text)`
  margin-bottom: ${pad}px;
`;

const ButtonRow = styled(Inline)`
  gap: ${pad}px;
  flex-direction: column;
  align-items: start;
  width: 100%;

  ${bp(1)} {
    width: unset;
    flex-direction: row;
    align-items: center;
    flex: none;
  }
`;

const SubmitButton = styled(Button)`
  color: ${({theme}) => theme.primary};
  padding: ${pad}px;
  width: 100%;
  max-width: 100%;

  ${({loading}) =>
    loading &&
    css`
      background: rgba(0, 0, 0, 0.5);
      pointer-events: none;
    `};

  ${bp(1)} {
    width: max-content;
    max-width: max-content;
    padding: ${pad / 2}px ${pad}px;
  }
`;

const DraftButton = styled(Button)`
  color: ${({theme}) => theme.warning};
  padding: ${pad}px;
  width: 100%;
  max-width: 100%;

  ${({loading}) =>
    loading &&
    css`
      background: rgba(0, 0, 0, 0.5);
      pointer-events: none;
    `};

  ${bp(1)} {
    width: max-content;
    max-width: max-content;
    padding: ${pad / 2}px ${pad}px;
  }
`;

const Certification = styled(Small)`
  color: ${({theme}) => theme.secondary};
  line-height: 1.5;
  margin-bottom: ${pad * 2}px;
  max-width: 300px;

  ${bp(3)} {
    max-width: ${breakpoint.width[1]};
  }
`;

const SpacedInline = styled(Inline)`
  justify-content: ${({forceRightAlign}) => (forceRightAlign ? "flex-end" : "space-between")};
  margin-top: ${({forceRightAlign}) => (forceRightAlign ? `${pad / 2}px` : 0)};
  width: 100%;
  margin: ${pad}px 0;
`;

const FormOptions = styled(Inline)`
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: ${pad}px;
  width: 100%;

  ${bp(1)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: ${pad}px;
  }
`;

const ClearButton = styled(Button)`
  color: red;
  padding: ${pad}px;
  width: 100%;
  max-width: 100%;

  ${bp(1)} {
    width: max-content;
    max-width: max-content;
    padding: ${pad / 2}px ${pad}px;
  }
`;

const CancelButton = styled(Button)`
  padding: ${pad}px;
  width: 100%;
  max-width: 100%;

  ${bp(1)} {
    width: max-content;
    max-width: max-content;
    padding: ${pad / 2}px ${pad}px;
  }
`;

const ToggleButton = styled(Button)`
  margin-top: -${pad}px;
`;

const Message = styled(Text)`
  color: ${({theme}) => theme.secondary};
  margin: ${pad * 2}px 0;
  ${voice.weak};
`;

const UnacceptableIcon = styled(FontAwesomeIcon)`
  fill: ${({fill}) => fill || "black"};
  vertical-align: 1em;
`;

const UnacceptableButton = styled.button`
  width: 16px;
  height: 16px;
  position: relative;

  ${({hasComments, theme}) =>
    !hasComments &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 50%;
        z-index: -1;
        animation: ${pulseDelay(theme.error)} 2s infinite;
        animation-duration: 10s;
      }
    `}
`;

export default RenderChecksheet;
