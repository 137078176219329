import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

// Style
import {border, pad, radius} from "../style/components/variables";
import {Anchor, Inline} from "../style/components/general";

const FilePreview = ({selected, link = true, download = true}) => {
  if (selected.extension && selected.extension.name && selected.extension.name.includes("image"))
    return (
      <>
        <FileWrapper>
          <img src={selected.link} alt={selected.label} />
        </FileWrapper>
        {link ||
          (download && (
            <Options>
              {download && (
                <Download href={selected.download} download>
                  <FontAwesomeIcon icon={faDownload} />
                  &nbsp;Download
                </Download>
              )}
              {link && (
                <Anchor href={selected.link} target="_blank">
                  Open in New Window
                </Anchor>
              )}
            </Options>
          ))}
      </>
    );

  if (
    selected.extension &&
    selected.extension.name &&
    (selected.extension.name.includes("pdf") || selected.extension.name === "text/plain")
  )
    return (
      <>
        <PDF>
          <embed src={selected.link} />
        </PDF>
        {(link || download) && (
          <Options>
            {download && (
              <Download href={selected.download} download>
                <FontAwesomeIcon icon={faDownload} />
                &nbsp;Download
              </Download>
            )}
            {link && (
              <Anchor href={selected.link} target="_blank">
                Open in New Window
              </Anchor>
            )}
          </Options>
        )}
      </>
    );

  return (
    <Options>
      <Download href={selected.download} download={`${selected.label}`}>
        <FontAwesomeIcon icon={faDownload} />
        &nbsp;Download
      </Download>
    </Options>
  );
};

FilePreview.propTypes = {
  selected: PropTypes.objectOf(PropTypes.any).isRequired,
  link: PropTypes.bool,
  download: PropTypes.bool
};

// Style Overrides
const FileWrapper = styled.div`
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  overflow: hidden;
  width: fit-content;
  margin-top: ${pad}px;
  max-width: 100%;

  p {
    padding: ${pad}px;
  }

  img {
    max-width: 100%;
    object-fit: contain;
    display: block;
  }
`;

const PDF = styled(FileWrapper)`
  width: 100%;

  embed {
    width: 100%;
    height: 600px;
  }
`;

const Download = styled(Anchor)`
  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const Options = styled(Inline)`
  margin-top: ${pad}px;
  gap: ${pad}px;
`;

export default FilePreview;
