import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {Abbr, FormFieldWrapper, Input, Label, Pill, Text} from "../../style/components/general.js";

const InputText = ({
  testId = "input-text",
  label,
  name,
  prompt,
  placeholder,
  required = false,
  disabled = false,
  capitalize = false,
  defaultValue = "",
  hidden = false,
  focused,
  callbackFocus,
  callbackBlur,
  onClick,
  hideError = false,
  callback,
  maxLength,
  condition,
  tag
}) => {
  const [hasInput, setHasInput] = useState(false);
  const [focus, setFocus] = useState(false);
  const [length, setLength] = useState(maxLength);

  const {
    setValue,
    watch,
    register,
    formState: {errors}
  } = useFormContext();

  const value = watch(name);

  useEffect(() => setHasInput(value && value.length > 0), [value]);

  useEffect(() => {
    if (value && capitalize) {
      const transformed = value.toUpperCase();
      if (transformed !== value) setValue(name, transformed);
    }
  }, [capitalize, name, setValue, value]);

  // Listen for focus and blur
  useEffect(() => {
    if (focused) focused(focus);
  }, [focus, focused]);

  const handleFocus = () => {
    setFocus(true);
    if (callbackFocus) callbackFocus();
  };

  const handleBlur = e => {
    setFocus(false);
    if (callbackBlur) callbackBlur(e);
  };

  useEffect(() => {
    if (maxLength && value && value.length > 0) setLength(maxLength - value.length);
    else if (maxLength) setLength(maxLength);

    if (callback) callback();
  }, [value, length, maxLength, callback]);

  return (
    <FormFieldWrapper hidden={hidden} data-testid={testId}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}
      <Area maxLength={maxLength}>
        <Input
          id={name}
          data-testid={`${testId}-input`}
          type="text"
          name={name}
          placeholder={!hasInput && prompt ? prompt : placeholder}
          disabled={disabled}
          defaultValue={defaultValue}
          hidden={hidden}
          required={required}
          onFocus={handleFocus}
          onClick={onClick}
          maxLength={maxLength || 500000}
          {...register(name, {
            required: required,
            onBlur: handleBlur
          })}
        />
        {maxLength && length > 0 && <MaxLength data-testid={`${testId}-max`}>{length}</MaxLength>}
      </Area>
      {prompt && hasInput && !errors[name] && <Prompt>{prompt}</Prompt>}
      {!hideError && errors && <InputError errors={errors} name={name} />}
    </FormFieldWrapper>
  );
};

InputText.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  capitalize: PropTypes.bool,
  defaultValue: PropTypes.string,
  hidden: PropTypes.bool,
  focused: PropTypes.func,
  callbackFocus: PropTypes.func,
  callbackBlur: PropTypes.func,
  onClick: PropTypes.func,
  callback: PropTypes.func,
  maxLength: PropTypes.number,
  hideError: PropTypes.bool,
  condition: PropTypes.string,
  tag: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ])
};

// Style Overrides
const Prompt = styled(Text)`
  ${voice.quiet};
  margin-top: 2px;
  position: relative;
  top: 100%;
  left: 0;
`;

const Area = styled.div`
  position: relative;
  width: 100%;

  ${Input} {
    ${({maxLength}) =>
      maxLength &&
      maxLength > 0 &&
      css`
        padding-right: ${pad * 3}px;
      `}
  }
`;

const MaxLength = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: ${pad}px;
`;

const LabelWrapper = styled(Label)`
  gap: 6px;
  margin-bottom: 2px;
`;

export default InputText;
