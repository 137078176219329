import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faFolder, faSearch, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";

// Utils
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Dropdown from "../../components/Dropdown.js";

// Styles
import {bp} from "../../style/components/breakpoints.js";
import {pad, radius} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  Inline,
  Heading,
  TableWrapper,
  TableMenu,
  TableFooter,
  Search,
  SearchWrapper,
  SearchIcon,
  Button,
  Arrow,
  Abbr
} from "../../style/components/general.js";

const CustomTableSharePoint = ({
  index,
  table,
  active,
  setActive,
  handleEditTable,
  inGlobalTables = false
}) => {
  const {
    sharepointFolderId,
    name,
    label,
    columns,
    facility: {name: facilityName, slug: facilitySlug, type, isDeleted}
  } = table;

  const isMounted = useMountedState();

  const {roleCanAccessPage, roleCanAccessResource} = useContext(AuthContext);

  const {settings} = useContext(SettingsContext);
  const {timezone} = settings;

  // Custom Table
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState();
  const [headings, setHeadings] = useState({});
  const [folderId, setFolderId] = useState(sharepointFolderId);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [maxBreadcrumbs, setMaxBreadcrumbs] = useState(3);
  // Base Table
  const [items, setItems] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [query, setQuery] = useState();
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("name");
  const [limit, setLimit] = useState(10);
  const [loadMore, setLoadMore] = useState();

  const showDeleted = useRef(false);

  // Previous Hooks
  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);
  const prevLimit = usePrevious(limit);

  const {api: apiMS, loading} = useApi("microsoft", {suppress: {longRequest: false}});

  const formatTableData = useCallback(
    payload =>
      payload.map(item => {
        const newItem = {};
        Object.keys(headings).forEach(heading => {
          if (heading === "preview" && roleCanAccessResource("custom_table_row", "view"))
            newItem[heading] = item?.folder ? (
              <FontAwesomeIcon icon={faFolder} />
            ) : (
              <Link href={item.webUrl} target="_blank">
                View
              </Link>
            );
          else if (heading === "name")
            newItem[heading] = item?.folder ? (
              <NameButton
                folder={item.folder}
                onClick={() => {
                  setFolderId(item.id);
                  setBreadcrumbs(prev => [...prev, {id: item.id, name: item.name.toUpperCase()}]);
                }}>
                <Abbr title={item.name}>{item.name}</Abbr>
              </NameButton>
            ) : (
              item.name
            );
          else if (heading === "createdAt")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone);
          else if (heading === "createdBy") newItem[heading] = item[heading].user.displayName;
          else if (heading === "updatedAt")
            newItem[heading] = prettyDateInUserTimezone(item.lastModifiedDateTime, timezone);
          else if (heading === "updatedBy") newItem[heading] = item.lastModifiedBy.user.displayName;
          else newItem[heading] = item[heading];
        });

        return newItem;
      }),
    [headings, roleCanAccessResource, timezone]
  );

  const getTableData = useCallback(
    id => {
      setItems([]);
      setShowOptions(false);

      const filter = {showDeleted: showDeleted.current};

      if (query) filter.Search = query;

      const payload = {
        resourceType: "sharepoint",
        resourcePath: "drive",
        resourceId: id,
        orderBy: orderBy,
        groupBy,
        limit,
        filter: JSON.stringify(filter)
      };

      apiMS.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data?.drive) {
          const formatted = formatTableData(data.drive);
          setTableData(formatted);
          setLoadMore(data?.next);
          setBreadcrumbs(prev => [
            ...prev,
            {id: data.drive[0].parentReference.id, name: data.drive[0].parentReference.name}
          ]);
        }
      });
    },
    [apiMS, groupBy, limit, orderBy, query, formatTableData]
  );

  // Format Headings
  useEffect(() => {
    if (isMounted()) {
      const tempHeaders = {};
      columns.map(col => {
        if (col.name !== "name") tempHeaders[col.name] = {header: col.label, disabled: true};
        else tempHeaders[col.name] = {header: col.label, disabled: false};
      });
      setHeadings(tempHeaders);
    }
  }, [isMounted, columns]);

  // Open first table
  useEffect(() => {
    if (isMounted() && index === 1) setOpen(true);
  }, [isMounted, index]);

  const getBreadCrumbMax = useCallback(() => {
    let maxCrumbs = breadcrumbs.length;
    if (window.innerWidth < 475) maxCrumbs = 2;
    else if (window.innerWidth < 600) maxCrumbs = 3;
    else if (window.innerWidth < 768) maxCrumbs = 4;
    else if (window.innerWidth < 850) maxCrumbs = 2;
    else if (window.innerWidth < 1000) maxCrumbs = 3;
    else if (window.innerWidth < 1300) maxCrumbs = 5;
    setMaxBreadcrumbs(maxCrumbs);
  }, [breadcrumbs.length]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && open && !tableData && sharepointFolderId) getTableData(sharepointFolderId);
  }, [isMounted, open, tableData, sharepointFolderId, getTableData]);

  useEffect(() => {
    if (isMounted()) window.addEventListener("resize", getBreadCrumbMax);

    return () => {
      window.removeEventListener("resize", getBreadCrumbMax);
    };
  }, [isMounted, breadcrumbs, getBreadCrumbMax]);

  // Folder Changes
  useEffect(() => {
    if (isMounted() && folderId) getTableData(folderId);
  }, [isMounted, folderId, getTableData]);

  // Update rows on sort or limit
  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      prevLimit &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy || prevLimit !== limit)
    )
      getTableData();
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, prevLimit, orderBy, groupBy, limit]);

  // Search
  useEffect(() => {
    if (open && (query || query === "")) getTableData();
  }, [open, query, getTableData]);

  const handleSearch = e => setQuery(e.target.value);

  const tableLabel = useMemo(() => {
    let l = "";
    if (inGlobalTables && facilityName) l += `${facilityName.toUpperCase()} ${type} `;
    l += label;
    return l;
  }, [facilityName, inGlobalTables, label, type]);

  return (
    <CustomTableWrapper key={name}>
      <TableMenuRow>
        <TableHeading>
          <TableLabel>
            <Img src="/assets/sharepoint.ico" alt="Sharepoint" />
            &nbsp;
            <Abbr title={tableLabel}>{tableLabel}</Abbr>
          </TableLabel>
          {!inGlobalTables && roleCanAccessResource("custom_table", "update") && !isDeleted && (
            <EditButton
              type="button"
              onClick={() => {
                if (handleEditTable) handleEditTable(table);
              }}>
              <Abbr title="Edit Table">
                <FontAwesomeIcon icon={faEdit} />
              </Abbr>
            </EditButton>
          )}
          {inGlobalTables && facilitySlug && roleCanAccessPage("records") && (
            <EditLink to={`/facilities/${facilitySlug}/tables`}>
              <Abbr title="View in Facility">
                <FontAwesomeIcon icon={faUpRightFromSquare} />
              </Abbr>
            </EditLink>
          )}
        </TableHeading>
        <ToggleActive
          type="button"
          onClick={() => {
            setOpen(prev => !prev);
            setActive(index);
          }}>
          <Arrow rotate={!open ? "180deg" : "270deg"} />
        </ToggleActive>
      </TableMenuRow>
      {open && (
        <div>
          <TableMenuRow>
            <HeaderMenu>
              <MenuItems active={active}>
                <SearchWrapper>
                  <SearchIcon>
                    <FontAwesomeIcon icon={faSearch} />
                  </SearchIcon>
                  <Search name="search" type="text" placeholder="Rows..." onChange={handleSearch} />
                </SearchWrapper>
                <CustomTableMenu>
                  {breadcrumbs?.length > maxBreadcrumbs && "... "}
                  {breadcrumbs?.map((crumb, idx) => (
                    <NameButton
                      key={crumb.id}
                      title={crumb.name}
                      active={breadcrumbs.length - 1 === idx}
                      hide={
                        breadcrumbs?.length > maxBreadcrumbs &&
                        idx < breadcrumbs.length - maxBreadcrumbs
                      }
                      onClick={() => {
                        setFolderId(crumb.id);
                        const crumbs = [];
                        for (let i = 0; i < breadcrumbs.length; i++) {
                          if (breadcrumbs[i].id !== crumb.id) crumbs.push(breadcrumbs[i]);
                          else {
                            crumbs.push(breadcrumbs[i]);
                            break;
                          }
                        }
                        setBreadcrumbs(crumbs);
                      }}>
                      <span style={{fontWeight: "normal"}}>/</span>&nbsp;{crumb.name}
                    </NameButton>
                  ))}
                </CustomTableMenu>
              </MenuItems>
            </HeaderMenu>
          </TableMenuRow>
          <RowWrapper>
            <BaseTable
              headings={headings}
              data={tableData}
              loading={loading}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              items={items}
              setItems={setItems}
              showOptions={showOptions}
              setShowOptions={setShowOptions}
            />
          </RowWrapper>
          <TableFooter>
            <PageLimit>
              <Dropdown
                options={[10, 20]}
                selection={limit}
                setSelection={selection => setLimit(selection)}
              />
            </PageLimit>
            {loadMore && (
              <Button
                type="button"
                onClick={() =>
                  apiMS
                    .callGet(null, {
                      resourceType: "sharepoint",
                      resourcePath: "drive",
                      next: loadMore
                    })
                    .then(({status, data}) => {
                      if (status === 200 && data?.drive) {
                        setTableData(prev => [...prev, ...formatTableData(data.drive)]);
                        setLoadMore(data?.next);
                      }
                    })
                }>
                Load More
              </Button>
            )}
          </TableFooter>
        </div>
      )}
    </CustomTableWrapper>
  );
};

CustomTableSharePoint.propTypes = {
  index: PropTypes.number.isRequired,
  table: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    tags: PropTypes.arrayOf(PropTypes.any).isRequired,
    global: PropTypes.bool,
    fileOnly: PropTypes.bool,
    sharepointFolderId: PropTypes.string,
    facility: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isDeleted: PropTypes.bool,
      type: PropTypes.string.isRequired
    })
  }).isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  handleEditTable: PropTypes.func,
  inGlobalTables: PropTypes.bool
};

// Style Overrides
const CustomTableWrapper = styled(TableWrapper)`
  min-width: 350px;
`;

const CustomTableMenu = styled(TableMenu)`
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: ${pad}px;
`;

const NameButton = styled.button`
  text-align: left;
  text-decoration: ${({folder}) => (folder ? "underline" : "none")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: ${({hide}) => (hide ? "none" : "block")};
  font-weight: ${({active}) => (active ? "bold" : "normal")};
  cursor: ${({active}) => (active ? "default" : "pointer")};
  pointer-events: ${({active}) => (active ? "none" : "inherit")};
  width: min-content;
`;

const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const TableLabel = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Img = styled.img`
  height: ${pad * 2}px;
`;

const TableHeading = styled.div`
  display: inline-flex;
  overflow: hidden;
  max-width: calc(100% - 30px);
`;

const EditButton = styled.button`
  ${voice.normal};
  margin-left: ${pad}px;
  height: min-height;
  align-self: center;
  width: max-content;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const EditLink = styled(NavLink)`
  ${voice.normal};
  margin-left: ${pad}px;
  height: min-height;
  align-self: center;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const HeaderMenu = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(2)} {
    display: flex;
  }
`;

const MenuItems = styled(Inline)`
  justify-content: space-between;
  width: 100%;
  opacity: ${({active}) => active};
`;

const ToggleActive = styled(Button)`
  background: none;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const RowWrapper = styled.div`
  position: relative;
`;

const Link = styled.a`
  ${voice.normal};
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  max-width: max-content;
  background-color: ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.tertiary};
`;

export default CustomTableSharePoint;
