import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  useLayoutEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faClose, faEdit} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";
import {NotificationContext} from "../../contexts/notify.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {iconLookup} from "../../components/form/InputFieldType.js";
import {exists, getSnakeCase, prettyDateInUserTimezone, toTitleCase} from "../../utils/helpers.js";
import {
  CUSTOM_OPTION,
  DAY_IDX,
  IDX_TO_DAY,
  DAY_NAMES_DAYJS,
  eventTypeColor,
  DAY_TO_IDX_DAYJS,
  IDX_TO_DAY_DAYJS,
  getNumberSuffix,
  BASE_OPTIONS_PLURAL,
  BASE_OPTIONS
} from "./helpers.js";

// Components
import AccordionWrapped from "../../components/AccordionWrapped.js";
import Element from "../../components/Element.js";
import Help from "../../components/Help.js";
import SearchSelect from "../../components/SearchSelect.js";
import Modal from "../../components/Modal.js";
import ModalStage from "./ModalStage.js";
import {
  InputText,
  InputSelect,
  InputDate,
  InputDay,
  InputRadioGroup,
  InputError,
  InputCheck,
  InputNumber
} from "../../components/form/FormInputs.js";
import InputCalendar, {
  calendarValidation,
  formatDefaults
} from "../../components/form/InputCalendar.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {
  ButtonFull,
  Label,
  Form,
  Select,
  FormField,
  Button,
  Heading,
  Small,
  Text,
  Abbr,
  Error,
  Inline,
  FormFieldWrapper,
  Input
} from "../../style/components/general.js";

// Socket Constants
import {EVENT_MODIFIED} from "../general/Room.js";
import {bp} from "../../style/components/breakpoints.js";

const icons = iconLookup();

const REMINDER = "reminder";

const NEVER = "Never";
const ON = "Date";
const UNTIL_OPTIONS = [NEVER, ON];

const defaultEvent = {
  type: "",
  name: "",
  start: "",
  frequency: "",
  recurrence: {
    interval: 1,
    base: "days",
    endDate: null,
    ends: NEVER,
    days: null
  }
};

const defaultSchema = {
  type: yup.string().required("Please provide an event type."),
  name: yup.string().when("templatizing", {
    is: val => !!val,
    then: () => yup.string().nullable(),
    otherwise: () => yup.string().required("Please provide name.")
  }),
  templatizing: yup.bool(),
  start: yup.string().when("templatizing", {
    is: val => !!val,
    then: () => yup.string().nullable(),
    otherwise: () => calendarValidation
  }),
  frequency: yup.string().nullable(),
  recurrence: yup
    .object()
    .nullable()
    .when("frequency", ([freq], schema) => {
      if (freq === CUSTOM_OPTION.name)
        return yup.object().shape({
          interval: yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .required("Please provide interval."),
          base: yup.string().required("Please provide frequency."),
          days: yup
            .array()
            .of(yup.string())
            .when("base", ([base]) => {
              if (base !== "weeks") return yup.mixed().nullable();
              return yup
                .array()
                .of(yup.string())
                .test({
                  test: v => Array.isArray(v) && v.length > 0,
                  message: "Please provide weekday."
                });
            }),
          ends: yup.string().required("Please select one."),
          endDate: yup.string().when("ends", {
            is: v => v === "Date",
            then: () => yup.string().required(),
            otherwise: () => yup.string().nullable()
          }),
          availability: yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
        });

      if (freq)
        return yup.object().shape({
          ends: yup.string().required("Please select one."),
          endDate: yup.string().when("ends", {
            is: v => v === "Date",
            then: () => yup.string().required(),
            otherwise: () => yup.string().nullable()
          })
        });

      return schema;
    }),
  group: yup.string().nullable()
};

const NO_LAST_DAY_OPTION = ["daily", "weekly", "bi-weekly", "work-week", "semi-monthly"];

const ModalEventManage = ({
  visible,
  setVisible,
  isGlobal,
  isEditing,
  facility,
  date,
  event,
  eventTypes,
  getEvents,
  frequencies,
  units,
  setUnits,
  renderModalControls
}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {currentUser, roleCanAccessResource, roles} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);
  const {getNotifications} = useContext(NotificationContext);

  const [eventType, setEventType] = useState(event?.type || null);
  const [unitsToAdd, setUnitsToAdd] = useState([]);
  const [stagesOpen, setStagesOpen] = useState(null);
  const [targetStage, setTargetStage] = useState(null);
  const [skipDayCorrection, setSkipDayCorrection] = useState(true);
  const [skipDateCorrection, setSkipDateCorrection] = useState(true);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [startPreview, setStartPreview] = useState("");
  // Search Inputs
  const [allFacilities, setAllFacilities] = useState([]);
  const [facilityResults, setFacilityResults] = useState([]);
  const [appliedTemplate, setAppliedTemplate] = useState(null);
  const [templateResults, setTemplateResults] = useState([]);
  // Modal States
  const [modalShowStage, setShowModalStage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const hasSubmitted = useRef(false);
  const scrollContainer = useRef(null);
  const scrollPos = useRef(null);

  const getFreqName = () => {
    if (event.availableIndex) return "custom";
    if (event?.frequency?.name === "work-week") return "work-week";
    if (event?.frequency?.name && event?.frequency?.visible) return event.frequency.name;
    if (event?.frequency) return "custom";
    return "";
  };

  const editingCustomWeek = useMemo(
    () => event?.frequency?.name !== "work-week" && event?.frequency?.exclude?.length > 1,
    [event]
  );

  const getDefaultInterval = () => {
    if (editingCustomWeek) return 1;
    if (event.frequency && !event.frequency?.visible) return event.frequency.interval;
    return null;
  };

  const getDefaultBase = () => {
    if (editingCustomWeek) return "weeks";
    if (event.frequency && !event.frequency?.visible) return event.frequency.base;
    return null;
  };

  const defaultValues =
    isEditing && event
      ? {
          facility: facility?.name || null,
          type: event.type?.name?.toLowerCase(),
          name: event.name,
          responses: event.responses,
          stages: event.stages,
          frequency: getFreqName(),
          recurrence: {
            interval: getDefaultInterval(),
            base: getDefaultBase(),
            endDate: event.endDate,
            ends: event.endDate ? "Date" : "Never",
            days: null,
            availability: event.availableIndex
          },
          group: event.group,
          ...formatDefaults(
            "start",
            prettyDateInUserTimezone(event.startDate, settings.timezone),
            event.lastDay
          )
        }
      : {
          facility: facility?.name || null,
          ...defaultEvent,
          templatize: false,
          recurrence: {
            ...defaultEvent.recurrence,
            days: null,
            availability: null
          },
          group: null,
          ...formatDefaults("start", date)
        };

  const form = useForm({
    defaultValues,
    resolver: yupResolver(
      yup
        .object()
        .shape({facility: yup.string().required("Facility is required."), ...defaultSchema})
    )
  });

  const {
    register,
    watch,
    setValue,
    reset,
    handleSubmit,
    trigger,
    formState: {errors, submitCount}
  } = form;

  const watchType = watch("type");
  const watchFacility = watch("facility");
  const watchFrequency = watch("frequency");
  const watchStartDate = watch("start.date");
  const watchLastDay = watch("start.lastDay");
  const watchBase = watch("recurrence.base");
  const watchInterval = watch("recurrence.interval");
  const watchEnds = watch("recurrence.ends");
  const watchDueDays = watch("recurrence.days");
  const watchAvailability = watch("recurrence.availability");

  const {api: apiFacilities} = useApi("facilities");
  const {api: apiFrequency} = useApi("frequencies");
  const {api: apiEvents} = useApi("events");
  const {api: apiTemplatesRetrieve, data: templates} = useApi("event-templates");

  const minDate = useMemo(() => {
    if (["weekly", "bi-weekly"].includes(watchFrequency))
      return dayjs().subtract(1, "week").add(1, "day").format("YYYY-MM-DD");
    if (["monthly", "bi-monthly"].includes(watchFrequency))
      return dayjs().startOf("month").format("YYYY-MM-DD");
    if (["annually", "biennially", "triennially"].includes(watchFrequency))
      return dayjs().startOf("year").format("YYYY-MM-DD");
    return dayjs().format("YYYY-MM-DD");
  }, [watchFrequency]);

  const hasLastDayOption = useMemo(() => {
    if (!watchFrequency) return false;
    if (watchFrequency === "custom")
      return watchBase?.includes("month") || watchBase?.includes("year");
    return !NO_LAST_DAY_OPTION.includes(watchFrequency);
  }, [watchFrequency, watchBase]);

  const availableOptions = useMemo(() => {
    if (
      watchFrequency !== CUSTOM_OPTION.name ||
      !["month", "months", "year", "years"].includes(watchBase)
    )
      return [];

    const options = [];

    const parsedInterval = parseInt(watchInterval, 10);

    if (exists(parsedInterval) && !Number.isNaN(parsedInterval)) {
      if (watchBase?.includes("month") && parsedInterval > 1) {
        for (let i = 1; i < parsedInterval + 1; i++) {
          options.push({name: i, label: `${i}${getNumberSuffix(i)} Month`});
        }
      }

      if (watchBase?.includes("year")) {
        if (parsedInterval === 1) {
          for (let i = 1; i < 13; i++) {
            options.push({name: i, label: `${i}${getNumberSuffix(i)} Month`});
          }
        } else {
          for (let i = 1; i < parsedInterval + 1; i++) {
            options.push({name: i, label: `${i}${getNumberSuffix(i)} Year`});
          }
        }
      }
    }

    return options;
  }, [watchFrequency, watchBase, watchInterval]);

  const showAvailable = useMemo(() => {
    if (watchFrequency !== CUSTOM_OPTION.name) return false;
    if (watchBase?.includes("month"))
      return watchInterval && watchInterval > 1 && watchInterval < 24;
    if (watchBase?.includes("year")) return watchInterval && watchInterval < 10;
    return false;
  }, [watchBase, watchFrequency, watchInterval]);

  const availableError = useMemo(() => {
    if (watchFrequency === CUSTOM_OPTION.name && watchBase?.includes("month"))
      if (watchInterval <= 1)
        return "Availability window not supported for intervals of less than 2 months.";
      else if (watchInterval >= 24)
        return "Availability window not supported for intervals of 24 months or longer.";
    if (watchFrequency === CUSTOM_OPTION.name && watchBase?.includes("year"))
      if (watchInterval > 10)
        return "Availability window not supported for intervals of longer than 10 years.";
    return "";
  }, [watchFrequency, watchBase, watchInterval]);

  const selectType = useCallback(
    type => {
      if (appliedTemplate !== null) setAppliedTemplate(null);

      const {name, stages} = type;

      const allIds = [];
      const byId = {};

      let prev = null;
      stages?.map(({name: stageName, color, hasFields, help, builder, restrictTo}, index) => {
        const key = getSnakeCase(stageName);

        // Default Values
        const stage = {
          name: stageName,
          color,
          restrictTo: null,
          builder: null,
          last: index === type.stages.length - 1,
          next: null
        };

        if (hasFields) {
          stage.help = help;
          stage.builder = builder;
          stage.restrictTo = restrictTo;
        }

        allIds.push(key);
        byId[key] = stage;

        if (prev) byId[prev].next = key;

        prev = key;
      });

      setEventType(type);

      setValue("type", name);
      setValue("stages", {allIds, byId});
    },
    [appliedTemplate, setValue]
  );

  useEffect(() => {
    if (eventTypes && !watchType) {
      if (event?.type) selectType(event?.type);
      else {
        const matches = eventTypes.filter(({name}) => name === "action");

        selectType(matches.length ? matches[0] : eventTypes[0]);
      }
    }
  }, [eventTypes, selectType, event, watchType]);

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      apiTemplatesRetrieve.callGet().then(({status, data}) => {
        if (status === 200) setTemplateResults(data);
      });

      if (isGlobal)
        // Adding an event
        apiFacilities.callGet().then(({status, data}) => {
          if (status === 200 && data) {
            const facilities = data.map(({id, name, type}) => ({
              id: id,
              name: name,
              label: `${name.toUpperCase()} ${type.toUpperCase()}`
            }));
            setAllFacilities(facilities);
            setFacilityResults(facilities);
          }
        });
      else
        apiFacilities.callGet(facility.id).then(({status, data}) => {
          if (status === 200) {
            const available = data.builder.allIds.map(id => {
              const {element, name, label} = data.builder.byId[id];
              return element === "group"
                ? {
                    name,
                    label
                  }
                : null;
            });
            setAvailableGroups(available);
          }
        });
    }
  }, [isMounted, apiFacilities, facility, event, isGlobal, apiTemplatesRetrieve]);

  // Set preview based on selected frequency
  useEffect(() => {
    if (watchFrequency && watchStartDate) {
      let params;

      if (watchFrequency !== "custom") params = {start: watchStartDate, name: watchFrequency};

      if (watchFrequency === "custom" && watchInterval && watchBase) {
        params = {
          start: watchStartDate,
          interval: Number.parseInt(watchInterval, 10),
          base: watchBase,
          exclude:
            watchDueDays && watchDueDays.length > 1
              ? DAY_IDX.filter(idx => !watchDueDays.includes(IDX_TO_DAY[idx]))
              : null
        };
        if (watchAvailability) {
          params.availableBase =
            watchBase.includes("month") || watchInterval === 1 ? "months" : "years";
          params.availableIndex = watchAvailability;
        }
      }

      if (params)
        apiFrequency.callGet(null, params).then(({status, data}) => {
          if (status === 200 && data) setStartPreview(data);
        });
    }
  }, [
    apiFrequency,
    watchStartDate,
    watchLastDay,
    watchFrequency,
    watchInterval,
    watchBase,
    watchDueDays,
    watchAvailability
  ]);

  useEffect(() => {
    if (isGlobal && watchFacility) {
      const match = allFacilities.filter(({name: fName}) => fName === watchFacility)[0];
      const targetId = match?.id;
      apiFacilities.callGet(targetId).then(({status, data}) => {
        if (status === 200 && data) {
          const available = data.builder?.allIds?.map(id => {
            const {element, name, label} = data.builder.byId[id];
            if (element === "group") {
              return {
                name,
                label
              };
            }
            return null;
          });
          setAvailableGroups(available);
        }
      });
    }
  }, [isGlobal, watchFacility, allFacilities, apiFacilities]);

  const searchFacilities = query => {
    if (query)
      setFacilityResults(
        allFacilities
          ? allFacilities.filter(f => f.name.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setFacilityResults(allFacilities);
  };

  const searchTemplates = query => {
    if (query)
      setTemplateResults(
        templates
          ? templates.filter(template => template.name.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setTemplateResults(templates);
  };

  const applyTemplate = template => {
    setAppliedTemplate(template);
    const {type, name, responses, stages, ...rest} = template;

    reset(prev => ({
      ...prev,
      type: type.name,
      name,
      stages,
      responses,
      ...rest
    }));
  };

  // Set Event Type
  useEffect(() => {
    if (watchType && eventTypes?.length > 0)
      eventTypes.map(type => {
        const {name, stages} = type;
        if (name === watchType) {
          setEventType(type);
          if (stages)
            setStagesOpen(
              Object.fromEntries(stages.map(({name: stgName}) => [getSnakeCase(stgName), true]))
            );
        }
      });
    else {
      setEventType({});
      setStagesOpen(null);
    }
  }, [eventTypes, watchType]);

  const prevFrequency = useRef(null);

  // Reset Frequency when switching from custom frequency
  useEffect(() => {
    if (
      prevFrequency.current === "custom" &&
      !editingCustomWeek &&
      (!isEditing || event?.frequency?.exclude)
    ) {
      setValue("recurrence.interval", "1");
      setValue("recurrence.base", "days");
    }

    prevFrequency.current = watchFrequency;

    if (watchFrequency === "semi-monthly") {
      let start = dayjs();

      const midCurrentMonth = Math.floor(start.daysInMonth() / 2);

      if (start.date() > midCurrentMonth) {
        start = start.date(start.daysInMonth());
      } else {
        start = start.date(midCurrentMonth);
      }

      setValue("start.date", start.format("YYYY-MM-DD"));
      setValue("start.visibleDate", start.format("MM/DD/YYYY"));
    }

    if (watchFrequency === "quarterly") {
      let start = dayjs();

      const currentMonth = start.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 3) nextMonth = 2;
      if (nextMonth >= 3 && nextMonth < 6) nextMonth = 5;
      if (nextMonth >= 6 && nextMonth < 9) nextMonth = 8;
      else nextMonth = 11;

      start = start.date(1);
      start = start.month(nextMonth);

      const quarterEnd = start.daysInMonth();
      start = start.date(quarterEnd);

      setValue("start.date", start.format("YYYY-MM-DD"));
      setValue("start.visibleDate", start.format("MM/DD/YYYY"));
    }
  }, [watchFrequency, editingCustomWeek, isEditing, event, setValue]);

  // Reset weekday list when base is not weeks
  useEffect(() => {
    if (
      watchFrequency !== "weekly" &&
      (watchBase !== "weeks" || watchFrequency !== CUSTOM_OPTION.name)
    )
      setValue("recurrence.days", null);
  }, [watchBase, watchFrequency, setValue]);

  // Week Based Frequencies (restricts day selection to one) -> set dueDay depending on startDate
  useEffect(() => {
    const startDateWeekday = dayjs(watchStartDate).day();
    if (
      watchBase === "weeks" &&
      startDateWeekday &&
      DAY_NAMES_DAYJS[startDateWeekday] &&
      !skipDayCorrection
    ) {
      const target = toTitleCase(DAY_NAMES_DAYJS[startDateWeekday]);
      setSkipDateCorrection(true);

      if (!watchDueDays && exists(startDateWeekday)) setValue("recurrence.days", [target]);
      else if (target && watchDueDays && !watchDueDays.includes(target)) {
        setValue(
          "recurrence.days",
          [...watchDueDays, target].sort(day => DAY_TO_IDX_DAYJS[day.toLowerCase()])
        );
      }
    } else setSkipDayCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchStartDate, skipDayCorrection, watchFrequency, setValue]);

  // Custom Frequency (allows multiple day selection) -> update dueDay depending on startDate
  useEffect(() => {
    const startDateWeekday = dayjs(watchStartDate).day();
    if (
      (watchBase === "weeks" || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
      exists(startDateWeekday) &&
      DAY_NAMES_DAYJS[startDateWeekday] &&
      !skipDayCorrection
    ) {
      const target = toTitleCase(DAY_NAMES_DAYJS[startDateWeekday]);
      setSkipDateCorrection(true);

      if (
        (!watchDueDays || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
        exists(startDateWeekday)
      )
        setValue("recurrence.days", [target]);
      else if (target && watchDueDays && !watchDueDays.includes(target)) {
        setValue(
          "recurrence.days",
          [...watchDueDays, target].sort(day => DAY_TO_IDX_DAYJS[day.toLowerCase()])
        );
      }
    } else setSkipDayCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchStartDate, skipDayCorrection, watchFrequency, setValue]);

  // Custom Frequency (allows multiple day selection) -> updates startDate depending on dueDays
  useEffect(() => {
    if (
      (watchBase === "weeks" || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
      watchDueDays?.length > 0 &&
      !skipDateCorrection
    ) {
      const startDateWeekday = dayjs(watchStartDate).day();
      if (!watchDueDays.includes(IDX_TO_DAY_DAYJS[startDateWeekday])) {
        let i = dayjs(date).day();
        let hasResetToSunday = false;
        while (!watchDueDays.includes(IDX_TO_DAY_DAYJS[i])) {
          if (i === 6) {
            // prevents infinite loop if for whatever reason watchDueDays does not contain any valid days
            if (hasResetToSunday) break;
            hasResetToSunday = true;
            i = 0;
          } else i++;
        }

        let start = dayjs(watchStartDate).day(i);

        if (start.isBefore(dayjs())) start = start.add(1, "week");

        setSkipDayCorrection(true);
        setValue("start.date", start.format("YYYY-MM-DD"));
        setValue("start.visibleDate", start.format("MM/DD/YYYY"));
      }
    } else setSkipDateCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchDueDays, skipDateCorrection, watchFrequency, date, setValue]);

  // Reset on close
  useEffect(() => {
    if (!visible) reset({...defaultEvent});
  }, [reset, visible]);

  const saveStage = ({name, restrictTo, help, builder}) => {
    const key = getSnakeCase(name);
    const target = `stages.byId.${key}`;
    const watchTarget = watch(target);
    setValue(target, {
      ...watchTarget,
      name,
      restrictTo,
      help,
      builder
    });

    setTargetStage(null);
    setShowModalStage(false);
    setStagesOpen(prev => ({
      ...prev,
      [key]: true
    }));

    if (hasSubmitted.current) trigger();
  };

  useEffect(() => {
    if (errors?.stages) hasSubmitted.current = true;
  }, [errors]);

  const saveEvent = values => {
    const {
      facility: facilityName,
      name,
      start: {date: start, lastDay},
      type,
      frequency,
      recurrence,
      responses,
      stages,
      templatize,
      group
    } = values;

    const dueDays = recurrence ? recurrence.days : null;

    let exclude = null;
    if (frequency === "custom" && dueDays && dueDays.length > 1)
      exclude = DAY_IDX.filter(idx => !dueDays.includes(IDX_TO_DAY[idx]));

    const match = facilityName
      ? allFacilities.filter(({label: fName}) => fName === facilityName)[0]
      : null;

    const selectedFacility = match?.id ? match.id : facility.id;

    const postData = {
      userId: currentUser.publicId,
      facilityId: selectedFacility,
      name,
      startDate: dayjs(start).format("YYYY-MM-DD"),
      lastDay:
        // remove last day option when not relevant
        hasLastDayOption ? lastDay : null,
      type,
      responses: responses,
      stages,
      units: unitsToAdd,
      templatize,
      group,
      exclude
    };

    if (frequency && recurrence) {
      const selected = frequency ? frequencies.find(freq => freq.name === frequency) : null;
      postData.base = selected ? selected.base : recurrence.base;
      postData.interval = selected ? selected.interval : recurrence.interval;
      if (selected) postData.frequency = selected.name;
      if (recurrence.ends !== "Never") postData.endDate = recurrence.endDate;
      else postData.endDate = null;
      if (showAvailable && postData.base && recurrence.availability) {
        postData.availableBase =
          postData.base.includes("month") || postData.interval === 1 ? "months" : "years";
        postData.availableIndex = recurrence.availability;
      }
    }

    const success = isEditing ? 200 : 201;
    const request = isEditing
      ? apiEvents.callPut(event.id, postData)
      : apiEvents.callPost(postData);

    request.then(({status, data: eventData}) => {
      if (status === success) {
        setVisible(false);
        getNotifications(selectedFacility.slug);
        getEvents();
        socket.emit(
          EVENT_MODIFIED,
          selectedFacility.slug,
          eventData.data.id,
          isEditing ? "put" : "post"
        );
        // TODO: navigate to due date
      }
    });
  };

  const handleAccordion = name => {
    setStagesOpen(prev => {
      if (!prev) return prev;
      return {...prev, [name]: !prev[name]};
    });
  };

  useLayoutEffect(() => {
    if (modalShowStage) {
      scrollPos.current = document.getElementById("modal").scrollTop;
      setShowModal(true);
    } else {
      scrollContainer.current.scrollTop = scrollPos.current;
      setShowModal(false);
    }
  }, [modalShowStage]);

  const renderStages = () => {
    if (eventType?.stages) {
      const {stages} = eventType;
      const content = stages.map((stage, index) => {
        const {name, color, hasFields} = stage;

        const key = getSnakeCase(name);
        const watchById = watch("stages.byId");
        const watchRestrictTo = watch(`stages.byId.${key}.restrictTo`);
        const watchFields = watch(`stages.byId.${key}.builder`);
        const watchNext = watch(`stages.byId.${key}.next`);

        const roleLabel =
          watchRestrictTo?.filter(({id}) => roles.filter(({id: roleId}) => id === roleId)[0]?.label)
            ?.length > 0 && roles
            ? watchRestrictTo
                .filter(({id}) => roles.filter(({id: roleId}) => id === roleId)[0]?.label)
                .map(({id}) => roles.filter(({id: roleId}) => id === roleId)[0].label)
                .join(", ")
            : "Unset";

        return (
          <Stage key={name}>
            {index === 0 && (
              <>
                <Status color={colors.blue}>
                  <Label bold>
                    <Abbr title="SCHEDULED">SCHEDULED</Abbr>
                  </Label>
                </Status>
                <Arrow>
                  <FontAwesomeIcon icon={faArrowDown} />
                </Arrow>
              </>
            )}

            <AccordionWrapped
              labelNode={
                <ButtonTextWrapper>
                  <AccordionLabel invertColors>
                    <Abbr title={name.toUpperCase()}>{name.toUpperCase()}</Abbr>
                  </AccordionLabel>

                  {hasFields && (
                    <Help showModal inverted>
                      Defined on type, edits not allowed on template or event.
                    </Help>
                  )}
                </ButtonTextWrapper>
              }
              menu={
                !hasFields ? (
                  <Button
                    type="button"
                    title="Edit Stage"
                    onClick={e => {
                      e.stopPropagation();
                      setTargetStage(stage);
                      setShowModalStage(true);
                    }}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                ) : null
              }
              toggleOpen={stagesOpen ? stagesOpen[key] : null}
              handleOpen={() => handleAccordion(key)}
              invertColors>
              <StageContent color={`#${color}`}>
                <Small>
                  Available:&nbsp;
                  <Abbr title={roleLabel}>{roleLabel || "All"}</Abbr>
                </Small>
                {watchFields?.allIds?.length > 0 ? (
                  watchFields.allIds.map(id => {
                    const {type: fieldType, name: fieldName, label} = watchFields.byId[id];
                    return (
                      <Field key={fieldName}>
                        <FontAwesomeIcon icon={icons[fieldType]} />
                        &nbsp;
                        <Abbr title={label}>{label}</Abbr>
                      </Field>
                    );
                  })
                ) : (
                  <Text inverted>No fields</Text>
                )}
                {watchNext && watchNext !== null && (
                  <Small>
                    Triggers:&nbsp;
                    <Abbr title={watchById[watchNext].name.toUpperCase()}>
                      {watchById[watchNext].name.toUpperCase()}
                    </Abbr>
                  </Small>
                )}
              </StageContent>
            </AccordionWrapped>

            <ErrorWrapper>
              {errors?.stages?.byId && errors?.stages?.byId[key] && (
                <Error>{errors?.stages?.byId[key].message}</Error>
              )}
            </ErrorWrapper>

            {/* Show Completed */}
            {index === stages.length - 1 && (
              <>
                <Arrow>
                  <FontAwesomeIcon icon={faArrowDown} />
                </Arrow>
                <Status color={colors.green}>
                  <Label bold>
                    <Abbr title="COMPLETED">COMPLETED</Abbr>
                  </Label>
                </Status>
              </>
            )}

            <Arrow hidden={index === stages.length - 1}>
              <FontAwesomeIcon icon={faArrowDown} />
            </Arrow>
          </Stage>
        );
      });

      return (
        <FormField>
          <Label bold>STAGES*</Label>
          <Stages>{content}</Stages>
          {errors?.stages && <Error>One or more stages have errors. Please see above.</Error>}
        </FormField>
      );
    }

    return null;
  };

  if (modalShowStage && targetStage && showModal)
    return (
      <ModalStage
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalStage(false)}
        stage={targetStage}
        existing={targetStage?.name && watch(`stages.byId.${getSnakeCase(targetStage.name)}`)}
        units={units}
        setUnits={setUnits}
        unitsToAdd={unitsToAdd}
        setUnitsToAdd={setUnitsToAdd}
        saveStage={saveStage}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      renderModalControls={renderModalControls()}
      maxWidth="600px"
      scrollRef={scrollContainer}>
      <FormProvider {...form}>
        <Form noValidate>
          <Heading center>{isEditing ? "Edit Event" : "Add Event"}</Heading>
          <br />
          {isGlobal && (
            <FormField>
              <Label bold>FACILITY *</Label>
              {!watchFacility ? (
                <SearchWrapper>
                  <SearchSelect
                    results={facilityResults}
                    setResults={setFacilityResults}
                    search={searchFacilities}
                    add={value => {
                      setValue("facility", value.label);
                      if (submitCount > 0) trigger("facility");
                    }}
                    placeholder="Search..."
                    showAll
                  />
                  <InputError errors={errors} name="facility" />
                </SearchWrapper>
              ) : (
                <SelectedFacility type="button" onClick={() => setValue("facility", null)}>
                  <ButtonTextWrapper>
                    <Abbr title={watchFacility}>
                      <SelectedFacilityText>Applied: {watchFacility}</SelectedFacilityText>
                    </Abbr>
                    <Abbr title="Remove Facility">
                      <FontAwesomeIcon icon={faClose} />
                    </Abbr>
                  </ButtonTextWrapper>
                </SelectedFacility>
              )}
            </FormField>
          )}

          {!isEditing && (
            <FormField>
              <Label bold>TEMPLATES</Label>
              {!appliedTemplate ? (
                <SearchWrapper>
                  <SearchSelect
                    results={templateResults}
                    setResults={setTemplateResults}
                    search={searchTemplates}
                    add={applyTemplate}
                    placeholder="Search..."
                    showAll
                  />
                  {templates?.length === 0 && <TemplateWarning>No templates exist</TemplateWarning>}
                </SearchWrapper>
              ) : (
                <Button
                  type="button"
                  title="Unapply Template"
                  onClick={() => {
                    setAppliedTemplate(null);
                    reset(defaultValues);
                  }}>
                  <span>Applied: {appliedTemplate.name}</span>
                  &nbsp;
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              )}
            </FormField>
          )}

          <FormField inline>
            <Select {...register("type", {required: true})} hidden>
              {eventTypes?.map(({name, value}) => (
                <option key={`${name}-hidden`} value={value}>
                  {name}
                </option>
              ))}
            </Select>
            <Inline>
              {eventTypes?.map(curr => {
                const {id, name} = curr;
                return (
                  <EventType
                    key={id}
                    type="button"
                    title={name}
                    onClick={!isEditing ? () => selectType(curr) : undefined}
                    active={watchType === name}
                    color={eventTypeColor(curr)}
                    disabled={isEditing}>
                    {toTitleCase(name)}
                  </EventType>
                );
              })}
            </Inline>
          </FormField>

          <FormField>
            <InputText name="name" placeholder="Name" label="EVENT NAME" required />
          </FormField>

          {/* Custom Fields defined from Type */}
          {eventType?.fields?.allIds?.map(id => {
            const field = eventType.fields.byId[id];
            return (
              field && (
                <FormField key={id}>
                  <Element attributes={{...field, name: `responses.${id}`}} />
                </FormField>
              )
            );
          })}

          {roleCanAccessResource("event", "create") && renderStages()}

          {!isEditing && eventType?.name !== REMINDER && (
            <FormField>
              <InputCheck name="templatize">
                Do you want to save this event as a template?
              </InputCheck>
            </FormField>
          )}

          <Frequency>
            {frequencies?.length > 0 && (
              <InputSelect
                name="frequency"
                label="REPEATS"
                placeholder="Does not repeat"
                options={[
                  ...frequencies.map(frequency => ({
                    name: frequency.name,
                    label: frequency.label
                  })),
                  {...CUSTOM_OPTION}
                ]}
                minWidth={200}
              />
            )}
          </Frequency>

          {watchFrequency === CUSTOM_OPTION.name && (
            <CustomGroup>
              <FormField>
                <Label bold>REPEAT EVERY</Label>
                <CustomGroup>
                  <InputNumber
                    testId="interval"
                    name="recurrence.interval"
                    min={1}
                    max={10}
                    maxWidth
                    required={false}
                    disableInverse
                  />
                  <InputSelect
                    testId="base"
                    fullWidth
                    name="recurrence.base"
                    options={watchInterval > 1 ? BASE_OPTIONS_PLURAL : BASE_OPTIONS}
                  />
                </CustomGroup>
              </FormField>
              {showAvailable && (
                <InputSelect
                  name="recurrence.availability"
                  label="AVAILABLE"
                  options={availableOptions}
                  placeholder="Always"
                />
              )}
            </CustomGroup>
          )}

          {(watchFrequency === "weekly" ||
            watchFrequency === "bi-weekly" ||
            watchBase === "weeks") && (
            <FormField>
              <InputDay
                name="recurrence.days"
                restrictOne={watchFrequency !== CUSTOM_OPTION.name}
                restrictWarning={
                  watchFrequency === CUSTOM_OPTION.name
                    ? "Multiple day selection not currently supported for intervals longer than 1 week"
                    : null
                }
              />
            </FormField>
          )}

          {availableError && (
            <WarningWrapper>
              <WarningHeader>Warning!</WarningHeader>&nbsp;{availableError}
            </WarningWrapper>
          )}

          <FormField data-testid="event.startDate">
            <Inline>
              {watchFrequency && (
                <FormFieldWrapper selfInline>
                  <Label bold>AVAILABLE DATE</Label>
                  <Input
                    type="text"
                    value={
                      startPreview &&
                      prettyDateInUserTimezone(startPreview, null, "MM / DD / YYYY, h:mm A")
                    }
                    disabled
                  />
                </FormFieldWrapper>
              )}
              <FormFieldWrapper selfInline>
                <InputCalendar
                  name="start"
                  label="FIRST DUE DATE"
                  showLastDay={hasLastDayOption}
                  min={minDate}
                  disabled={["semi-monthly", "quarterly"].includes(watchFrequency)}
                />
              </FormFieldWrapper>
            </Inline>

            {(watchFrequency === "monthly" ||
              watchFrequency === "bi-monthly" ||
              watchBase === "months") &&
              !["semi-monthly", "quarterly"].includes(watchFrequency) &&
              dayjs(watchStartDate).date() > 28 && (
                <WarningWrapper>
                  <WarningHeader>Warning!</WarningHeader>&nbsp;For months with less or greater
                  than&nbsp;
                  {dayjs(watchStartDate).date()} days, this event will be due on the last day of the
                  month
                </WarningWrapper>
              )}
            {watchFrequency === "semi-monthly" && (
              <WarningWrapper>
                <WarningHeader>Warning!</WarningHeader>&nbsp;Defaults to the middle or last day of
                the month for semi-monthly events.
              </WarningWrapper>
            )}
            {watchFrequency === "quarterly" && (
              <WarningWrapper>
                <WarningHeader>Warning!</WarningHeader>&nbsp;Defaults to the last day of the quarter
                for quarterly events.
              </WarningWrapper>
            )}
          </FormField>

          {watchFrequency && (
            <>
              <FormField>
                <InputRadioGroup
                  name="recurrence.ends"
                  label="END"
                  options={UNTIL_OPTIONS}
                  orient={false}
                />
              </FormField>
              {watchEnds === ON && (
                <FormField>
                  <InputDate name="recurrence.endDate" label="LAST EVENT" />
                </FormField>
              )}
            </>
          )}

          {availableGroups?.length > 0 && (
            <FormField>
              <InputSelect
                name="group"
                label="Associate Facility Group"
                placeholder="Select..."
                options={availableGroups}
                minWidth={150}
              />
            </FormField>
          )}
        </Form>
      </FormProvider>

      <Submit type="button" onClick={handleSubmit(saveEvent)}>
        {isEditing ? "Update Event" : "Add Event"}
      </Submit>
    </Modal>
  );
};

ModalEventManage.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  facility: PropTypes.objectOf(PropTypes.any),
  event: PropTypes.objectOf(PropTypes.any),
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  getEvents: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnits: PropTypes.func.isRequired,
  renderModalControls: PropTypes.func.isRequired
};

ModalEventManage.defaultProps = {
  facility: null,
  event: null
};

// Style Overrides
const EventType = styled(Button)`
  color: ${({color}) => color};
  border: ${border} solid ${({color}) => color};
  background: transparent;

  ${({active, color, theme}) =>
    active &&
    css`
      color: ${theme.tertiary};
      background: ${color};
    `};
`;

const SelectedFacility = styled(Button)`
  overflow: hidden;

  ${voice.quiet};
`;

const SelectedFacilityText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ButtonTextWrapper = styled(Inline)`
  gap: ${pad / 2}px;
  width: 100%;
`;

const Warning = styled(Small)`
  color: ${({theme}) => theme.secondary};
`;

const Stages = styled.div`
  ${flex("column", "nowrap", "start", "start")};
  width: 65%;
  padding: ${pad}px ${pad}px ${pad * 2}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  gap: ${pad}px;
`;

const Stage = styled.div`
  width: 100%;
`;

const StageContent = styled.div`
  padding: ${pad}px;
  display: flex;
  flex-direction: column;
  background: ${({color, theme}) => color || theme.secondary};
`;

const Arrow = styled.div`
  font-size: 30px;
  text-align: center;

  ${({hidden}) =>
    hidden &&
    css`
      opacity: 0;
    `}
`;

const Status = styled.div`
  padding: ${pad}px;
  border-radius: ${radius};
  background: ${({color}) => color};
  overflow: hidden;

  ${Label} {
    display: block;
    width: 100%;
    color: ${({theme}) => theme.tertiary};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Field = styled.div`
  padding: ${pad}px 0;
  color: ${({theme}) => theme.tertiary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const Submit = styled(ButtonFull)`
  margin-bottom: ${pad / 2}px;
  margin-top: ${pad}px;
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: ${pad * 2}px;
`;

const TemplateWarning = styled.span`
  ${voice.small};
  color: ${({theme}) => theme.primary};
`;

const Frequency = styled(FormField)`
  min-width: 200px;
`;

const CustomGroup = styled(Inline)`
  align-items: end;

  div {
    margin: 0;
  }

  input {
    min-width: 45px;
    width: 45px;
  }

  select {
    width: 150px;
  }

  ${bp(2)} {
    margin-right: 0;
  }
`;

const ErrorWrapper = styled.div`
  margin-top: -${pad / 2}px;
  margin-bottom: ${pad}px;
`;

const WarningWrapper = styled(Warning)`
  margin-top: ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
`;

const WarningHeader = styled.strong`
  color: ${({theme}) => theme.warning};
`;

const AccordionLabel = styled(Label)`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.secondary};

  ${({invertColors, theme}) =>
    invertColors
      ? css`
          color: ${theme.tertiary};
        `
      : ""};
`;

export default ModalEventManage;
