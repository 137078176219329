import React, {useState, useEffect, Fragment, useContext, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Contexts
import {useToast} from "../../contexts/toast.js";
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Utils
import MapProvider, {
  formatAddressComponents,
  formatAddressForGeocode
} from "../../utils/google/maps.js";

// Components
import Modal from "../../components/Modal.js";
import Location from "../../components/Location.js";
import {InputCheck, InputTextGroup} from "../../components/form/FormInputs.js";

// Styles
import {flex} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  Heading,
  FormField,
  Form,
  Inline,
  FormGroup,
  Button,
  Text,
  Small,
  Label
} from "../../style/components/general.js";

const maps = new MapProvider();

const DEFAULT_LOCATION = {
  address: {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    details: [{}],
    hasDetails: false,
    primary: false
  }
};

const schema = yup.object().shape({
  address: yup.lazy(({lat, lon, line1}) => {
    if ((!lat || lat === "") && (!lon || lon === "") && line1 !== "" && line1 !== undefined)
      return yup.object().shape({
        line1: yup.string().required("Line 1 is required"),
        line2: yup.string().nullable(),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zipCode: yup.string().required("Zip Code is required"),
        lat: yup.number().nullable(),
        lon: yup.number().nullable(),
        hasDetails: yup.bool(),
        details: yup
          .mixed()
          .nullable()
          .when("hasDetails", {
            is: val => !!val,
            then: () =>
              yup.array().of(
                yup.object().shape({
                  key: yup.string(),
                  value: yup.string()
                })
              )
          })
      });

    if (
      (!line1 || line1 === "") &&
      lat !== "" &&
      lat !== undefined &&
      lon !== "" &&
      lon !== undefined
    )
      return yup.object().shape({
        line1: yup.string().nullable(),
        line2: yup.string().nullable(),
        city: yup.string().nullable(),
        state: yup.string().nullable(),
        zipCode: yup.string().nullable(),
        lat: yup.number().typeError("Provide valid latitude.").required("Latitude is required"),
        lon: yup.number().typeError("Provide valid longitude.").required("Longitude is required"),
        details: yup
          .mixed()
          .nullable()
          .when("hasDetails", {
            is: val => !!val,
            then: () =>
              yup.array().of(
                yup.object().shape({
                  key: yup.string(),
                  value: yup.string()
                })
              )
          })
      });

    return yup.object().shape({
      line1: yup.string().required("Line 1 is required"),
      line2: yup.string().nullable(),
      city: yup.string().required("City is required"),
      state: yup.string().required("State is required"),
      zipCode: yup.string().required("Zip Code is required"),
      lat: yup.number().typeError("Provide valid latitude.").required("Latitude is required"),
      lon: yup.number().typeError("Provide valid longitude.").required("Longitude is required"),
      details: yup
        .mixed()
        .nullable()
        .when("hasDetails", {
          is: val => !!val,
          then: () =>
            yup.array().of(
              yup.object().shape({
                key: yup.string(),
                value: yup.string()
              })
            )
        })
    });
  }),
  primary: yup.boolean()
});

const ModalLocation = ({visible, setVisible, facility, loadMap, setLocations}) => {
  const isMounted = useMountedState();
  const {roleCanAccessResource} = useContext(AuthContext);

  const {addToast} = useToast();

  const [facilityLocations, setFacilityLocations] = useState([]);
  const [targetId, setTargetId] = useState(null);
  const [manage, setManage] = useState(false);

  const {api: apiFacilities} = useApi("facilities");
  const {api: apiChecksheets, data: checksheets} = useApi("checksheets");
  const {api: apiAddresses} = useApi("addresses");

  const form = useForm({
    defaultValues: DEFAULT_LOCATION,
    resolver: yupResolver(schema)
  });
  const {watch, setValue, reset, handleSubmit} = form;

  const location = watch("address");
  const isPrimary = watch("address.primary");
  const hasDetails = watch("address.hasDetails");

  const checksheetHasWeatherReferences = useMemo(() => {
    if (!checksheets) return false;
    for (let i = 0; i < checksheets.length; i++) {
      const checksheet = checksheets[i];
      const {byId} = checksheet?.builder || {};
      if (byId) {
        const elements = Object.values(byId);
        const conditions = elements
          .filter(({condition}) => !!condition?.list)
          .map(({condition}) => condition);
        const formulas = elements.filter(({formula}) => !!formula).map(({formula}) => formula);
        for (let j = 0; j < conditions.length; j++) {
          const {list} = conditions[j];
          if (list?.some(({depends}) => ["rainfall", "cumulative"].includes(depends))) return true;
        }

        for (let j = 0; j < formulas.length; j++) {
          const formula = formulas[j];
          if (formula?.some(({value}) => ["rainfall", "cumulative"].includes(value))) return true;
        }
      }
    }
    return false;
  }, [checksheets]);

  // Format facility addresses as locations
  useEffect(() => {
    if (facility?.addresses?.length > 0) setFacilityLocations(facility.addresses);
  }, [facility.addresses, setValue]);

  useEffect(() => {
    if (isMounted() && facility) apiChecksheets.callGet(null, {facilityId: facility.id});
  }, [isMounted, apiChecksheets, facility]);

  const addAddress = address =>
    apiFacilities
      .callPut(facility.id, {
        address: {
          ...address,
          primary: facilityLocations?.length === 0 || isPrimary
        }
      })
      .then(({status, data}) => {
        if (status === 200 && data) {
          const {addresses, builder} = data.facility;
          setFacilityLocations(addresses);
          loadMap(addresses, builder);
          reset(DEFAULT_LOCATION);
          setManage(false);
        }
      });

  const updateAddress = address =>
    apiFacilities
      .callPut(facility.id, {
        address: {id: targetId, ...address, primary: isPrimary}
      })
      .then(({status, data}) => {
        if (status === 200 && data) {
          const {addresses, builder} = data.facility;
          setFacilityLocations(addresses);
          loadMap(addresses, builder);
          setTargetId(null);
          reset(DEFAULT_LOCATION);
          setManage(false);
        }
      });

  const removeAddress = id => {
    const address = facility.addresses.find(a => a.id === id);
    if (address.primary && checksheetHasWeatherReferences) {
      addToast(
        "Cannot remove! Existing checksheet relies on primary address for weather-based conditionals or formulas.",
        "error"
      );
    } else {
      setLocations(null);
      apiAddresses
        .callDelete(id, {
          data: {
            facility: facility.id
          }
        })
        .then(({status}) => {
          if (status === 200) {
            const temp = facilityLocations.filter(a => a.id !== id);
            setFacilityLocations(temp);
            loadMap(temp, facility.builder);

            if (facilityLocations.length === 1) {
              setManage(true);
              setValue("address.primary", true);
            }
          }
        });
    }
  };

  const handleSave = () => {
    const {details, lat, lon, line1} = location;

    delete location.hasDetails;

    if (!details || details.length === 0 || !details[0].key) location.details = null;

    if (location && !line1) {
      // Coordinates provided
      const coords = maps.getLatLng(lat, lon);
      maps.geocode({latLng: coords}, results => {
        if (results?.length > 0) {
          let payload = null;
          for (let i = 0; i < results.length; i++) {
            if (results[i].types[0] === "street_address") {
              const updated = formatAddressComponents(results[i].address_components);
              payload = {...location, lat, lon, ...updated};

              break;
            }
          }

          if (payload) {
            if (targetId) updateAddress(payload);
            else addAddress(payload);
            setLocations(null);
          } else
            addToast(
              "Coordinates do not correspond to a known address. Please try new coordinates."
            );
        }
      });
    } else if (location && (!lat || !lon)) {
      // Address Provided
      maps.geocode({address: formatAddressForGeocode(location)}, results => {
        if (results?.length > 0) {
          // Should provide a single match
          const newLat = results[0].geometry.location.lat();
          const newLon = results[0].geometry.location.lng();
          const payload = {...location, lat: newLat, lon: newLon};
          if (targetId) updateAddress(payload);
          else addAddress(payload);
          setLocations(null);
        }
      });
    } else {
      if (targetId) updateAddress(location);
      else addAddress(location);
      setLocations(null);
    }
  };

  const editAddress = address => {
    setManage(true);
    setTargetId(address.id);

    reset({
      address: {
        ...address,
        hasDetails: !!address.details && address.details.length > 0,
        details: address.details || [{}]
      }
    });
  };

  if (manage && roleCanAccessResource("facility_address", "create"))
    return (
      <Modal
        visible={manage}
        setVisible={setManage}
        goBack={() => {
          setManage(false);
          reset(DEFAULT_LOCATION);
        }}
        hasBackButton>
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit(handleSave)} noValidate>
            <FormGroup>
              <Location defaultForm="Address" form={form} />

              <FormField>
                <InputCheck testId="hasDetails" name="address.hasDetails">
                  Additional details?
                </InputCheck>
                {hasDetails && (
                  <InputTextGroup
                    testId="details"
                    name="address.details"
                    label="Details"
                    canSetKey
                  />
                )}
              </FormField>

              <FormField>
                <InputCheck
                  name="address.primary"
                  testId="address.primary"
                  hidden={facilityLocations?.length === 0}>
                  Set as Primary Address
                </InputCheck>
              </FormField>

              <Inline>
                <Button type="submit" data-testid="address.submit">
                  Save
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setManage(false);
                    reset(DEFAULT_LOCATION);
                  }}
                  style={{marginLeft: `${pad}px`}}>
                  Cancel
                </Button>
              </Inline>
            </FormGroup>
          </Form>
        </FormProvider>
      </Modal>
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalHeading>
        <Heading>Location(s)</Heading>
      </ModalHeading>
      {facilityLocations?.map(address => (
        <Address key={address.id} data-testid="edit.locations">
          <div>
            <Label data-testid="edit.location" bold>
              {address.primary && <span>Primary</span>}
            </Label>
            <Text>
              <span>{address.line1}</span>
              <br />
              {address.line2 && address.line2 !== "" && (
                <>
                  <span>{address.line2}</span>
                  <br />
                </>
              )}
              <span>
                {address.city} {address.state}
                {address.zipCode && `, ${address.zipCode}`}
              </span>
              <br />
              <Small>
                {address.details &&
                  address.details.map(
                    detail =>
                      Object.keys(detail).length > 0 && (
                        <Fragment key={detail.key}>
                          {`${detail.key}: ${detail.value}`}
                          <br />
                        </Fragment>
                      )
                  )}
              </Small>
            </Text>
          </div>
          <Options>
            {roleCanAccessResource("facility_address", "update") && (
              <Button
                type="button"
                onClick={() => editAddress(address)}
                data-testid="textGroup.edit">
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {roleCanAccessResource("facility_address", "delete") && (
              <Button
                type="button"
                onClick={() => removeAddress(address.id)}
                data-testid="textGroup.remove">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
          </Options>
        </Address>
      ))}

      {facilityLocations?.length === 0 && <Text>No facility locations have been added.</Text>}

      {roleCanAccessResource("facility_address", "create") && (
        <Button type="button" onClick={() => setManage(true)}>
          Add Location
        </Button>
      )}
    </Modal>
  );
};

ModalLocation.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  loadMap: PropTypes.func.isRequired,
  setLocations: PropTypes.func.isRequired
};

// Style Overrides
const ModalHeading = styled(Inline)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${pad}px;
`;

const Address = styled.div`
  ${flex("row", "wrap", "space-between", "start")};
  position: relative;
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${props => props.theme.secondary};
`;

const Options = styled(Inline)`
  align-self: end;
  gap: ${pad}px;
`;

export default ModalLocation;
